<template>
  <transition>
    <v-card
      class="fz-14 mb-5 overflow-hidden v-card--user-information position-relative margin-top"
      max-width="316px"
      style="box-shadow: 0 0 0 1px rgba(6,44,82,0.1), 0 1px 3px 0 rgba(64,66,69,0.12), 0 2px 16px 0 rgba(33,43,54,0.08);"
    >
      <div class="fz-12 font-weight-semi-bold dark-text text-uppercase pa-4 d-flex justify-space-between align-center">
        Send to

        <v-btn
          text
          class="text-none pa-0"
          height="20px"
          min-width="46px"
          color="#007ACE"
          @click="close"
        >Cancel
        </v-btn>
      </div>

      <v-col
        cols="12"
        md="12"
        class="py-0"
      >
        <v-text-field
          solo
          outlined
          label="Search people, mailboxes"
          type="text"
          v-model="search"
          dense
          hide-details
          style="height: 36px;"
        >
          <template v-slot:prepend-inner>
            <v-icon size="20px" color="#919EAB">search</v-icon>
          </template>
        </v-text-field>
      </v-col>

      <v-col
        cols="12"
        md="12"
        class="px-0 pt-2 pb-4"
      >
        <v-divider></v-divider>
      </v-col>

      <div class="fz-12 font-weight-semi-bold dark-text text-uppercase px-4 mb-2">
        Recent
      </div>

      <v-list
        class="py-0 overflow-y-auto"
        max-height="400px"
      >
        <template v-for="(item, itemIndex) in sendArray">
          <v-list-item :key="itemIndex">
            <v-list-item-avatar
              width="40px"
              min-width="40px"
              height="40px"
              class="mr-2"
              v-if="item.avatar"
            >
              <v-img
                :alt="`${item.name} avatar`"
                :src="item.avatar + '?random=' + itemIndex"
              ></v-img>
            </v-list-item-avatar>

            <v-list-item-content>
              <v-list-item-title class="fz-14 font-weight-medium dark-text">
                {{ item.firstname ? fullName(item.firstname, item.lastname) : item.source }}
              </v-list-item-title>
              <v-list-item-subtitle class="fz-14 grey--text text--darken-1">
                {{ item.phone ? item.phone : item.content }}
              </v-list-item-subtitle>
            </v-list-item-content>

            <v-list-item-action>
              <v-tooltip top>
                <template v-slot:activator="{ on }">
              <v-btn
                v-on="on"
                class="grey-button px-3"
                height="30px"
                min-width="44px"
                @click="sendTo(itemIndex)"
              >
                <v-icon size="18px" color="#919EAB">send</v-icon>
                <span class="d-md-none d-lg-block ml-2">Send</span>
              </v-btn>
                </template>
                <span>Send</span>
              </v-tooltip>
            </v-list-item-action>
          </v-list-item>

          <v-divider
            :key="itemIndex + 'divider'"
          ></v-divider>
        </template>
      </v-list>

      <v-btn text link tile class="text-none fill-width " color="link" height="50px" @click="sendExpand = !sendExpand">
        Show all
        <v-icon color="regular-text" :class="{ 'button__icon--active': sendExpand }">expand_more</v-icon>
      </v-btn>
    </v-card>
  </transition>
</template>

<script>
export default {
  name: 'HistoryIncomingCallSendTo',
  props: {
    dialog: {
      type: Boolean,
      default: true
    }
  },
  data: () => ({
    search: '',
    sendExpand: false,
    sendList: [
      {
        firstname: 'Ola',
        lastname: 'Brooks',
        phone: '096-348-2316',
        avatar: 'https://picsum.photos/200'
      },
      {
        source: 'Development mailbox',
        content: '4 people in queue'
      },
      {
        firstname: 'Kyle',
        lastname: 'Moss',
        phone: '377-166-3107',
        avatar: 'https://picsum.photos/200'
      },
      {
        source: 'Support mailbox',
        content: '2 people in queue'
      },
      {
        source: 'Design mailbox',
        content: '1 people in queue'
      },
      {
        firstname: 'Ola',
        lastname: 'Brooks',
        phone: '096-348-2316',
        avatar: 'https://picsum.photos/200'
      },
      {
        source: 'Development mailbox',
        content: '4 people in queue'
      },
      {
        firstname: 'Kyle',
        lastname: 'Moss',
        phone: '377-166-3107',
        avatar: 'https://picsum.photos/200'
      },
      {
        source: 'Support mailbox',
        content: '2 people in queue'
      },
      {
        source: 'Design mailbox',
        content: '1 people in queue'
      },
      {
        firstname: 'Ola',
        lastname: 'Brooks',
        phone: '096-348-2316',
        avatar: 'https://picsum.photos/200'
      },
      {
        source: 'Development mailbox',
        content: '4 people in queue'
      },
      {
        firstname: 'Kyle',
        lastname: 'Moss',
        phone: '377-166-3107',
        avatar: 'https://picsum.photos/200'
      },
      {
        source: 'Support mailbox',
        content: '2 people in queue'
      },
      {
        source: 'Design mailbox',
        content: '1 people in queue'
      }
    ]
  }),
  computed: {
    sendArray () {
      return this.sendExpand ? this.sendList : this.sendList.slice(0, 5)
    }
  },
  methods: {
    close () {
      this.$store.dispatch('components/chatSend', false)
      // this.$emit('closePlayMessage', false)
    },
    sendTo (index) {
      console.log(index)
      console.log(this.$route)
    },
    fullName (firstname, lastname) {
      return firstname + ' ' + lastname
    }
  }
}
</script>
<style lang="scss">
  .margin-top:first-of-type {
    margin-top: 146px !important;
  }
</style>
