export function getBase64 (data) {
  const bytes = new Uint8Array(data)
  let src = ''

  function reducer (item, b) {
    src = item += String.fromCharCode(b)

    return src
  }

  const binary = bytes.reduce(reducer, '')

  return 'data:image/jpeg;base64,' + btoa(binary)
}
