<template>
  <v-dialog
    v-model="dialog"
    persistent
    max-width="724px"
  >
    <v-card>
      <v-card-title class="px-5 pt-5 pb-4">
        <h2 class="font-weight-bold fz-28">Send as message</h2>
      </v-card-title>

      <v-card-text class="px-5">
        <v-form v-model="valid">
          <v-row>
            <v-col
              cols="12"
              md="12"
            >
              <label class="fz-14 mb-1 d-block">To</label>
              <v-text-field
                solo
                outlined
                label="Search contacts or enter new phone number"
                type="email"
                v-model="form.phone"
                :rules="phoneRules"
                dense
                hide-details
              >
                <template v-slot:prepend-inner>
                  <v-icon size="20px" color="#919EAB">search</v-icon>
                </template>
              </v-text-field>
            </v-col>

            <v-col
              cols="12"
              md="12"
              class="py-1"
            >
              <label class="fz-14 mb-1 d-block">Message</label>
              <v-textarea
                solo
                outlined
                v-model="form.message"
                :rules="messageRules"
                height="78px"
                dense
                hide-details
                no-resize
              >
              </v-textarea>
            </v-col>

            <v-col
              cols="12"
              md="12"
              class="pt-1"
            >
              <div class="indigo lighten-5 br-3 py-3 px-4 d-flex align-center">
                <v-btn
                  class="play-button pa-0"
                  @click="isPlaying = !isPlaying"
                >
                  <v-icon size="24px">{{ isPlaying ? 'pause' : 'play_arrow'}}</v-icon>
                </v-btn>

                <div class="d-flex flex-column justify-center ml-4">
                  <div>
                    <span class="font-weight-medium mr-2" style="color:#1D2428;">{{ form.voicemails.date }}</span>
                    {{ form.voicemails.time }}
                  </div>

                  {{ form.voicemails.source }}
                </div>

                <div class="d-flex align-center ml-auto">
                  <v-tooltip bottom content-class="v-tooltip--bottom">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        icon
                        v-bind="attrs"
                        v-on="on"
                        class="mr-3"
                      >
                        <v-icon size="24px" color="#919EAB">import_export</v-icon>
                      </v-btn>
                    </template>

                    <span>Change record</span>
                  </v-tooltip>

                  <v-tooltip bottom content-class="v-tooltip--bottom">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        icon
                        v-bind="attrs"
                        v-on="on"
                      >
                        <v-icon size="24px" color="#ED6347">remove_circle</v-icon>
                      </v-btn>
                    </template>

                    <span>Remove record</span>
                  </v-tooltip>
                </div>
              </div>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>

      <v-card-actions class="px-5 pt-0 pb-6">
        <v-spacer></v-spacer>
        <v-btn
          class="grey-button"
          height="36px"
          @click="cancel"
        >
          Cancel
        </v-btn>
        <v-btn
          class="violet-button"
          @click="send"
        >
          Send
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'Send-as-message',
  props: {
    dialog: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    form: {
      phone: '',
      message: 'The Slamming Viper is a fast paced agile shoe with a low profile and excellent stability characteristics. It incorporates the very best of Slamming Indoor shoe technologies and then some.',
      voicemails: {
        date: '08 Apr 2017',
        time: '01:52PM',
        source: 'Support mailbox  x8721'
      }
    },
    valid: false,
    isPlaying: false,
    phoneRules: [
      v => !!v || 'Phone number is required',
      v => /^[0-9]+$/.test(v) || 'Phone number must be valid'
    ],
    messageRules: [
      v => !!v || 'Message is required',
      v => v.length >= 10 || 'Subject must be more than 10 characters'
    ]
  }),
  methods: {
    send () {
      if (this.valid) {
        console.log(this.form)
      } else {
        console.log('Validate denied!')
      }
    },
    cancel () {
      this.$emit('update:sendMessageDialog', false)
    }
  }
}
</script>
