<template>
  <transition>
    <v-card
      class="overflow-hidden pb-2 margin-top-md mx-4 ml-lg-0"
      max-width="316px"
      :loading="loading"
    >
      <div class="text-uppercase font-weight-semi-bold dark-text pa-4" style="font-size: 12px">My mailboxes</div>
      <div v-if="mailboxesComputed.length">
        <template
          v-for="(item, itemIndex) in mailboxesComputed"
        >
          <v-divider
            :key="itemIndex + 'v-divider'"
            v-if="itemIndex"
          ></v-divider>

          <v-list-item
            :key="itemIndex + 'v-list-item'"
            :class="$store.state.auth.currentUser && $store.state.auth.currentUser.role === 'company_admin' ? 'px-2' : 'px-4'"
            class="pointer d-flex justify-space-between"
            :to="{name: 'VoicemailsMailboxes', params: {name: replaceLink(item.name)}}"
          >
            <v-list-item-avatar
              min-width="24px"
              width="24px"
              height="24px"
              class="ma-0 mt-2 mr-1 align-self-start"
              v-if="$store.state.auth.currentUser && $store.state.auth.currentUser.role === 'company_admin'"
            >
              <v-icon size="22px" color="#919EAB">{{ item.icon }}</v-icon>
            </v-list-item-avatar>

            <v-list-item-content>
              <v-list-item-title class="font-weight-semi-bold dark-text" style="font-size: 14px">
                {{ item.name }}
              </v-list-item-title>

              <v-list-item-subtitle class="grey--text text--darken-1">
                <span>{{ item.unreadMessage || 'No' }} {{ item.unreadMessage && item.unreadMessage > 1 || !item.unreadMessage ? 'unread messages' : 'unread message' }}</span>
              </v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action v-if="rightPopUpMyMailboxes">
              <v-btn
                text
                class="pa-3 grey-button d-flex justify-center"
                height="36px"
                @click.stop.prevent="assignMailbox(item.uuid)"
              >
                <v-icon size="20px" color="#919EAB" class="mr-1">add</v-icon>
                Assign
              </v-btn>
            </v-list-item-action>
          </v-list-item>
        </template>
      </div>
      <p v-else-if="!loading" class="fz-14 font-weight-medium dark-text mx-4">
        {{ rightPopUpMyMailboxes && this.mailboxesList.length ? 'User assigned to all mailboxes' : 'Mailboxes are empty' }}
      </p>
    </v-card>
  </transition>
</template>

<script>
import { mapState, mapActions } from 'vuex'

export default {
  name: 'VoicemailMyMailboxes',
  data: () => ({
    mailboxesList: [],
    loading: false
  }),
  created () {
    this.init()
  },
  computed: {
    ...mapState('components', [
      'rightPopUpMyMailboxes'
    ]),
    mailboxesComputed () {
      return this.rightPopUpMyMailboxes ? this.mailboxesList.filter(e => e.users && !e.users.includes(this.$route.params.id)) : this.mailboxesList
    }
  },
  methods: {
    ...mapActions(['getMailboxes', 'getMailbox']),
    async init () {
      const params = this.$route.name === 'Mailboxes' ? { isShared: true } : {}
      try {
        this.loading = true
        const { data } = await this.getMailboxes(params)
        if (!data) return
        this.mailboxesList = []
        data.mailboxList.forEach(async (mailbox, index) => {
          const res = await this.getMailbox(mailbox.uuid)
          this.mailboxesList.push({
            ...mailbox,
            users: res.data.mailbox.users
          })
        })
      } catch (err) {
        throw new Error(err)
      } finally {
        this.loading = false
      }
    },
    replaceLink (item) {
      const linkName = item.split(' ')
      return linkName[0].toLowerCase()
    },
    async assignMailbox (mailboxUuid) {
      if (!this.$route.params.id) return
      await this.$store
        .dispatch('addUserToMailbox', {
          mailboxUuid,
          userUuid: this.$route.params.id
        })
        .then(() => {
          this.init()
        }).catch(err => {
          throw new Error(err)
        })
    }
  }
}
</script>
<style scoped lang="scss">
  .margin-top-md {
    @include media-breakpoint-up(md) {
      margin-top: 110px !important;
    }
  }
</style>
