<template>
  <v-card elevation="0">
    <v-list-item-content class="justify-center py-1">
      <div
          v-if="accountName && !roleIsSysAdminAccountUser"
          class="fz-12 font-weight-semi-bold dark-text text-uppercase fill-width pa-4 pb-3"
          style="height: 40px;"
      >
        {{ accountName }}
      </div>
      <v-btn
          v-if="roleIsSysAdminAccountUser"
          depressed
          title="Switch Account"
          text
          to="/switch-account"
          height="40px"
          class="text-none font-weight-semi-bold fill-width justify-start v-btn--violet-hover fz-14"
          tile
      >
        {{ accountName }}
      </v-btn>
      <v-btn
        to="/my-profile"
        depressed
        text
        height="40px"
        class="text-none fill-width justify-start v-btn--violet-hover fz-14"
        tile
      >
        My Profile
      </v-btn>
      <template v-if="$store.state.auth.currentUser.role === 'account_user'">
        <v-menu
          v-for="(number, index) in phoneNumbersSliced"
          :key="index"
          left
          max-width="203px"
          nudge-left="1px"
          :rounded="'1'"
          offset-x
          :close-on-content-click="false"
          content-class="elevation-2"
        >
          <template v-slot:activator="{ on }">
            <v-btn
              class="d-flex justify-space-between align-center v-btn--violet-hover"
              text
              tile
              v-on="on"
            >
              {{number.number}}
              <v-icon v-if="number.extensions.length" size="20px" color="#919EAB">navigate_next</v-icon>
            </v-btn>
          </template>

          <v-card elevation="0" v-if="number.extensions.length">
            <v-list-item-content class="justify-center pt-0 pb-1">
              <div
                class="fz-12 font-weight-semi-bold dark-text text-uppercase fill-width pa-4 pb-3"
                style="height: 40px;"
              >
                Extensions
              </div>
              <v-divider></v-divider>

              <div v-for="extension in number.extensions" :key="extension.extension_id" class="fz-14 d-flex justify-space-between align-center px-4 py-3">
                <span class="dark-text">×{{extension.extensionNumber}}</span>
                <!-- <span class="grey--text">Design</span> -->
              </div>

            </v-list-item-content>
          </v-card>
        </v-menu>
      </template>

      <!-- <v-btn
        :to="$store.state.auth.currentUser && $store.state.auth.currentUser.role !== 'account_admin' ? '#' : '#'"
        depressed
        text
        height="40px"
        class="fill-width text-none justify-start v-btn--violet-hover fz-14"
        tile
      >
        {{ $store.state.auth.currentUser && $store.state.auth.currentUser.role !== 'account_admin' ? 'Switch to central desk' : 'Support' }}
      </v-btn> -->
      <v-btn
        v-if="$store.state.auth.currentUser.role === 'account_admin'"
        to="/phone-numbers"
        depressed
        text
        height="40px"
        class="text-none fill-width justify-start v-btn--violet-hover fz-14"
        tile
      >
        Number Rental
      </v-btn>
      <v-btn
        v-else-if="phoneNumbers.length > maxNumbers"
        depressed
        text
        height="40px"
        class="text-none fill-width justify-start v-btn--violet-hover fz-14"
        tile
      >
        More...
      </v-btn>
      <!-- <div
        v-if="isDesktop"
        class="fz-12 font-weight-semi-bold dark-text text-uppercase fill-width pa-4 pb-3"
        style="height: 40px;"
      >
        Settings
      </div> -->

      <v-divider class="mt-0 mb-2"></v-divider>

      <v-btn
        to="/settings"
        depressed
        text
        height="40px"
        class="text-none fill-width justify-start v-btn--violet-hover fz-14 dark-text"
        tile
      >
        Settings
      </v-btn>

      <v-btn
        to="/invoice"
        depressed
        text
        height="40px"
        class="text-none fill-width justify-start v-btn--violet-hover fz-14"
        tile
      >
        Invoice
      </v-btn>

      <v-btn
        to="/payments-history"
        depressed
        text
        height="40px"
        class="text-none fill-width justify-start v-btn--violet-hover fz-14"
        tile
      >
        Payments
      </v-btn>

      <v-divider class="my-2"></v-divider>

      <v-btn
        depressed
        text
        height="40px"
        class="text-none fill-width justify-start v-btn--violet-hover fz-14"
        tile
        @click.stop="openDialog"
        >
        Log out
      </v-btn>
    </v-list-item-content>

    <v-dialog
      persistent
      v-model="logoutDialog"
      :max-width="isDesktop ? '350' : '260'"
    >
      <v-card>
        <v-card-title class="font-weight-bold">
          Log out
        </v-card-title>

        <v-card-text>
          Are you sure you want to Log out?
        </v-card-text>

        <v-card-actions class="pb-5 pt-0 justify-md-end">

          <div :style="isDesktop ? '' : 'width: 50%'"
               class="mx-2">
            <v-btn
              color="secondary"
              outlined
              @click="logoutDialog = false"
              class="w-100 text-capitalize"
            >
              Cancel
            </v-btn>
          </div>

          <div :style="isDesktop ? '' : 'width: 50%'"
               class="mx-2">
            <v-btn
              depressed
              color="primary"
              class="w-100 text-none"
              @click="logout"
            >
              Log out
            </v-btn>
          </div>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex'
export default {
  name: 'UserMenu',
  data () {
    return {
      logoutDialog: false,
      loading: false,
      maxNumbers: 10
    }
  },
  created () {
    this.loadExtensions()
  },
  computed: {
    ...mapGetters({
      // phoneNumbers: 'phoneNumbers',
      extensions: 'extensions'
    }),
    accountName () {
      return this.$store.state.auth.currentUser.accountName
    },
    roleIsSysAdminAccountUser () {
      return ['system_admin', 'account_user'].includes(this.$store.state.auth.currentUser.role)
    },
    phoneNumbers () {
      const numbers = []
      this.extensions.forEach((element) => {
        if (numbers.findIndex(e => e.number === element.didNumber) === -1) {
          numbers.push({
            number: element.didNumber,
            extensions: [element]
          })
        } else {
          const number = numbers.find(e => e.number === element.didNumber)
          number.extensions.push(element)
        }
      })
      return numbers
    },
    phoneNumbersSliced () {
      return this.phoneNumbers.slice(0, this.maxNumbers)
    },
    isDesktop () {
      return this.$vuetify.breakpoint.mdAndUp
    }
  },
  mounted () {
    const self = this
    window.addEventListener('storage', function (event) {
      if (event.key === 'currentUser' && !event.newValue) {
        self.logout()
      }
    })
  },
  methods: {
    ...mapActions({
      // numbersList: 'didNumbersList',
      getExtensions: 'getExtensions'
    }),
    ...mapMutations(['setUserImage']),
    async loadExtensions () {
      if ((this.extensions && this.extensions.length) || this.$store.state.auth.currentUser.role === 'account_admin') return
      await this.getExtensions()
    },
    async logout () {
      try {
        this.logoutDialog = false
        this.setUserImage(null)
        await this.$router.push({ path: '/login' })
        this.$store.dispatch('logout')
      } catch (e) {
        throw new Error(e)
      }
    },
    openDialog () {
      this.logoutDialog = true
      this.$emit('closeMenu', false)
    }
  }
}
</script>
