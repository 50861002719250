<template>
  <v-card class="pa-5 fz-14 mb-5" :class="list.length > 5 ? 'pb-0' : ''">
    <v-card-title class="px-0 pb-1 pt-0 font-weight-semi-bold fz-16 justify-space-between">
      <router-link
        :to="link"
        class="text-decoration-none"
      >
        <span class="dark-text">{{ title }}</span>
      </router-link>
      <v-btn icon :class="{ 'button__icon--active': collapsed }" @click="collapsed = !collapsed">
        <v-icon>expand_more</v-icon>
      </v-btn>
    </v-card-title>
    <v-card-subtitle class="px-0 caption pb-2">
      <slot name="subtitle"></slot>
    </v-card-subtitle>
    <template v-if="!collapsed">
      <template v-if="showList">
        <slot :list="currentList" />
      </template>
      <v-list v-else class="py-0">
        <v-list-item v-for="(item, index) in currentList" :key="index" class="px-0 list-item">
          <slot :item="item" :index="index"/>
        </v-list-item>
      </v-list>
      <template v-if="list.length > 5">
        <v-divider/>
        <v-btn
          text
          link
          tile
          class="text-none fill-width"
          color="link"
          height="50px"
          @click="showAll = !showAll"
        >
          {{showAll ? 'Show less' : 'Show all'}}
          <v-icon color="regular-text" :class="{ 'button__icon--active': showAll }">expand_more</v-icon>
        </v-btn>
      </template>
    </template>
  </v-card>
</template>

<script>
export default {
  name: 'CardCollapse',
  data: () => ({
    collapsed: false,
    showAll: false
  }),
  props: {
    title: {
      type: String,
      default: ''
    },
    subtitle: {
      type: String,
      default: ''
    },
    link: {
      type: String,
      default: ''
    },
    list: {
      type: Array,
      default: () => {
        return []
      }
    },
    showList: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    currentList () {
      return this.showAll ? this.list : this.list.slice(0, 5)
    }
  }
}
</script>

<style scoped lang="scss">
.list-item {
  border-bottom: 1px solid #DFE3E8;

  &:last-child {
    border-bottom: none;
  }
}
</style>
