<template>
  <div>
    <template v-if="$vuetify.breakpoint.smAndDown">
      <v-btn
        v-if="!toggleSearch"
        style="position: absolute; right: 1rem; color: #A7A5B1"
        class="d-md-none px-0 mt-4"
        min-width="40px"
        text
        @click="toggleSearch = true"
      >
        <v-icon size="16px">search</v-icon>
      </v-btn>
      <div v-else class="mobileSearch">
        <v-card class="py-1">
          <v-btn
            text
            class="d-flex align-center"
            @click="toggleSearch = !toggleSearch"
          >
            <v-icon size="30px" color="#6170C5">navigate_before</v-icon>
            <span class="font-weight-regular fz-20 text-capitalize">Search</span>
          </v-btn>
        </v-card>
        <SearchDropdown
          v-if="toggleSearch"
          :isMobile="true"
          style="position:relative"
          @blur="toggleSearch = false"
        />
      </div>
    </template>
    <div class="d-flex justify-space-between mt-5">
      <div class="px-4 px-md-0">
        <h1 :class="isMobile ? 'blue-elemental font-weight-bold fz-25' : 'font-weight-semi-bold fz-28 line-h-32 dark-text'">Good morning, {{ userName }}</h1>
        <h2 class="d-none d-md-block light-text fz-26 font-weight-regular">Here’s what’s happening today</h2>
        <h2 class="d-md-none light-text fz-14 font-weight-regular">Here’s what’s happening today</h2>
        <div class="d-flex mt-8">
          <div class="mr-12">
            <div class="body-2 light-text">Today’s calls</div>
            <div class="text-h2 dark-text fz-26 line-h-32 font-weight-regular">{{ toHourMinSec(dashboardCall.duration) }}</div>
          </div>
          <div>
            <div class="body-2 light-text">Today’s messages</div>
            <div class="text-h2 dark-text fz-26 line-h-32 font-weight-regular">{{dashboardSMS.total}}</div>
          </div>
        </div>
      </div>
      <img src="../../public/admin_dash.png" alt="" style="height: 236px" class="d-none d-md-block">
    </div>
    <v-card class="pa-4 fz-14 mb-5 line-h-17" :class="{ 'd-flex justify-space-between': isMobile }">
      <div class="flex-grow-1">
        <div class="d-flex align-start justify-space-between">
          <div
            :class="isMobile ? 'font-weight-medium fz-15 line-h-18' : 'font-weight-semi-bold fz-16 line-h-24'"
            class="black-text mb-2"
          >
            Status
          </div>
          <span v-if="isMobile" class="greyGainsboro" @click="editStatus">
            Tap to edit
          </span>
        </div>
        <div v-if="!statusEdit">
          <div class="mt-2">{{ dashboard.status }}</div>
          <v-btn
            v-if="!isMobile"
            outlined
            min-width="57px"
            height="30px"
            class="text-capitalize grey-button br-3 align-self-center mt-4"
            @click="editStatus"
          >
            Edit
          </v-btn>
        </div>
        <template v-else>
          <v-textarea
            :maxlength="500"
            outlined
            height="80"
            class="mb-4"
            hide-details
            v-model="newStatus"
            ref="status"
          />
          <v-btn
            outlined
            height="30px"
            class="text-capitalize violet-button br-3 mr-2"
            @click="updateStatus"
          >
            Save
          </v-btn>
          <v-btn
            @click="cancelStatusUpdate"
            outlined
            height="30px"
            class="text-capitalize grey-button br-3"
          >
            Cancel
          </v-btn>
        </template>
      </div>
    </v-card>
    <v-card class="pa-5 fz-14 mb-5 card-container">
      <v-row no-gutters class="card-container__block pa-0">
        <v-col cols="12" sm="12" md="12" lg="1" xl="1" class="title d-flex align-end">
          <v-card-title class="pa-0 pb-xl-4 font-weight-semi-bold fz-16 d-flex">
            <span class="mr-auto section-header w-max">Call hours</span>
          </v-card-title>
        </v-col>
        <v-col class="d-flex justify-end flex-wrap align-end" cols="12" xl="11">
          <v-col cols="12" lg="4" xl="2">
            <v-select
              v-model="allFilters.groupBy"
              :items="groupByOptions"
              item-text="label"
              item-value="value"
              menu-props="offsetY"
              style="height: 36px;"
              solo
              outlined
              hide-details
            >
              <template v-slot:append>
                <v-icon size="15px">unfold_more</v-icon>
              </template>
            </v-select>
          </v-col>
          <v-col class="responsive_m_r" cols="6" lg="4" xl="3">
            <SelectComponent :label="true" :value="allNumbers" @selectedValue="allFilters.didNumber = $event" />
          </v-col>
          <v-col cols="6" lg="4" xl="3">
            <SelectComponent :label="true" :value="allUsers" @selectedValue="allFilters.userUuid = $event" />
          </v-col>
          <v-col class="d-flex mb-0" cols="6" lg="4" xl="2">
            <div style="min-width: 130px; width: 100%">
              <span>From:</span>
              <date-picker-solo
                :value="allFilters.startDate"
                :date-type="allFilters.groupBy"
                ref="allFiltersDate"
                picker-type="start"
                @changeDate="changeStartDate"
              />
            </div>
          </v-col>
          <v-col class="d-flex mb-0" cols="6" lg="4" xl="2">
            <div style="min-width: 130px; width: 100%">
              <span>To:</span>
              <date-picker-solo
                :value="allFilters.endDate"
                :date-type="allFilters.groupBy"
                ref="allFiltersDate"
                picker-type="end"
                @changeDate="changeEndDate"
              />
            </div>
          </v-col>
        </v-col>
        <v-col cols="12" class="d-flex">
          <div class="d-flex align-center pa-2">
            <div :style="{backgroundColor: incomingColor }" class="line-chart-color"></div>
            <span class="ml-2">Incoming</span>
          </div>
          <div class="d-flex align-center pa-2">
            <div :style="{backgroundColor: outgoingColor }" class="line-chart-color"></div>
            <span class="ml-2">Outgoing</span>
          </div>
        </v-col>
        <v-col cols="12">
          <bar-chart :data="computedCallHours" :options="options" ref="refCallHours" style="height: 250px"/>
        </v-col>
      </v-row>
    </v-card>
    <v-card class="pa-5 fz-14 mb-5 card-container">
      <v-row no-gutters class="card-container__block pa-0">
        <v-col cols="12" sm="12" md="12" lg="1" xl="1" class="title d-flex align-end">
          <v-card-title class="pa-0 pb-xl-4 font-weight-semi-bold fz-16 d-flex">
            <span class="mr-auto section-header w-max">Incoming Calls</span>
          </v-card-title>
        </v-col>
        <v-col class="d-flex justify-end flex-wrap align-end" cols="12" xl="11">
          <v-col cols="12" lg="4" xl="2">
            <v-select
              v-model="incomingFilters.groupBy"
              :items="groupByOptions"
              item-text="label"
              item-value="value"
              menu-props="offsetY"
              style="height: 36px;"
              solo
              outlined
              hide-details
            >
              <template v-slot:append>
                <v-icon size="15px">unfold_more</v-icon>
              </template>
            </v-select>
          </v-col>
          <v-col class="responsive_m_r" cols="6" lg="4" xl="2">
            <SelectComponent :label="true" :value="callTypeOptions" @selectedValue="incomingCallsType = $event" />
          </v-col>
          <v-col class="responsive_m_r" cols="6" lg="4" xl="3">
            <SelectComponent :label="true" :value="allNumbers" @selectedValue="incomingFilters.didNumber = $event" />
          </v-col>
          <v-col class="d-flex mb-0" cols="6" lg="4" xl="2">
            <div style="min-width: 130px; width: 100%">
              <span>From:</span>
              <date-picker-solo
                :value="incomingFilters.startDate"
                :date-type="incomingFilters.groupBy"
                picker-type="start"
                @changeDate="changeIncomingStartDate"
              />
            </div>
          </v-col>
          <v-col class="d-flex mb-0" cols="6" lg="4" xl="2">
            <div style="min-width: 130px; width: 100%">
              <span>To:</span>
              <date-picker-solo
                :value="incomingFilters.endDate"
                :date-type="incomingFilters.groupBy"
                picker-type="end"
                @changeDate="changeIncomingEndDate"
              />
            </div>
          </v-col>
        </v-col>
        <v-col cols="12">
          <bar-chart :data="dashboard.IncomingCalls" :options="options" ref="incomingBar" style="height: 250px"/>
        </v-col>
      </v-row>
    </v-card>
    <v-card class="pa-5 fz-14 mb-5 card-container">
      <v-row no-gutters class="card-container__block pa-0">
        <v-col cols="12" sm="12" md="12" lg="1" xl="1" class="title d-flex align-end">
          <v-card-title class="pa-0 pb-xl-4 font-weight-semi-bold fz-16 d-flex">
            <span class="mr-auto section-header w-max">Outgoing Calls</span>
          </v-card-title>
        </v-col>
        <v-col class="d-flex justify-end flex-wrap align-end" cols="12" xl="11">
          <v-col cols="12" lg="4" xl="2">
            <v-select
              v-model="outgoingFilters.groupBy"
              :items="groupByOptions"
              item-text="label"
              item-value="value"
              menu-props="offsetY"
              style="height: 36px;"
              solo
              outlined
              hide-details
            >
              <template v-slot:append>
                <v-icon size="15px">unfold_more</v-icon>
              </template>
            </v-select>
          </v-col>
          <v-col class="responsive_m_r" cols="6" lg="4" xl="2">
            <SelectComponent :label="true" :value="callTypeOptions" @selectedValue="outgoingCallsType = $event" />
          </v-col>
          <v-col class="responsive_m_r" cols="6" lg="4" xl="3">
            <SelectComponent :label="true" :value="allUsers" @selectedValue="outgoingFilters.userUuid = $event" />
          </v-col>
          <v-col class="d-flex mb-0" cols="6" lg="4" xl="2">
            <div style="min-width: 130px; width: 100%">
              <span>From:</span>
              <date-picker-solo
                :value="outgoingFilters.startDate"
                :date-type="outgoingFilters.groupBy"
                picker-type="start"
                @changeDate="changeOutgoingStartDate"
              />
            </div>
          </v-col>
          <v-col class="d-flex mb-0" cols="6" lg="4" xl="2">
            <div style="min-width: 130px; width: 100%">
              <span>To:</span>
              <date-picker-solo
                :value="outgoingFilters.endDate"
                :date-type="outgoingFilters.groupBy"
                picker-type="end"
                @changeDate="changeOutgoingEndDate"
              />
            </div>
          </v-col>
        </v-col>
        <v-col cols="12">
          <bar-chart :data="dashboard.OutgoingCalls" :options="options" ref="outgoingBar" style="height: 250px"/>
        </v-col>
      </v-row>
    </v-card>
    <!-- <v-card class="pa-5 fz-14 mb-5 card-container">
      <v-row no-gutters class="card-container__block pa-0">
        <v-col cols="12" sm="12" md="12" lg="1" xl="1" class="title d-flex align-end">
          <v-card-title class="pa-0 pb-xl-4 font-weight-semi-bold fz-16 d-flex">
            <span class="mr-auto section-header w-max">Incoming SMS</span>
          </v-card-title>
        </v-col>
        <v-col class="d-flex justify-end flex-wrap align-end" cols="12" xl="11">
          <v-col class="responsive_m_r" cols="12" lg="4" xl="3">
            <SelectComponent :label="true" :value="allNumbers" @selectedValue="incomingSmsFilters.didNumber = $event" />
          </v-col>
          <v-col class="d-flex mb-0" cols="6" lg="4" xl="2">
            <div style="min-width: 130px; width: 100%">
              <span>From:</span>
              <date-picker-solo :value="incomingSmsFilters.startDate" @changeDate="changeIncomingSMSStartDate"/>
            </div>
          </v-col>
          <v-col class="d-flex mb-0" cols="6" lg="4" xl="2">
            <div style="min-width: 130px; width: 100%">
              <span>To:</span>
              <date-picker-solo :value="incomingSmsFilters.endDate" @changeDate="changeIncomingSMSEndDate"/>
            </div>
          </v-col>
        </v-col>
        <v-col cols="12">
          <bar-chart :data="dashboard.IncomingSMS" :options="options" ref="incomingSmsBar" style="height: 250px"/>
        </v-col>
      </v-row>
    </v-card>
    <v-card class="pa-5 fz-14 mb-5 card-container">
      <v-row no-gutters class="card-container__block pa-0">
        <v-col cols="12" sm="12" md="12" lg="1" xl="1" class="title d-flex align-end">
          <v-card-title class="pa-0 pb-xl-4 font-weight-semi-bold fz-16 d-flex">
            <span class="mr-auto section-header w-max">Outgoing SMS</span>
          </v-card-title>
        </v-col>
        <v-col class="d-flex justify-end flex-wrap align-end" cols="12" xl="11">
          <v-col class="responsive_m_r" cols="12" lg="4" xl="3">
            <v-select
              v-model="outgoingSmsFilters.country"
              :items="availableCountries"
              item-text="name"
              item-value="a2"
              menu-props="offsetY"
              style="height:36px"
              solo
              outlined
              hide-details
            >
              <template v-slot:append>
                <v-icon size="15px">unfold_more</v-icon>
              </template>
            </v-select>
          </v-col>
          <v-col class="d-flex mb-0" cols="6" lg="4" xl="2">
            <div style="min-width: 130px; width: 100%">
              <span>From:</span>
              <date-picker-solo :value="outgoingSmsFilters.startDate" @changeDate="changeOutgoingSMSStartDate"/>
            </div>
          </v-col>
          <v-col class="d-flex mb-0" cols="6" lg="4" xl="2">
            <div style="min-width: 130px; width: 100%">
              <span>To:</span>
              <date-picker-solo :value="outgoingSmsFilters.endDate" @changeDate="changeOutgoingSMSEndDate"/>
            </div>
          </v-col>
        </v-col>
        <v-col  cols="12">
          <bar-chart :data="dashboard.OutgoingSMS" :options="options"  ref="outgoingSmsBar"  style="height: 250px"/>
        </v-col>
      </v-row>
    </v-card> -->
    <!-- <v-card class="pa-5 fz-14 mb-5 card-container">
      <v-row no-gutters class="card-container__block">
        <v-col cols="12" lg="6" xl="7" class="responsive_w_40 title">
          <v-card-title class="px-0 pb-1 pt-0 font-weight-semi-bold fz-16">
            <span class="mr-auto">Outgoing Calls</span>
          </v-card-title>
        </v-col>
        <v-col class="d-flex justify-end">
          <v-col class="px-1 responsive_m_r numbers" cols="4">
            <SelectComponent :value="['All countries']"/>
          </v-col>
          <v-col class="px-1 dates" cols="6">
            <date-picker class="ml-2"/>
          </v-col>
        </v-col>
        <v-col cols="12">
          <bar-chart :data="dashboard.OutgoingCallsCountries" :options="options" style="height: 250px"/>
        </v-col>
      </v-row>
    </v-card> -->
    <vue-snotify/>
  </div>
</template>

<script>
// import LineChart from '@/components/LineChart'
import BarChart from '@/components/BarChart'
import DatePickerSolo from '@/components/DatePickerSolo'
import SelectComponent from '@/components/SelectComponent'
import SearchDropdown from '@/components/SearchDropdown'
import { mapActions, mapMutations } from 'vuex'
import * as moment from 'moment'

export default {
  name: 'HomeAdmin',
  components: {
    SelectComponent,
    DatePickerSolo,
    // LineChart,
    BarChart,
    SearchDropdown
  },
  data: () => ({
    allUsers: [],
    allNumbers: [],
    callsNumber: 'All numbers',
    callsUser: 'All users',
    allFilters: {
      groupBy: 'day',
      startDate: '',
      endDate: '',
      didNumber: '',
      userUuid: ''
    },
    incomingFilters: {
      groupBy: 'day',
      startDate: '',
      endDate: '',
      callDir: 'in',
      didNumber: ''
    },
    outgoingFilters: {
      groupBy: 'day',
      startDate: '',
      endDate: '',
      callDir: 'out',
      userUuid: ''
    },
    incomingSmsFilters: {
      startDate: moment(new Date()).format('YYYY-MM-DD'),
      endDate: moment(new Date()).format('YYYY-MM-DD'),
      smsDir: 'in',
      didNumber: '',
      groupBy: 'country'
    },
    outgoingSmsFilters: {
      startDate: moment(new Date()).format('YYYY-MM-DD'),
      endDate: moment(new Date()).format('YYYY-MM-DD'),
      smsDir: 'out',
      didNumber: '',
      country: ''
    },
    groupByOptions: [
      { label: 'Hour', value: 'hour' },
      { label: 'Day', value: 'day' },
      { label: 'Month', value: 'month' }
    ],
    callHoursLabels: [],
    incomingCallHoursData: [],
    outgoingCallHoursData: [],
    availableCountries: [
      { name: 'All countries', a2: '' }
    ],
    incomingColor: '#51B83C',
    outgoingColor: '#9C6ADE',
    monthNames: ['January', 'February', 'March', 'April', 'May', 'June',
      'July', 'August', 'September', 'October', 'November', 'December'
    ],
    incomingCallsNumber: '',
    callTypeOptions: [
      { label: 'All', value: '' },
      { label: 'Attempts', value: 'attempts' },
      { label: 'Connected', value: 'connected' }
    ],
    incomingCallsType: '',
    outgoingCallsType: '',
    incomingSMSNumber: '',
    outgoingsSMSCountries: 'All countries',
    outgoingsCallsCountries: 'All countries',
    options: {
      maintainAspectRatio: false,
      elements: {
        line: {
          tension: 0
        }
      },
      legend: {
        display: false
      }
    },
    dashboard: {
      callHors: {
        labels: ['Sep 1', 'Sep 2', 'Sep 3', 'Sep 4', 'Sep 5', 'Sep 6', 'Sep 7', 'Sep 8', 'Sep 9', 'Sep 10', 'Sep 11', 'Sep 12'],
        datasets: [
          {
            backgroundColor: 'transparent',
            borderColor: '#9C6ADE',
            data: [/* 40, 20, 12, 39, 10, 40, 39, 80, 40, 20, 12, 11 */],
            pointHoverRadius: 5,
            pointHoverBackgroundColor: '#9C6ADE'
          },
          {
            backgroundColor: 'transparent',
            borderColor: '#47C1BF',
            data: [/* 4, 2, 32, 9, 11, 42, 9, 8, 4, 2, 1, 11 */],
            pointHoverRadius: 5,
            pointHoverBackgroundColor: '#47C1BF'
          }
        ]
      },
      IncomingCalls: {
        labels: [/* 'January', 'February', 'March', 'April', 'May', 'June', 'July' */],
        datasets: [
          {
            label: 'Data One',
            backgroundColor: '#51B83C',
            hoverBackgroundColor: '#118043',
            data: [/* 40, 39, 10, 40, 39, 80, 40 */]
          }
        ]
      },
      OutgoingCalls: {
        labels: [/* 'January', 'February', 'March', 'April', 'May', 'June', 'July' */],
        datasets: [
          {
            label: 'Data One',
            backgroundColor: '#9C6ADE',
            hoverBackgroundColor: '#50248F',
            data: [/* 40, 39, 10, 40, 39, 80, 40 */]
          }
        ]
      },
      IncomingSMS: {
        labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
        datasets: [
          {
            label: 'Data One',
            backgroundColor: '#6E016B',
            hoverBackgroundColor: '#4D004B',
            data: [40, 39, 10, 40, 39, 80, 40]
          }
        ]
      },
      OutgoingSMS: {
        labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
        datasets: [
          {
            label: 'Data One',
            backgroundColor: '#BFD3E6',
            hoverBackgroundColor: '#8B96C6',
            data: [40, 39, 10, 40, 39, 80, 40]
          }
        ]
      },
      OutgoingCallsCountries: {
        labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
        datasets: [
          {
            label: 'Data One',
            backgroundColor: '#46C1BF',
            hoverBackgroundColor: '#01848E',
            data: [40, 39, 10, 40, 39, 80, 40]
          }
        ]
      },
      status: ''
    },
    statusEdit: false,
    newStatus: '',
    dashboardCall: {
      duration: 0
    },
    dashboardSMS: {
      sent: 0,
      received: 0,
      total: 0
    },
    toggleSearch: false
  }),
  computed: {
    isMobile () {
      return this.$vuetify.breakpoint.smAndDown
    },
    queuesNumber () {
      let result = 0
      this.dashboard.queues.forEach(q => {
        result += q.users.length
      })
      return result
    },
    userName () {
      return this.$store.state.auth.currentUser.firstName
    },
    computedCallHours () {
      return {
        labels: this.callHoursLabels.map(item => this.getLabel(item, this.allFilters.groupBy)),
        datasets: [
          {
            label: 'Incoming',
            backgroundColor: this.incomingColor,
            borderColor: this.incomingColor,
            data: this.incomingCallHoursData || []
            // pointHoverRadius: 5,
            // pointHoverBackgroundColor: this.incomingColor
          },
          {
            label: 'Outgoing',
            backgroundColor: this.outgoingColor,
            borderColor: this.outgoingColor,
            data: this.outgoingCallHoursData || []
            // pointHoverRadius: 5,
            // pointHoverBackgroundColor: this.outgoingColor
          }
        ]
      }
    }
  },
  watch: {
    allFilters: {
      handler () {
        this.getAllCallLogs()
      },
      deep: true
    },
    'allFilters.groupBy' () {
      this.$refs.allFiltersDate.reset()
    },
    incomingFilters: {
      handler () {
        this.getIncomingCallLogs()
      },
      deep: true
    },
    incomingCallsType () {
      this.getIncomingCallLogs()
    },
    incomingSmsFilters: {
      handler () {
        // console.log('inc SMSS')
        this.getIncomingSmsLogs()
      },
      deep: true
    },
    outgoingFilters: {
      handler () {
        this.getOutgoingCallLogs()
      },
      deep: true
    },
    outgoingCallsType () {
      this.getOutgoingCallLogs()
    },
    outgoingSmsFilters: {
      handler () {
        console.log('inc SMSS')
        this.getOutgoingSmsLogs()
      },
      deep: true
    },
    toggleSearch (val) {
      this.setMobileSearch(val)
    }
  },
  created () {
    this.reset()
    this.getData()
    this.getCallLogs()
    this.getSmsLogs()
    this.getIncomingSmsLogs()
    this.getOutgoingSmsLogs()
    this.getAllCallLogs()
    this.getIncomingCallLogs()
    this.getOutgoingCallLogs()
    this.loadAvailableCountries()

    // this.status = JSON.parse(localStorage.getItem('currentCompany')).notice

    // console.log(JSON.parse(localStorage.getItem('currentCompany')).notice)
  },
  methods: {
    ...mapActions([
      'getAccountData', 'getMembers', 'didNumbersList', 'getLogReport', 'getDashboardCall',
      'getDashboardSMS', 'getVoicemails', 'getAvailableCountries'
    ]),
    ...mapMutations('components', ['setMobileSearch']),
    async getAllUsers () {
      await this.$store
        .dispatch('getAllUsers')
        .then((res) => {
          this.allUsers = res.data
        }).catch(err => {
          console.log('err', err)
        })
    },
    async getData () {
      try {
        await this.getMembers()
          .then(res => {
            this.setUsers(res.data.members)
          })
        await this.didNumbersList()
          .then(res => {
            this.setNumbers(res.data.didList)
          })
        const { data } = await this.getAccountData(this.$store.state.auth.currentUser.role)
        this.dashboard.status = data.notice
      } catch (e) {
        throw new Error(e)
      }
    },
    setUsers (users) {
      const logTypes = [
        {
          label: 'All users',
          value: ''
        }
      ]
      users.forEach(item => {
        logTypes.push({
          label: `${item.user_first_name} ${item.user_last_name}`,
          value: item.user_uuid
        })
      })
      this.allUsers = logTypes
    },
    setNumbers (numbers) {
      const logTypes = [
        {
          label: 'All numbers',
          value: ''
        }
      ]
      numbers.forEach(item => {
        logTypes.push({
          label: item.didNumber,
          value: item.didNumber
        })
      })
      this.allNumbers = logTypes
    },
    editStatus () {
      this.statusEdit = true
      this.newStatus = this.dashboard.status
      setTimeout(() => {
        if (this.$refs.status) this.$refs.status.focus()
      }, 100)
    },
    updateStatus () {
      try {
        this.$store
          .dispatch('editNotice', { notice: this.newStatus })
          .then((res) => {
            this.$snotify.notifications = []
            this.$snotify.success('Successful')
          }).catch(err => {
            this.$snotify.error(err)
            throw new Error(err)
          })
        this.dashboard.status = this.newStatus
        this.statusEdit = false
      } catch (e) {
        throw new Error(e)
      }
    },
    cancelStatusUpdate () {
      this.newStatus = this.dashboard.status
      this.statusEdit = false
    },
    async getCallLogs () {
      try {
        const today = moment(new Date()).format('YYYY-MM-DD')
        const params = {
          startDate: today,
          endDate: today,
          callDir: 'all',
          groupBy: 'day'
        }
        const { data } = await this.getDashboardCall(params)
        const duration = data.payload.reduce((accumulator, e) => accumulator + e.total_duration, 0)

        this.dashboardCall.duration = duration
        // this.dashboardCall.total = data.payload[0].total_attempt
      } catch (err) {
        throw new Error(err)
      }
    },
    reset () {
      const today = new Date().toISOString().split('T')[0]
      this.allFilters.startDate = today
      this.allFilters.endDate = today
      this.incomingFilters.startDate = today
      this.incomingFilters.endDate = today
      this.outgoingFilters.startDate = today
      this.outgoingFilters.endDate = today
    },
    changeStartDate (value) {
      if (new Date(value) > new Date(this.allFilters.endDate)) {
        this.allFilters.startDate = `${this.allFilters.endDate}`
        this.allFilters.endDate = value
      } else this.allFilters.startDate = value
      this.getAllCallLogs()
    },
    changeEndDate (value) {
      if (new Date(value) < new Date(this.allFilters.startDate)) {
        this.allFilters.endDate = `${this.allFilters.startDate}`
        this.allFilters.startDate = value
      } else this.allFilters.endDate = value
      this.getAllCallLogs()
    },
    changeIncomingStartDate (value) {
      if (new Date(value) > new Date(this.incomingFilters.endDate)) {
        this.incomingFilters.startDate = `${this.incomingFilters.endDate}`
        this.incomingFilters.endDate = value
      } else this.incomingFilters.startDate = value
      this.getIncomingCallLogs()
    },
    changeIncomingEndDate (value) {
      if (new Date(value) < new Date(this.incomingFilters.startDate)) {
        this.incomingFilters.endDate = `${this.incomingFilters.startDate}`
        this.incomingFilters.startDate = value
      } else this.incomingFilters.endDate = value
      this.getIncomingCallLogs()
    },
    changeOutgoingStartDate (value) {
      if (new Date(value) > new Date(this.outgoingFilters.endDate)) {
        this.outgoingFilters.startDate = `${this.outgoingFilters.endDate}`
        this.outgoingFilters.endDate = value
      } else this.outgoingFilters.startDate = value
      this.getOutgoingCallLogs()
    },
    changeOutgoingEndDate (value) {
      if (new Date(value) < new Date(this.outgoingFilters.startDate)) {
        this.outgoingFilters.endDate = `${this.outgoingFilters.startDate}`
        this.outgoingFilters.startDate = value
      } else this.outgoingFilters.endDate = value
      this.getOutgoingCallLogs()
    },
    changeIncomingSMSStartDate (value) {
      if (new Date(value) > new Date(this.incomingSmsFilters.endDate)) {
        this.incomingSmsFilters.startDate = `${this.incomingSmsFilters.endDate}`
        this.incomingSmsFilters.endDate = value
      } else this.incomingSmsFilters.startDate = value
      this.getIncomingSmsLogs()
    },
    changeIncomingSMSEndDate (value) {
      if (new Date(value) < new Date(this.incomingSmsFilters.startDate)) {
        this.incomingSmsFilters.endDate = `${this.incomingSmsFilters.startDate}`
        this.incomingSmsFilters.startDate = value
      } else this.incomingSmsFilters.endDate = value
      this.getIncomingSmsLogs()
    },
    changeOutgoingSMSStartDate (value) {
      if (new Date(value) > new Date(this.outgoingSmsFilters.endDate)) {
        this.outgoingSmsFilters.startDate = `${this.outgoingSmsFilters.endDate}`
        this.outgoingSmsFilters.endDate = value
      } else this.outgoingSmsFilters.startDate = value
      this.getOutgoingSmsLogs()
    },
    changeOutgoingSMSEndDate (value) {
      if (new Date(value) < new Date(this.outgoingSmsFilters.startDate)) {
        this.outgoingSmsFilters.endDate = `${this.outgoingSmsFilters.startDate}`
        this.outgoingSmsFilters.startDate = value
      } else this.outgoingSmsFilters.endDate = value
      this.getOutgoingSmsLogs()
    },
    format (val) {
      return moment(val).format('YYYY-MM-DD HH:mm:ss')
    },
    getDates ({ startDate, endDate, groupBy }) {
      const runDate = new Date(moment(new Date(startDate)).format('YYYY-MM-DD') + ' 00:00:00')
      let stopDate = new Date(moment(new Date(endDate)).format('YYYY-MM-DD') + ' 23:59:59')
      if (groupBy === 'month') {
        const tempStart = new Date(runDate)
        const temp = new Date(tempStart.setMonth(tempStart.getMonth()))

        if (runDate < stopDate && temp >= stopDate) stopDate = new Date(stopDate.setMonth(stopDate.getMonth()))
      }

      var dateArray = []
      var currentDate = runDate

      while (currentDate <= stopDate) {
        dateArray.push(this.format(currentDate))

        switch (groupBy) {
          case 'hour':
            currentDate = new Date(currentDate.setHours(currentDate.getHours() + 1))
            break
          case 'day':
            currentDate = new Date(currentDate.setDate(currentDate.getDate() + 1))
            break
          case 'month':
            currentDate = new Date(currentDate.setMonth(currentDate.getMonth() + 1))
        }
      }
      return dateArray
    },
    findIndex (list, groupBy, y, m, d, h) {
      for (let i = 0; i < list.length; i++) {
        const [tDate, tTime] = list[i].split(' ')
        const [ty, tm, td] = tDate.split('-')
        const th = tTime.split(':')[0]

        switch (groupBy) {
          case 'hour':
            if (ty === y && tm === m && td === d && th === h) return i
            break
          case 'day':
            if (ty === y && tm === m && td === d) return i
            break
          case 'month':
            if (ty === y && tm === m) return i
            break
        }
      }
      return -1
    },
    getLabel (item, groupBy) {
      const [date, time] = item.split(' ')
      const [hour, min, sec] = time.split(':')
      const day = parseInt(date.split('-')[2])
      const month = this.monthNames[new Date(item).getMonth()]
      switch (groupBy) {
        case 'hour':
          return `${month} ${day} ${hour}:${min}`
        case 'day':
          return `${month} ${day}`
        case 'month':
          return month
      }
    },
    async getAllCallLogs () {
      try {
        const { data: { payload } } = await this.getDashboardCall(this.allFilters)

        const dateArray = this.getDates(this.allFilters)
        this.callHoursLabels = []
        this.incomingCallHoursData = []
        this.outgoingCallHoursData = []

        dateArray.forEach(dateTime => {
          this.callHoursLabels.push(dateTime)
          this.incomingCallHoursData.push(0)
          this.outgoingCallHoursData.push(0)
        })

        for (const element of payload) {
          const [date, time] = this.formatDate(element.created_on).split(' ')
          const [y, m, d] = date.split('-')
          const h = time.split(':')[0]

          const index = this.findIndex(this.callHoursLabels, this.allFilters.groupBy, y, m, d, h)
          if (index < 0) continue

          switch (element.direction) {
            case 'in':
              this.incomingCallHoursData[index] += element.total_duration
              break
            case 'out':
              this.outgoingCallHoursData[index] += element.total_duration
              break
          }
        }
        this.$nextTick(() => {
          if (this.$refs.refCallHours) this.$refs.refCallHours.reload()
        })
      } catch (err) {
        throw new Error(err)
      }
    },
    async getIncomingCallLogs () {
      try {
        const { data: { payload } } = await this.getDashboardCall(this.incomingFilters)

        const dateArray = this.getDates(this.incomingFilters)
        const labels = []
        const data = []

        dateArray.forEach(dateTime => {
          labels.push(dateTime)
          data.push(0)
        })

        for (const element of payload) {
          const [date, time] = this.formatDate(element.created_on).split(' ')
          const [y, m, d] = date.split('-')
          const h = time.split(':')[0]

          const index = this.findIndex(labels, this.incomingFilters.groupBy, y, m, d, h)
          if (index < 0) continue
          if (this.incomingCallsType === 'attempts') {
            data[index] += parseInt(element.total_attempt)
          } else if (this.incomingCallsType === 'connected') {
            data[index] += parseInt(element.total_duration)
          } else {
            data[index] += parseInt(element.total_attempt) + parseInt(element.total_duration)
          }
        }
        this.dashboard.IncomingCalls.labels = labels.map(item => this.getLabel(item, this.incomingFilters.groupBy))
        this.dashboard.IncomingCalls.datasets[0].data = data
        if (this.$refs.incomingBar) this.$refs.incomingBar.reload()
      } catch (err) {
        throw new Error(err)
      }
    },
    async getOutgoingCallLogs () {
      try {
        const { data: { payload } } = await this.getDashboardCall(this.outgoingFilters)

        const dateArray = this.getDates(this.outgoingFilters)
        const labels = []
        const data = []

        dateArray.forEach(dateTime => {
          labels.push(dateTime)
          data.push(0)
        })

        for (const element of payload) {
          const [date, time] = this.formatDate(element.created_on).split(' ')
          const [y, m, d] = date.split('-')
          console.log(m, 'm')
          const h = time.split(':')[0]

          const index = this.findIndex(labels, this.outgoingFilters.groupBy, y, m, d, h)
          if (index < 0) continue
          if (this.outgoingCallsType === 'attempts') {
            data[index] += parseInt(element.total_attempt)
          } else if (this.outgoingCallsType === 'connected') {
            data[index] += parseInt(element.total_duration)
          } else {
            data[index] += parseInt(element.total_attempt) + parseInt(element.total_duration)
          }
        }
        this.dashboard.OutgoingCalls.labels = labels.map(item => this.getLabel(item, this.outgoingFilters.groupBy))
        this.dashboard.OutgoingCalls.datasets[0].data = data
        if (this.$refs.outgoingBar) this.$refs.outgoingBar.reload()
      } catch (err) {
        throw new Error(err)
      }
    },
    async getSmsLogs () {
      const today = moment(new Date()).format('YYYY-MM-DD')
      const params = {
        startDate: today,
        endDate: today,
        smsDir: 'all',
        groupBy: 'day'
      }

      const { data: { payload } } = await this.getDashboardSMS(params)

      const report = payload.reduce((accumulator, e) => accumulator + parseInt(e.total), 0)
      this.dashboardSMS = payload.length ? payload[0] : this.dashboardSMS
      this.dashboardSMS.total = report
    },
    async getIncomingSmsLogs () {
      const { data: { payload } } = await this.getDashboardSMS(this.incomingSmsFilters)

      const data = []
      const labels = []

      let prevMonth = ''
      let prevIdx = null

      for (const element of payload) {
        const month = new Date(element.created_on).toLocaleString('default', { month: 'long' })

        if (prevMonth === month) {
          data[prevIdx] += element.total
        } else {
          labels.push(month)
          data.push(element.total)
        }

        prevMonth = month
        prevIdx = data.length - 1
      }

      this.dashboard.IncomingSMS.labels = labels
      this.dashboard.IncomingSMS.datasets[0].data = data
      if (this.$refs.incomingSmsBar) this.$refs.incomingSmsBar.reload()
    },
    async getOutgoingSmsLogs () {
      const { data: { payload } } = await this.getDashboardSMS(this.outgoingSmsFilters)

      const data = []
      const labels = []

      let prevMonth = ''
      let prevIdx = null

      for (const element of payload) {
        const month = new Date(element.created_on).toLocaleString('default', { month: 'long' })

        if (prevMonth === month) {
          data[prevIdx] += element.total
        } else {
          labels.push(month)
          data.push(element.total)
        }

        prevMonth = month
        prevIdx = data.length - 1
      }

      this.dashboard.OutgoingSMS.labels = labels
      this.dashboard.OutgoingSMS.datasets[0].data = data
      if (this.$refs.outgoingSmsBar) this.$refs.outgoingSmsBar.reload()
    },
    async loadAvailableCountries () {
      try {
        const { data } = await this.getAvailableCountries()
        if (!data) return
        this.availableCountries.push(...data.payload)
      } catch (err) {
        throw new Error(err)
      }
    },
    toHourMinSec (value) {
      const hh = Math.floor(value / 3600)
      const mm = Math.floor((value - hh * 3600) / 60)
      const ss = value % 60
      return `${hh ? hh + 'h ' : ''}${mm ? mm + 'm ' : ''}${ss}s`
    }
  }
}
</script>
<style scoped lang="scss">
  .title {
    display: flex;
    min-width: 20%;
  }
  .select::v-deep .v-input__control {
    min-height: 30px !important;
  }
  .line-chart-color {
    width: 30px;
    height: 10px;
  }
  .line-h-32 {
    line-height: 32px !important;
  }
    @media (max-width: 1450px) {
      // .responsive_m_r {
      //   // margin-right: 28px;
      // }
      .responsive_w_25 {
        max-width: 25%;
      }
      .responsive_w_40 {
        max-width: 40%;
      }
    }
    @media (min-width: 960px) and (max-width: 1400px) {
      .card-container {
        &__block {
          flex-direction: column;
        }

        .numbers, .users, .groupBy {
          max-width: 100%;
          flex: 2;
        }

        .dates, .title {
          max-width: 100%;
          flex: 1;
        }
      }
    }
     @media (min-width: 700px) and (max-width: 760px) {
      .section-header {
        font-size: 14px !important;
      }
    }
    @media (max-width: 700px) {
      .card-container {
        &__block {
          flex-direction: column;
        }

        .numbers, .users, .groupBy {
          max-width: 100%;
          flex: 2;
        }

        .dates, .title {
          max-width: 100%;
          flex: 1;
        }
      }
    }
</style>
