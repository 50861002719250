import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import Snotify, { SnotifyPosition } from 'vue-snotify'
import VueHtmlToPaper from 'vue-html-to-paper'

import '@/assets/styles/main.scss'

// setup fake backend
import { configureFakeBackend } from './_helpers'
import 'vue-snotify/styles/simple.css'

const snotifyOptions = {
  toast: {
    position: SnotifyPosition.centerTop
  }
}
Vue.use(Snotify, snotifyOptions)

const options = {
  name: '_blank',
  specs: [
    'fullscreen=yes',
    'titlebar=yes',
    'scrollbars=yes'
  ],
  styles: [
    'https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css',
    'https://unpkg.com/kidlat-css/css/kidlat.css'
  ],
  timeout: 1000, // default timeout before the print window appears
  autoClose: true, // if false, the window will not close after printing
  windowTitle: window.document.title // override the window title
}
Vue.use(VueHtmlToPaper, options)

Vue.config.productionTip = false
configureFakeBackend()

new Vue({
  router,
  store,
  vuetify,
  data: {
    currentUser: null
  },
  render: h => h(App)
}).$mount('#app')
