var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-overlay',{staticClass:"fill-width ml-auto dial-overlay dial-overlay-incoming",attrs:{"z-index":"100"}},[_c('v-container',{staticClass:"mb-16 mb-md-0 pb-md-0 pb-16"},[_c('v-row',{ref:"container",staticClass:"ml-auto d-flex justify-md-end justify-center mb-16 mb-md-0"},[_c('v-col',{staticClass:"d-flex justify-center align-center py-10",staticStyle:{"position":"relative","background":"white","max-width":"300px"},attrs:{"cols":"12","sm":"3"}},[_c('div',{staticClass:"d-flex flex-column align-center"},[_c('div',{staticClass:"mb-16"},[_c('v-img',{staticClass:"mx-auto",attrs:{"src":require('../assets/call-avatar.svg'),"contain":"","width":"72","height":"72","max-width":"min-content"}}),_c('v-list',{staticClass:"white"},[_c('v-list-item',{staticClass:"theme--light white"},[_c('v-list-item-content',{staticClass:"text-center"},[_c('v-list-item-title',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(_vm.phone)+" ")]),(!_vm.callIsActive)?_c('v-list-item-subtitle',{staticClass:"mt-4 caption",staticStyle:{"color":"rgba(0, 0, 0, .87)"}},[_vm._v(" Call ")]):_c('v-list-item-subtitle',{staticClass:"mt-4 caption",staticStyle:{"color":"rgba(0, 0, 0, .87)"}},[_vm._v(" "+_vm._s(_vm.timeDisplay)+" ")])],1)],1)],1)],1),_c('v-row',{staticStyle:{"max-width":"250px"}},[_c('v-col',{staticClass:"d-flex flex-column align-center pa-0 mb-8",attrs:{"cols":"6","sm":"6"}},[_c('div',[_c('v-img',{staticClass:"mx-auto",staticStyle:{"cursor":"pointer"},attrs:{"src":require('../assets/call-message.svg'),"contain":"","width":"72","height":"72","max-width":"min-content"}})],1),_c('v-list-item-subtitle',{staticClass:"text-center caption mt-3 text--black"},[_vm._v("Message ")])],1),_c('v-col',{staticClass:"d-flex flex-column align-center pa-0 mb-8",attrs:{"cols":"6","sm":"6"}},[_c('div',[_c('v-img',{staticClass:"mx-auto",style:(_vm.mutedDisabled ? 'pointer-events: none' : 'cursor: pointer'),attrs:{"src":require(`../assets/${
                      _vm.mute ? 'call-mute-active.svg' : 'call-mute.svg'
                    }`),"contain":"","width":"72","height":"72","max-width":"min-content"},on:{"click":function($event){return _vm.muteCall()}}})],1),_c('v-list-item-subtitle',{staticClass:"text-center caption mt-3"},[_vm._v("Mute ")])],1),_c('v-col',{staticClass:"d-flex flex-column align-center pa-0 mb-8",attrs:{"cols":"6","sm":"6"}},[_c('div',[_c('v-img',{staticClass:"mx-auto",style:(`${
                    _vm.holdDisable
                      ? 'cursor: no-drop; pointer-events: none'
                      : 'cursor: pointer; pointer-events: inherit'
                  }`),attrs:{"src":require(`../assets/${
                      _vm.hold ? 'call-hold.svg' : 'call-hold-active.svg'
                    }`),"contain":"","width":"72","height":"72","max-width":"min-content"},on:{"click":function($event){return _vm.holdCall()}}})],1),_c('v-list-item-subtitle',{staticClass:"text-center caption mt-3"},[_vm._v("Hold ")])],1),_c('v-col',{staticClass:"d-flex flex-column align-center pa-0 mb-8",attrs:{"cols":"6","sm":"6"}},[_c('div',[_c('v-img',{staticClass:"mx-auto",staticStyle:{"cursor":"pointer"},attrs:{"src":require('../assets/call-keypad.svg'),"contain":"","width":"72","height":"72","max-width":"min-content"}})],1),_c('v-list-item-subtitle',{staticClass:"text-center caption mt-3"},[_vm._v("Keypad ")])],1)],1),_c('div',{staticClass:"mt-16 w-100 d-flex"},[_c('v-img',{staticClass:"mx-auto",staticStyle:{"cursor":"pointer"},attrs:{"src":require('../assets/call-end.svg'),"contain":"","width":"72","height":"72","max-width":"min-content"},on:{"click":function($event){return _vm.hangup()}}})],1)],1)])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }