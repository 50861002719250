<template>
  <div>
    <template v-for="(voicemail, voicemailIndex) in voicemails">
      <v-divider
        v-if="voicemailIndex"
        :key="voicemailIndex + 'divider'"
      ></v-divider>
      <v-list-item v-if="isDashboard" :key="voicemailIndex + 'list_item'" class="px-0">
        <v-list-item-avatar
          width="40px"
          height="40px"
          class="mr-4 my-3"
          :class="record === voicemail.a_leg_record_url ? 'primary' : 'paused'"
        >
          <v-tooltip top content-class="v-tooltip--top">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                v-bind="attrs"
                v-on="on"
                @click.stop.prevent="play(voicemail.a_leg_record_url)"
              >
                <v-icon color="#919EAB">{{record === voicemail.a_leg_record_url ? 'pause' : 'play_arrow'}}</v-icon>
              </v-btn>
            </template>
            <span>{{record === voicemail.a_leg_record_url ? 'Pause' : 'Play'}} A record</span>
          </v-tooltip>
        </v-list-item-avatar>
        <v-list-item-avatar
          width="40px"
          height="40px"
          class="mr-4 my-3"
          :class="record === voicemail.b_leg_record_url ? 'primary' : 'paused'"
        >
          <v-tooltip top content-class="v-tooltip--top">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                v-bind="attrs"
                v-on="on"
                @click.stop.prevent="play(voicemail.b_leg_record_url)"
              >
                <v-icon color="#919EAB">{{record === voicemail.b_leg_record_url ? 'pause' : 'play_arrow'}}</v-icon>
              </v-btn>
            </template>
            <span>{{record === voicemail.b_leg_record_url ? 'Pause' : 'Play'}} B record</span>
          </v-tooltip>
        </v-list-item-avatar>
        <v-list-item-content class="d-flex align-center">
          <div style="flex: unset;">
            <v-list-item-title class="fz-14 font-weight-medium">
              <!-- <span v-if="voicemail.caller_first_name || voicemail.caller_last_name">{{ voicemail.caller_first_name }} {{ voicemail.caller_last_name }}</span>
              <span v-else>{{ voicemail.ani }}</span> -->
              <span style="color: #1D2428;">{{ formatDateAmPm(voicemail.created).split('|')[0] }} </span>
              <span style="color: #637381;">{{ formatDateAmPm(voicemail.created).split('|')[1] }}</span>
            </v-list-item-title>
            <v-list-item-subtitle>
              <span>{{ voicemail.mailbox_name }} </span>
              <span>{{ voicemail.transcription }}</span>
            </v-list-item-subtitle>
          </div>
          <div
            v-if="record === voicemail.a_leg_record_url || record === voicemail.b_leg_record_url"
            class="progress-bar mx-auto"
            style="width: 50%; flex: unset; max-height: 25px;"
          >
            <v-layout justify-space-between class="ma-0">
              <span class="caption">{{formatTime(time.current)}}</span>
              <span class="caption">{{formatTime(time.total)}}</span>
            </v-layout>
            <v-slider
              :value="time.percentage"
              readonly
              class="slider mt-n2"
            ></v-slider>
          </div>
        </v-list-item-content>
        <v-list-item-action class="w-max">
          <div>
            <v-tooltip top content-class="v-tooltip--top">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  class="mr-2 voicemail-button"
                  outlined
                  v-bind="attrs"
                  v-on="on"
                  @click="showTranscript(voicemail)"
                >
                  <v-icon color="#919EAB">record_voice_over</v-icon>
                </v-btn>
              </template>
              <span>View the transcript</span>
            </v-tooltip>
            <v-tooltip top content-class="v-tooltip--top">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  class="voicemail-button"
                  outlined
                  v-bind="attrs"
                  v-on="on"
                  @click="download(voicemail)"
                >
                  <v-icon color="#919EAB">download</v-icon>
                </v-btn>
              </template>
              <span>Download</span>
            </v-tooltip>
          </div>
        </v-list-item-action>
      </v-list-item>

      <v-list-item v-else :key="voicemailIndex + 'list_item'" class="pl-1 voicemail-item">
        <v-icon size="20px" class="mr-1 pointer">apps</v-icon>

        <v-list-item-avatar
          width="40px"
          height="40px"
          class="mr-4 my-3"
        >
          <v-img
            :alt="`${voicemail.caller_first_name} avatar`"
            :src="'https://picsum.photos/200?random=' + voicemail.ani"
          >
            <v-icon color="#fff">phone</v-icon>
          </v-img>
        </v-list-item-avatar>

        <v-list-item-content class="align-center">
          <v-list-item-title class="fz-14 font-weight-bold dark-text">
            <span v-if="voicemail.caller_first_name || voicemail.caller_last_name">{{ voicemail.caller_first_name }} {{ voicemail.caller_last_name }}</span>
            <span v-else>{{ voicemail.ani }}</span>
            <v-icon
              v-if="voicemail.callBacked"
              color="#50B83C"
              class="mb-1"
            >
              done
            </v-icon>
          </v-list-item-title>
          <v-list-item-subtitle
            class="fz-14 grey--text text--darken-1"
          >
            <p class="ma-0">
              <span>{{ formatDate(voicemail.created) }}</span>
              <span v-if="voicemail.transcription">, {{voicemail.transcription}}</span>
            </p>
            <p class="ma-0">
              <span>{{ voicemail.ani_city }}</span>
              <span v-if="voicemail.ani_state">, {{voicemail.ani_state}}</span>
            </p>
          </v-list-item-subtitle>
        </v-list-item-content>

        <v-list-item-action class="flex-row align-center v-list-item__action--hover"> <!--  v-if="!callbackView" -->
          <v-tooltip top content-class="v-tooltip--top">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                class="mr-2"
                v-bind="attrs"
                v-on="on"
                @click="download(voicemail)"
              >
                <v-icon color="#919EAB">download</v-icon>
              </v-btn>

            </template>
            <span>Download</span>
          </v-tooltip>

          <v-tooltip top content-class="v-tooltip--top">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                class="mr-2"
                v-bind="attrs"
                v-on="on"
                @click.stop.prevent="play(voicemail.a_leg_record_url)"
              >
                <v-icon color="#919EAB">{{record === voicemail.a_leg_record_url ? 'pause' : 'play_arrow'}}</v-icon>
              </v-btn>

            </template>
            <span>{{record === voicemail.a_leg_record_url ? 'Pause' : 'Play'}} A record</span>
          </v-tooltip>

          <v-tooltip top content-class="v-tooltip--top">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                class="mr-2"
                v-bind="attrs"
                v-on="on"
                @click.stop.prevent="play(voicemail.b_leg_record_url)"
              >
                <v-icon color="#919EAB">{{record === voicemail.b_leg_record_url ? 'pause' : 'play_arrow'}}</v-icon>
              </v-btn>

            </template>
            <span>{{record === voicemail.b_leg_record_url ? 'Pause' : 'Play'}} B record</span>
          </v-tooltip>

          <v-tooltip top content-class="v-tooltip--top">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                class="mr-2"
                v-bind="attrs"
                v-on="on"
                @click="showTranscript(voicemail)"
              >
                <v-icon color="#919EAB">record_voice_over</v-icon>
              </v-btn>

            </template>
            <span>View the transcript</span>
          </v-tooltip>
        </v-list-item-action>
      </v-list-item>
    </template>
    <v-dialog
        v-model="dialog"
        persistent
      >
        <v-card :loading="dialogLoading">
          <v-card-title class="font-weight-bold">
            Transcript
            <v-spacer></v-spacer>
            <v-btn @click="closeDialog" small icon>
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-card-title>

          <v-divider />
          <v-card-subtitle v-if="a_leg_asr_url" class="black--text fz-16 mt-2">Transcript A</v-card-subtitle>
          <pre v-if="a_leg_asr_url" class="px-6">{{ a_leg_asr_url }}</pre>
          <p v-else class="text-center mt-3">Transcript A is empty</p>
          <v-divider class="mt-2" />
          <v-card-subtitle v-if="a_leg_asr_url" class="black--text fz-16 mt-2">Transcript B</v-card-subtitle>
          <pre v-if="b_leg_asr_url" class="px-6">{{ b_leg_asr_url }}</pre>
          <p v-else class="text-center mt-3">Transcript B is empty</p>

          <v-card-actions class="pb-5 pt-3 justify-md-end">
            <v-btn
              color="secondary"
              outlined
              class="w-100 text-capitalize"
              @click="closeDialog"
            >
              Close
            </v-btn>
          </v-card-actions>
        </v-card>
    </v-dialog>
    <audio
      ref="audio"
      :src="record"
      @play="onPlay"
      @pause="onPause"
      @ended="ended"
      @durationchange="onDurationChange"
    >
    </audio>
  </div>
</template>

<script>
export default {
  name: 'voicemail-component',
  props: {
    voicemails: {
      type: Array,
      required: true
    }
  },
  data: () => ({
    paused: true,
    record: '',
    dialog: false,
    dialogLoading: false,
    a_leg_asr_url: '',
    b_leg_asr_url: '',
    time: {
      total: 0,
      current: 0,
      interval: null,
      percentage: 0
    }
  }),
  computed: {
    isDashboard () {
      return this.$route.name === 'Home'
    }
  },
  methods: {
    play (record) {
      if (this.record === record) return this.ended()

      this.record = record
      this.time.current = 0
      this.time.percentage = 0
      setTimeout(() => {
        this.$refs.audio.play()
        this.paused = false
      }, 100)
    },
    download (voicemail) {
      const flink = document.createElement('a')

      flink.href = voicemail.a_leg_record_url
      flink.download = 'a_leg_record_' + this.formatDate(voicemail.created)
      flink.click()
      flink.remove()

      setTimeout(() => {
        const slink = document.createElement('a')
        slink.href = voicemail.b_leg_record_url
        slink.download = 'b_leg_record_' + this.formatDate(voicemail.created)
        slink.click()
        slink.remove()
      }, 2000)
    },
    async showTranscript (voicemail) {
      this.dialog = true
      this.dialogLoading = true
      const headers = {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
      if (voicemail.a_leg_asr_url) {
        await fetch(voicemail.a_leg_asr_url, { headers })
          .then(r => r.json())
          .then(json => {
            this.a_leg_asr_url = json?.a_live?.txt || ''
          })
          .catch(err => {
            throw new Error(err)
          })
      }
      if (voicemail.b_leg_asr_url) {
        await fetch(voicemail.b_leg_asr_url, { headers })
          .then(r => r.json())
          .then(json => {
            this.b_leg_asr_url = json?.b_live?.txt || ''
          })
          .catch(err => {
            throw new Error(err)
          })
      }
      this.dialogLoading = false
    },
    closeDialog () {
      this.dialog = false
      this.dialogLoading = false
      this.a_leg_asr_url = ''
      this.b_leg_asr_url = ''
    },
    ended () {
      this.$refs.audio.pause()
      this.paused = true
      this.record = ''
    },
    onPlay () {
      this.paused = false
      const interval = setInterval(() => {
        this.updateTime(this.$refs.audio.currentTime, this.$refs.audio.duration, interval)
      }, 15)

      this.time.interval = interval
    },
    onPause () {
      this.paused = true
      clearInterval(this.time.interval)
    },
    updateTime (currentTime, duration, interval) {
      const percentage = (currentTime / duration) * 100
      if (percentage >= 100) {
        this.time.percentage = 100 // avoid 99.85
        return clearInterval(interval)
      }

      this.time.current = currentTime
      this.time.percentage = percentage
    },
    onDurationChange (e) {
      this.time.total = e.target.duration
    },
    formatTime (time) {
      time = parseInt(time)
      const m = parseInt(time / 60)
      const s = time % 60
      return `${m}:${s < 10 ? `0${s}` : s}`
    }
  }
}
</script>

<style lang="scss" scoped>
.paused {
  border: 1px solid #C4CDD5;
}
.voicemail-button {
  border-color: #C4CDD5;
  background-color: #161d250d;
  width: 44px !important;
  min-width: unset !important;
}
.slider ::v-deep {
  & .v-slider__thumb {
    width: 6px !important;
    height: 6px !important;
  }
}
</style>
