<template>
  <v-list-item class="pl-4 pr-6" :to="!noLink ? { name: 'Mailbox', params: { boxName: item.title }} : ''">

    <v-list-item-content>
      <v-list-item-title class="fz-14 font-weight-semi-bold">
        {{ item.title }}
      </v-list-item-title>

      <v-list-item-subtitle class="fz-14">{{ item.users.length }} people in queue</v-list-item-subtitle>
    </v-list-item-content>
    <div class="d-flex flex-row-reverse align-center py-3">
      <v-img
        v-for="(person, personIndex) in listToShow"
        :key="personIndex"
        :alt="`${person.name} avatar`"
        :src="person.avatar + '?random=' + personIndex"
        height="39"
        width="39"
        class="rounded-circle ml-n6"
        style="border: 1px solid white; min-width: 39px"
      ></v-img>
      <div v-if="item.users.length > 5" class="mr-8" style="color: #637381">{{ item.users.length - 5 }}+</div>
    </div>

  </v-list-item>
</template>

<script>
export default {
  name: 'QueueItem',
  props: {
    item: {
      type: Object,
      required: true
    },
    noLink: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    listToShow () {
      return this.item.users.slice(0, 5)
    }
  }
}
</script>

<style scoped>

</style>
