import Vue from 'vue'
import Vuex from 'vuex'
import auth from './modules/auth'
import desk from './modules/desk'
import user from './modules/user'
import number from './modules/number'
import components from './modules/components'
import callflow from './modules/callflow'
import admin from './modules/admin'
import campaign from './modules/campaign'
import accounts from './modules/accounts'

Vue.use(Vuex)

const debug = process.env.NODE_ENV !== 'production'

export default new Vuex.Store({
  state: {
    searchResults: undefined
  },
  mutations: {},
  actions: {},
  modules: {
    auth,
    desk,
    user,
    number,
    components,
    callflow,
    admin,
    campaign,
    accounts
  },
  strict: debug,
  plugins: []
})
