<template>
  <v-card
    class="pa-3 overflow-hidden mb-6"
    max-width="316px"
    :class="[callItem.active ? 'active-call' : '']"
  >
    <v-row>
      <v-col
        cols="12"
        md="12"
        class="d-flex align-center py-3"
        :class="callItem.active ? 'pl-3 pr-5' : 'px-5'"
      >
        <v-img
          :alt="`${callItem.firstname} avatar`"
          :src="callItem.avatar + '?random=' + callItem"
          :max-height="callItem.active ? '40px' : '50px'"
          :max-width="callItem.active ? '40px' : '50px'"
          class="rounded-circle mr-2"
        ></v-img>

        <div
          class="fz-14 d-flex justify-center fill-width"
          :class="callItem.active ? 'justify-space-between' : 'flex-column'"
        >
          <span v-if="callItem.firstname && callItem.lastname" class="indigo--text font-weight-medium">
            {{ fullName(callItem.firstname, callItem.lastname) }}
          </span>

          <!-- <span v-else-if="callItem.location" class="indigo--text font-weight-medium">
            {{ callItem.location }}
          </span> -->

          <span
            v-else
            class="grey--text"
          >
            {{ callItem.phoneNumber }}
          </span>
          <span
              v-if="callItem.is_spam"
              class="red--text"
          >
            Suspected spam
          </span>

          <span
            v-if="callIsActive"
            class="font-weight-bold"
          >
            {{ timeDisplay }}
          </span>
        </div>
      </v-col>

      <v-col
        cols="12"
        md="12"
        class="pa-0"
      >
        <v-divider class="hl-724"></v-divider>
      </v-col>

      <v-col
        cols="12"
        md="12"
        class="d-flex align-center justify-end pr-4"
      >
        <v-tooltip bottom content-class="v-tooltip--bottom" v-if="!callItem.active">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon
              :disabled="!activeAnswerButton"
              class="mr-2"
              v-bind="attrs"
              v-on="on"
              @click="answer()"
            >
              <v-icon
                size="24px"
                color="green"
              >
                {{ 'phone' }}
<!--                {{ callItem.active ? 'pause' : 'phone' }}-->
              </v-icon>
            </v-btn>
          </template>

          <span>{{ callItem.active ? 'Pause' : 'Answer' }}</span>
        </v-tooltip>

        <v-tooltip bottom content-class="v-tooltip--bottom" v-if="!callItem.active">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon
              :disabled="!callItem.active"
              class="mr-2"
              v-bind="attrs"
              v-on="on"
            >
              <v-icon
                size="24px"
                :color="callItem.active ? 'green' : '#919EAB'"
              >
                {{ 'person'}}
              </v-icon>
            </v-btn>
          </template>

          <span>{{ 'Account' }}</span>
        </v-tooltip>
        <v-tooltip bottom content-class="v-tooltip--bottom" v-if="callIsOutbound">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
                icon
                :disabled="callItem.disabledButtons"
                class="mr-2"
                v-bind="attrs"
                v-on="on"
                @click="hold()"
            >
              <v-icon
                  size="24px"
                  :color="!callItem.disabledButtons ? 'green' : '#919EAB'"
              >
                {{ !callItem.hold ? 'stop' : 'play_arrow' }}
              </v-icon>
            </v-btn>
          </template>

          <span>{{ callItem.active ? 'Hold' : 'Resume' }}</span>
        </v-tooltip>

        <v-tooltip v-if="callItem.checked" bottom content-class="v-tooltip--bottom">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon
              :disabled="!callItem.checked"
              class="mr-2"
              v-bind="attrs"
              v-on="on"
              @click="send()"
            >
              <v-icon
                size="24px"
                :color="callItem.checked ? 'green' : '#919EAB'"
              >
                send
              </v-icon>
            </v-btn>
          </template>

          <span>Send message</span>
        </v-tooltip>

        <SendAsMessage :dialog="sendMessageDialog" :sendMessageDialog.sync="sendMessageDialog"/>

        <v-tooltip v-if="callItem.active" bottom content-class="v-tooltip--bottom">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon
              :disabled="callItem.disabledButtons"
              class="mr-2"
              v-bind="attrs"
              v-on="on"
              @click="mute()"
            >
              <v-icon
                size="24px"
                :color="!callItem.disabledButtons ? 'green' : '#919EAB'"
              >
                {{ callItem.mute ? 'mic_off' : 'mic' }}
              </v-icon>
            </v-btn>
          </template>

          <span> {{ callItem.mute ? 'Unmute' : 'Mute' }} </span>
        </v-tooltip>

        <v-tooltip bottom content-class="v-tooltip--bottom" v-if="$props.callItem.uuidActiveCall">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon
              :disabled="callItem.disabledButtons"
              class="mr-2"
              v-bind="attrs"
              v-on="on"
              @click="record()"
            >
              <v-icon
                size="24px"
                :color="callItem.uuidActiveCall ? 'green' : '#919EAB'"
              >
                {{ callItem.record ? 'pause' : 'play_arrow' }}
              </v-icon>
            </v-btn>
          </template>

          <span> {{ callItem.record ? 'stop recording' : 'Start recording' }} </span>
        </v-tooltip>

        <v-menu
          bottom
          left
          max-width="203px"
          nudge-left="-8px"
          nudge-bottom="10px"
          :rounded="'1'"
          offset-y
          open-on-click
          content-class="elevation-0 v-menu--triangle"
        >
          <template v-slot:activator="{ on }">
            <v-btn
              icon
              v-on="on"
            >
              <v-icon size="24px" color="#ED6347">call_end</v-icon>
            </v-btn>
          </template>

          <v-card elevation="1">
            <v-list-item-content class="justify-center py-1">
              <v-btn
                depressed
                text
                height="40px"
                class="text-capitalize fill-width rounded-0 justify-start"
                color="#ED6347"
                @click="hangup()"
              >
                <span class="fz-14">Hang Up</span>
              </v-btn>
              <v-divider class="mt-0 mb-2"></v-divider>
              <v-btn
                depressed
                text
                height="40px"
                :disabled="!callItem.uuidActiveCall"
                class="text-capitalize fill-width rounded-0 justify-start v-btn--violet-hover"
                @click="markAsSpam"
              >
                <span class="fz-14">{{ callItem.is_spam ? 'Unmark' : 'Mark' }} as spam</span>
              </v-btn>
              <v-btn
                depressed
                text
                height="40px"
                :disabled="!callItem.uuidActiveCall"
                class="text-capitalize fill-width rounded-0 justify-start v-btn--violet-hover"
                @click="markSpamAndHungUp"
              >
                <span class="fz-14">Hang up and {{ callItem.is_spam ? 'Unblock' : 'Block' }}</span>
              </v-btn>
            </v-list-item-content>
          </v-card>
        </v-menu>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import SendAsMessage from './SendAsMessage'
import { OUTGOING_REQUEST_NAME } from '../utilities/sessionManager'
import moment from 'moment'

export default {
  name: 'IncomingCallsItem',
  data () {
    return {
      sendMessageDialog: false,
      isActiveAudio: false
    }
  },
  components: { SendAsMessage },
  props: {
    callItem: {
      type: Object,
      default: null
    }
  },
  computed: {
    callIsActive () {
      return this.$props.callItem && this.$props.callItem.status === 'answered'
    },
    timeDisplay () {
      if (this.$props.callItem) {
        const d = moment.utc(this.$props.callItem.time * 1000)
        return d.format('mm:ss')
      }
      return ''
    },
    callIsOutbound () {
      return this.$props.callItem.type === OUTGOING_REQUEST_NAME
    },
    activeAnswerButton () {
      if (this.$store.state.components.calls.length <= 1) {
        return true
      }
      return this.$store.state.components.calls.every(call => {
        if (call.status !== 'answered') return true
        if (+call.phoneNumber === +this.$props.callItem?.phoneNumber) return true
        if (call.type === OUTGOING_REQUEST_NAME) {
          return call.hold
        }
        return call.mute
      })
    }
  },
  methods: {
    fullName (firstname, lastname) {
      return firstname + ' ' + lastname
    },
    toHourMinSec (value) {
      const hh = Math.floor(value / 60 / 60)
      const mm = Math.floor(value / 60) - hh * 3600
      const ss = value % 60
      return `${hh ? hh + ':' : '0'}${mm ? mm + ':' : '0:'}${ss < 10 ? '0' + ss : ss}`
    },
    answer () {
      this.$emit('answerCall')
    },
    hold () {
      this.$emit('holdCall')
    },
    hangup () {
      this.$emit('hangupCall')
    },
    add (item) {
      this.$emit('addCall', item)
    },
    send () {
      // if (!item.active) return
      // this.sendMessageDialog = !this.sendMessageDialog
      this.$emit('sendCall')
    },
    mute () {
      this.$emit('muteCall')
    },
    markAsSpam () {
      this.$emit('markAsSpam')
    },
    markSpamAndHungUp () {
      this.$emit('markSpamAndHungUp')
    },
    record () {
      if (this.$props.callItem.uuidActiveCall) {
        // this.isActiveAudio = !this.isActiveAudio
        this.$emit('recordCall')
      }
    }
  }
}
</script>
