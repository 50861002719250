const state = {
  myMailboxesComponent: [
    {
      icon: 'palette',
      title: 'Design mailbox',
      unreadMessage: '1'
    },
    {
      icon: 'voicemail',
      title: 'General mailbox',
      unreadMessage: ''
    },
    {
      icon: 'stop',
      title: 'Marketing mailbox',
      unreadMessage: ''
    },
    {
      icon: 'developer_mode',
      title: 'Development mailbox',
      unreadMessage: ''
    },
    {
      icon: 'stop',
      title: 'CEO mailbox',
      unreadMessage: '2'
    },
    {
      icon: 'question_answer',
      title: 'Support mailbox',
      unreadMessage: '2'
    }
  ]
}

const getters = {}

const mutations = {}

const actions = {}

const modules = {}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
