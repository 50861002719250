import axios from 'src/_services/axios-configs'
import { create, convert } from 'xmlbuilder2'

const stepEnum = {
  CallflowGreeting: 0,
  CallflowDial: 1,
  CallflowSimulcall: 2,
  CallflowMenu: 3,
  CallflowSchedule: 4,
  CallflowGeoRouting: 5,
  CallflowTag: 6,
  CallflowVoicemail: 7,
  CallflowHangUp: 8
}
const CallflowNames = [
  'CallflowGreeting',
  'CallflowDial',
  'CallflowSimulcall',
  'CallflowMenu',
  'CallflowSchedule',
  'CallflowGeoRouting',
  'CallflowTag',
  'CallflowVoicemail',
  'CallflowHangUp'
]
const scheduleDow = [
  'mon,tue,wed,thu,fri',
  'mon',
  'tue',
  'wed',
  'thu',
  'fri',
  'sun',
  'sat',
  'sun,sat'
]

const tagUrl = 'https://api.fonio.app/opentact/tag/'
var steps = []
let stepIndex = 0

const setPayload = (type, item, child, parent, mailbox) => {
  // console.log(item, '_____')
  let subItem
  const payload = { type }
  const actions = {
    branches: [],
    georoutingAreaCode: [],
    georoutingZip: [],
    other: { name: '', type: -1 }
  }
  const children = []
  let activeTab = 0
  stepIndex++
  switch (type) {
    case 0:
    case 3:
    case 7:
      if (item.Say) payload.text = item.Say
      else payload.record = item.Play// ['@url']
      break
  }
  switch (type) {
    case 1:
      subItem = item.Dial
      payload.dialNumber = subItem.To
      payload.timeout = parseInt(subItem['@timeout'])
      break
    case 2:
      subItem = item.Dial
      payload.simulCallAll = !subItem.length
      if (payload.simulCallAll) {
        if (Array.isArray(subItem.To)) payload.simulCallDestinations = subItem.To.map(number => ({ number }))
        else payload.simulCallDestinations = [{ number: subItem.To }]
        payload.timeout = parseInt(subItem['@timeout'])
      } else {
        payload.simulCallDestinations = subItem.map(s => ({ number: s.To }))
        payload.timeout = parseInt(subItem[0]['@timeout'])
      }
      break
    case 3:
      if (!child.Gather.DTMF) break
      payload.menuDtmf = []
      if (child.Gather.DTMF.length === undefined) {
        const id = Date.now() + Math.floor(Math.random() * 1000000)
        const { type } = JSON.parse(child.Gather.DTMF['!'])
        const data = { name: child.Gather.DTMF['@value'], type, id }
        payload.menuDtmf.push(data)
        actions.branches.push(setChildren(child.Gather.DTMF, id, parent, 'menu', child.Gather.DTMF['@value']))
        children.push(id)
      } else {
        child.Gather.DTMF.forEach(dtmf => {
          const id = Date.now() + Math.floor(Math.random() * 1000000)
          const { type } = JSON.parse(dtmf['!'])
          const data = { name: dtmf['@value'], type, id }
          payload.menuDtmf.push(data)
          actions.branches.push(setChildren(dtmf, id, parent, 'menu', dtmf['@value']))
          children.push(id)
        })
      }
      break
    case 4:
      // console.log(item, 'ssssssssss')
      payload.schedule = []
      if (item.Schedule.length === undefined) {
        const id = Date.now() + Math.floor(Math.random() * 1000000)
        children.push(id)
        if ((item.Schedule.Branch.length === undefined && item.Schedule.Branch['!']) || item.Schedule.Branch[0]['!']) {
          actions.branches.push(setChildren(item.Schedule.Branch.length === undefined ? item.Schedule.Branch : item.Schedule.Branch[0], id, parent, 'schedule', 'Branch'))
        }
        const branches = []
        if (item.Schedule.Branch.length === undefined) {
          branches.push(getScheduleBranch(item.Schedule.Branch))
        } else {
          item.Schedule.Branch.forEach(branch => {
            branches.push(getScheduleBranch(branch))
          })
        }
        payload.schedule.push(branches)
      } else {
        item.Schedule.forEach(schedule => {
          const id = Date.now() + Math.floor(Math.random() * 1000000)
          children.push(id)
          if ((schedule.Branch.length === undefined && schedule.Branch['!']) || schedule.Branch[0]['!']) {
            actions.branches.push(setChildren(schedule.Branch.length === undefined ? schedule.Branch : schedule.Branch[0], id, parent, 'schedule', 'Branch'))
          }
          const branches = []
          if (schedule.Branch.length === undefined) {
            branches.push(getScheduleBranch(schedule.Branch))
          } else {
            schedule.Branch.forEach(branch => {
              branches.push(getScheduleBranch(branch))
            })
          }
          payload.schedule.push(branches)
        })
      }
      if (child) {
        const id = Date.now() + Math.floor(Math.random() * 1000000)
        children.push(id)
        if (child.Default && child.Default['!']) actions.other = setChildren(child.Default, id, parent, 'schedule')
      }
      break
    case 5:
      // console.log(item, 'ssssssssss')
      if (!item.Geo || !item.Geo.Area) break
      payload.georoutingAreaCode = []
      payload.georoutingZip = []
      if (item.Geo.Area.length === undefined) {
        const id = Date.now() + Math.floor(Math.random() * 1000000)
        children.push(id)
        // console.log(item.Geo.Area['@npa'].split(','), 'sssssssssssssssssssss')
        const tab = item.Geo.Area['@npa'] ? 'georoutingAreaCode'
          : item.Geo.Area['@zip_codes'] ? 'georoutingZip' : ''
        if (!tab) break
        const branch = item.Geo.Area.length === undefined ? item.Geo.Area : item.Geo.Area[0]
        activeTab = tab === 'georoutingZip' ? 1 : 0
        const payloadTab = tab === 'georoutingAreaCode' ? item.Geo.Area['@npa'] : item.Geo.Area['@zip_codes']

        if (branch['!']) actions[tab].push(setChildren(branch, id, parent, 'geoRouting', tab, activeTab))
        payload[tab].push(payloadTab.split(',').map(item => parseInt(item)))
      } else {
        item.Geo.Area.forEach(area => {
          const id = Date.now() + Math.floor(Math.random() * 1000000)
          children.push(id)
          const tab = area['@npa'] ? 'georoutingAreaCode'
            : area['@zip_codes'] ? 'georoutingZip' : ''
          if (tab) {
            const branch = area.length === undefined ? area : area[0]
            activeTab = tab === 'georoutingZip' ? 1 : 0
            const payloadTab = tab === 'georoutingAreaCode' ? area['@npa'] : area['@zip_codes']

            if (branch['!']) actions[tab].push(setChildren(branch, id, parent, 'geoRouting', tab, activeTab))
            payload[tab].push(payloadTab.split(',').map(item => parseInt(item)))
          }
        })
      }
      if (item.Geo.Default) {
        const id = Date.now() + Math.floor(Math.random() * 1000000)
        children.push(id)
        if (item.Geo.Default['!']) actions.other = setChildren(item.Geo.Default, id, parent, 'geoRouting', 'other')
      }
      break
    case 6:
      subItem = item.curl
      payload.tags = subItem.map(s => ({ tagName: s.replace(tagUrl, '') }))
      break
    case 7:
      payload.selectedMailbox = mailbox
      break
  }
  return { payload, actions, children, activeTab }
}

const setChildren = (branch, id, parent, message, name = '') => {
  const { type } = JSON.parse(branch['!'])
  const action = { name, type, id }

  const childPayload = setPayload(type, branch, branch)
  steps.push({
    id,
    type,
    actions: childPayload.actions,
    name: CallflowNames[type],
    activeTab: true,
    isVisible: true,
    message: { type: message, branch: name },
    parent,
    payload: childPayload.payload,
    children: []
  })
  return action
}

const getScheduleBranch = (branch) => {
  const dayOfWeek = scheduleDow.findIndex(e => e === branch['@dow'])
  const time = branch['@time']
  const branchItem = {
    dayOfWeek,
    rangeType: time ? 1 : 0
  }
  if (time) {
    const [start, end] = time.split('-')
    const startTime = start.split(':')[0]
    const endTime = end.split(':')[0]
    branchItem.startTime = startTime
    branchItem.endTime = endTime
  }
  return branchItem
}

const getStepsFromXml = (xml) => {
  let enableRecording = false
  try {
    steps = []
    stepIndex = 0
    const obj = convert(xml, { format: 'object' })
    let keys = Object.keys(obj.Response)
    let response = keys.map(key => ({
      [key]: obj.Response[key]
    }))
    console.log(response, '______1', keys)
    if (keys[0] === 'Record') {
      response = response[0].Record
      keys = Object.keys(response)
      enableRecording = true
    } else if (response.length === 1) {
      response = response[0]
    }
    console.log(response, '______2', keys, response.length)
    if (keys[0] === '#') {
      response = Object.values(response[keys[0]])
    } else if (response.length === undefined) {
      response = keys.map(key => ({
        [key]: response[key]
      }))
    }
    console.log(response, '______3')
    let mailbox = ''
    const recordIndex = response.findIndex(e => Object.keys(e).includes('Record'))
    if (recordIndex >= 0) {
      enableRecording = true
      const tag = response[recordIndex].Record['@tag']
      const [t, m] = tag.split('_')
      if (m) mailbox = m
    }
    let index = 0
    while (index < response.length) {
      const key = Object.keys(response[index])[0]
      const value = Object.values(response[index])[0]
      const sIndex = stepIndex
      if (key[0] === '!') {
        const { type } = JSON.parse(value)
        const id = Date.now() + Math.floor(Math.random() * 10000)
        steps.push({
          id,
          type,
          isVisible: true,
          actions: {},
          name: CallflowNames[type],
          parent: undefined,
          payload: {},
          children: []
        })

        const { payload, actions, children, activeTab } = setPayload(
          type,
          response[index + 1],
          type >= 3 || type <= 5 || type === 7 ? response[index + 2] : null,
          id,
          mailbox
        )
        steps[sIndex].actions = actions
        steps[sIndex].children = children
        steps[sIndex].payload = payload
        steps[sIndex].activeTab = activeTab
      }
      index++
    }

    // console.log(steps)
    return enableRecording
  } catch (err) {
    console.log(err)
    return enableRecording
  }
}
const getPayload = (name, payload = {}) => {
  const result = {
    type: stepEnum[name]
  }
  switch (name) {
    case 'CallflowGreeting':
    case 'CallflowMenu':
    case 'CallflowVoicemail':
      result.text = payload.text || ''
      result.record = payload.record || ''
      break
    case 'CallflowDial':
    case 'CallflowSimulcall':
      result.timeout = payload.timeout || 20000
      // result.amd = payload.amd === undefined || payload.amd
      break
  }

  switch (name) {
    case 'CallflowDial':
      result.dialNumber = payload.dialNumber || ''
      break
    case 'CallflowSimulcall':
      result.simulCallAll = payload.simulCallAll === undefined || payload.simulCallAll
      result.simulCallDestinations = payload.simulCallDestinations || []
      // result.simulCallSticky = payload.simulCallSticky !== undefined && payload.simulCallSticky
      break
    case 'CallflowMenu':
      result.menuDtmf = payload.menuDtmf || []
      break
    case 'CallflowSchedule':
      result.schedule = payload.schedule || []
      break
    case 'CallflowGeoRouting':
      result.georoutingAreaCode = payload.georoutingAreaCode || []
      result.georoutingZip = payload.georoutingZip || []
      break
    case 'CallflowTag':
      result.tags = payload.tags || []
      break
    case 'CallflowVoicemail':
      result.selectedMailbox = payload.selectedMailbox || ''
      break
  }

  return result
}
const getXml = () => {
  let root = create({ version: '1.0', encoding: 'UTF-8' }).ele('Response')
  root.ele('Answer')
  const voicemail = state.steps.some(e => e.name === 'CallflowVoicemail')
  /* if (voicemail) {
    tags = { tag: `voicemail_${voicemail.payload.selectedMailbox}`, asr: true }
  } */
  if (state.data.enableRecording && !voicemail) {
    const recordElement = root.ele('Record', { tag: 'global' })
    recordElement.ele('Tone')
    recordElement.ele('Pause', { length: '10000' })
  }
  for (const step of state.steps) {
    if (step.parent) continue
    root = getXmlFromStep(root, step.payload, step)
  }
  // if (!state.steps.some(item => item.name === 'CallflowHangUp')) root.com(JSON.stringify({ type: 8 })).ele('Hangup')
  return root.end({ prettyPrint: true })
}

const getXmlFromStep = (root, step, stepFromState) => {
  if (!step) return root
  root.com(JSON.stringify({ type: step.type }))
  switch (step.type) {
    case 0:
    case 3:
    case 7:
      if (stepFromState ? stepFromState.activeTab : true) root.ele('Say').txt(step.text)
      else root.ele('Play').txt(step.record)
      // else root.ele('Play', { url: step.record })
      break
  }
  let subStep
  let subSubStep
  let child
  let childXml
  switch (step.type) {
    case 1:
      root
        .ele('Dial', { timeout: step.timeout })
        .ele('To').txt(step.dialNumber)
      break
    case 2:
      if (step.simulCallAll) {
        const dial = root.ele('Dial', { timeout: step.timeout })
        step.simulCallDestinations.forEach(destination => {
          dial.ele('To').txt(destination.number)
        })
      } else {
        step.simulCallDestinations.forEach(destination => {
          root
            .ele('Dial', { timeout: step.timeout })
            .ele('To').txt(destination.number)
        })
      }
      break
    case 3:
      if (!stepFromState) break
      subStep = root.ele('Gather')
      stepFromState.actions.branches.forEach(action => {
        childXml = subStep.ele('DTMF', { value: action.name })
        child = state.steps.find(e => e.id === action.id)
        getXmlFromStep(childXml, child ? child.payload : null, child)
      })
      break
    case 4:
      if (!stepFromState) break
      step.schedule.forEach((schedule, index) => {
        subStep = root.ele('Schedule', { tag: String.fromCharCode('A'.charCodeAt(0) + index) })
        schedule.forEach(sch => {
          subSubStep = subStep.ele('Branch', { dow: scheduleDow[sch.dayOfWeek] })
          if (sch.rangeType === 1) {
            subSubStep.att('time', `${sch.startTime ? sch.startTime : '00'}:00:00-${sch.endTime ? `${sch.endTime}:00:00` : '23:59:59'}`)
          } else if (sch.rangeType === 2) {
            subSubStep.att('time', `${sch.fromTime ? sch.fromTime : '00'}:00:00-23:59:59`)
          }
          child = state.steps.find(e => e.id === stepFromState.actions.branches[index].id)
          if (child) getXmlFromStep(subSubStep, child.payload, child)
        })
      })
      child = state.steps.find(e => e.id === stepFromState.actions.other.id)
      if (!child) break
      subStep = root.ele('Default')
      getXmlFromStep(subStep, child.payload, child)
      break
    case 5:
      if (!stepFromState) break
      subStep = root.ele('Geo')
      if (!stepFromState.activeTab && step.georoutingAreaCode.length) {
        for (const index in step.georoutingAreaCode) {
          subSubStep = subStep.ele('Area', { npa: step.georoutingAreaCode[index].toString() })
          child = state.steps.find(e => e.id === stepFromState.actions.georoutingAreaCode[index].id)
          if (!child) continue
          getXmlFromStep(subSubStep, child.payload, child)
        }
      }

      if (stepFromState.activeTab && step.georoutingZip.length) {
        for (const index in step.georoutingZip) {
          subSubStep = subStep.ele('Area', { zip_codes: step.georoutingZip[index].toString() })
          child = state.steps.find(e => e.id === stepFromState.actions.georoutingZip[index].id)
          if (!child) continue
          getXmlFromStep(subSubStep, child.payload, child)
        }
      }

      child = state.steps.find(e => e.id === stepFromState.actions.other.id)
      if (!child) break
      subSubStep = subStep.ele('Default')
      getXmlFromStep(subSubStep, child.payload, child)
      break
    case 6:
      step.tags.forEach(tag => {
        root.ele('curl').txt(`${tagUrl}${tag.tagName}`)
      })
      break
    case 7:
      subStep = root.ele('Record', { tag: `voicemail_${step.selectedMailbox}`, asr: true })
      subStep.ele('Tone')
      subStep.ele('Pause', { length: '10000' })
      break
    case 8:
      root.ele('Hangup')
      break
  }
  return root
}

const state = {
  isCreatingCallFlow: false,
  errors: {
    sameZip: false,
    unknownZip: false,
    sameArea: false,
    scheduleOverlap: {}
  },
  data: {
    name: '',
    didUuid: '',
    enableRecording: false
  },
  steps: [],
  lineCoords: null
}

const getters = {
  getFromPath (state) {
    return (path) => {
      let target = state.steps

      for (const key of path) {
        target = target[key]
      }

      return target
    }
  },
  getFromData (state) {
    return (path) => {
      return state.data[path]
    }
  },
  steps: s => s.steps,
  isCreatingCallFlow: s => s.isCreatingCallFlow,
  errors: s => s.errors,
  hasError: s => s.errors.sameZip || s.errors.sameArea || s.errors.unknownZip ||
    Object.values(s.errors.scheduleOverlap).some(value => value) ||
    s.steps.some(e => e.name === 'CallflowDial' && !e.payload.dialNumber) ||
    s.steps.some(e => e.name === 'CallflowSimulcall' &&
      (!e.payload.simulCallDestinations.length || e.payload.simulCallDestinations.some(dest => !dest.number) ||
      e.payload.simulCallDestinations.some((item, idx) =>
        item.number && e.payload.simulCallDestinations.findIndex(e => e.number && e.number === item.number) !== idx))
    ) ||
    s.steps.some(e => e.name === 'CallflowVoicemail' && !e.payload.selectedMailbox) ||
    s.steps.some(e => (e.name === 'CallflowGreeting' || e.name === 'CallflowMenu' || e.name === 'CallflowVoicemail') &&
    ((e.activeTab && (!e.payload.text || !e.payload.text.trim())) || (!e.activeTab && !e.payload.record))),
  hasHangUp: s => s.steps.some(item => item.name === 'CallflowHangUp'),
  hasVoicemail: s => s.steps.some(item => item.name === 'CallflowVoicemail'),
  lineCoords: s => s.lineCoords
}

const mutations = {
  setData (state, data) {
    state.data = data
  },
  setDataStep (state, { step, data }) {
    state.data[step] = data
    console.log(state.data, 'setDataStep')
  },
  resetCallflow (state) {
    state.isCreatingCallFlow = false
    state.data.name = ''
    state.data.enableRecording = false
    state.steps = []
    state.errors.sameZip = false
    state.errors.scheduleOverlap = {}
    steps = []
    stepIndex = 0
  },
  setSteps (state, steps) {
    state.steps = steps
  },
  _addStep (state, { path, value, isReplacement }) {
    let target = state.steps

    for (const key of path.slice(0, path.length - 1)) {
      target = target[key]
    }

    target.splice(path[path.length - 1], isReplacement ? 1 : 0, value)
    console.log(state.steps, path, '_addStep')
  },
  pushToStep (state, { path, value }) {
    let target = state.steps

    for (const key of path) {
      target = target[key]
    }

    console.log(state.steps, 'pushToStep')
    return target.push(value)
  },
  setStepValue (state, { path, value }) {
    let target = state.steps

    for (const key of path.slice(0, path.length - 1)) {
      target = target[key]
    }

    target[path[path.length - 1]] = value
    console.log(state.steps, 'setStepValue', value)
  },
  removeFromStep (state, { path }) {
    console.log(state.steps, 'removeFromStep', path)
    let target = state.steps
    // const parent = target[path[0]]

    for (const key of path.slice(0, path.length - 1)) {
      target = target[key]
    }
    /* const child = target[path[path.length - 1]]
    //console.log('path: ', path, '\nparent:', parent, '\nchild: ', child)
    if (child.message) {
      if (child.message.branch === 0) {
        parent.action[child.message.type] = false
      } else {
        const aPath = actionPath[child.message.type]
        let aTarget = parent
        for (let key of aPath) {
          aTarget = aTarget[key]
        }
        aTarget[child.message.branch - 1].action[child.message.type] = false
      }
    } */
    target.splice(path[path.length - 1], 1)
  },
  setIsCreatingCallFlow (state, value) {
    state.isCreatingCallFlow = value
  },
  setError (state, { path, error }) {
    state.errors[path] = error
    console.log(state.errors, 'setError')
  },
  setLineCoords (state, lineCoords) {
    state.lineCoords = lineCoords
  }
}

const actions = {
  setCallflowData ({ commit }, payload) {
    commit('setData', payload)
  },
  setCallflowDataStep ({ commit }, { step, data }) {
    commit('setDataStep', { step, data })
  },
  getCallflow ({ commit }, uuid) {
    return new Promise((resolve, reject) => {
      const url = `/callflow/${uuid}`
      commit('resetCallflow')
      axios.get(url)
        .then(res => {
          const data = getStepsFromXml(res.data.payload.callflowXml)
          commit('setSteps', steps)
          commit('setDataStep', { step: 'enableRecording', data })
          resolve(res)
        })
        .catch(err => {
          reject(err.response ? err.response.data : err)
        })
    })
  },
  /* getCallflowSteps ({ commit }, uuid) {
    return new Promise((resolve, reject) => {
      const url = 'callflow/steps'

      axios.get(url, { params: { callflow_uuid: uuid } })
        .then(({ data }) => {
          commit('setSteps', data.response)
          resolve(data)
        })
        .catch(err => {
          reject(err.response.data)
        })
    })
  }, */
  addStep ({ commit }, { name, path, id, parent, isReplacement, message }) {
    if (!name) throw new Error('Step name is required')
    if (!Array.isArray(path)) throw new Error('Path must be an array of numbers')
    const actions = {
      branches: [],
      georoutingAreaCode: [],
      georoutingZip: [],
      other: { name: '', type: -1 }
    }
    commit('_addStep', {
      path,
      value: {
        id: id,
        name,
        parent,
        activeTab: true,
        isVisible: true,
        message,
        payload: getPayload(name),
        children: [],
        actions
      },
      isReplacement
    })
  },
  generateXml ({ state }) {
    const xml = getXml()
    console.log(xml)
    return xml
  },
  async submitCallFlow ({ state, commit }, { isEdit, uuid, changed }) {
    if (state.isCreatingCallFlow || !state.steps.length) return

    if (!state.data.name) {
      return console.log('Validation error')
    }

    return new Promise((resolve, reject) => {
      commit('setIsCreatingCallFlow', true)

      let method
      let url
      const xmlString = getXml()
      console.log(xmlString)
      const now = Date.now()
      var blob = new Blob([xmlString], { type: 'text/xml' })
      var file = new File([blob], `${state.data.name}-${now}.xml`, { type: 'text/xml' })
      const formData = new FormData()

      if (!isEdit || changed) formData.append('name', state.data.name)
      formData.append('callflow_xml', file, `${state.data.name}-${now}.xml`)
      // formData.append('callflow_xml', Buffer.from(xmlString, 'utf-8'), 'filename.xml')
      if (isEdit) {
        method = 'patch'
        url = `/callflow/${uuid}`
        // if (state.data.didNumbers.length) body.didNumbers = state.data.didNumbers
      } else {
        method = 'post'
        url = '/callflow'
        // if (state.data.didUuid) body.didUuid = state.data.didUuid
      }
      const config = {
        onUploadProgress: (progressEvent) => {
          console.log(progressEvent.loaded, progressEvent.total, progressEvent, 'progressEvent')
        }
      }
      axios[method](url, formData, config)
        .then(res => {
          if (!res.data.payload.uuid) {
            reject(new Error('Uuid is undefined'))
          }
          resolve(res)
        })
        .catch(err => {
          reject(err.response.data || err.response)
        }).finally(() => {
          commit('setIsCreatingCallFlow', false)
        })
    })
  },
  submitDuplicateCallflow (_, { name, xml }) {
    return new Promise((resolve, reject) => {
      const now = Date.now()
      var blob = new Blob([xml], { type: 'text/xml' })
      var file = new File([blob], `${name}-${now}.xml`, { type: 'text/xml' })
      const formData = new FormData()
      formData.append('name', name)
      formData.append('callflow_xml', file, `${name}-${now}.xml`)
      axios.post('/callflow', formData)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err.response.data || err.response)
        })
    })
  },
  async assignDidToCallflow (_, { didUuid, callflowUuid }) {
    return new Promise((resolve, reject) => {
      const url = `/did/${didUuid}/callflow/${callflowUuid}`
      axios.put(url)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err.response.data || err.response)
        })
    })
  },
  calculateLines ({ commit }) {
    const startElements = document.querySelectorAll('.toggle-action-wrapper')
    const endElements = document.querySelectorAll('.branch-step-name')
    const startCoords = {}
    const relativeCoords = {}
    for (const element of startElements) {
      const rect = element.getBoundingClientRect()
      const id = element.id.replace('start_', '')
      startCoords[id] = { x: rect.left, y: rect.top }
    }
    for (const element of endElements) {
      const [pos, parent, type, child] = element.id.split('_')
      if (!type) continue
      // console.log(pos, parent, type, child, 'endElements')
      const rect = element.getBoundingClientRect()
      const id = element.id.replace('end_', '')
      relativeCoords[id] = { x: startCoords[id].x - rect.left - element.offsetWidth, y: rect.top - startCoords[id].y + element.offsetHeight / 2 }
    }
    commit('setLineCoords', relativeCoords)
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
