<template>
  <transition>
    <div class="mt-md-15 pt-md-11 popup-wrapper">
      <v-card
        class="fz-14 mb-5 overflow-hidden max-w-380"
        style="box-shadow: 0 0 0 1px rgba(6,44,82,0.1), 0 1px 3px 0 rgba(64,66,69,0.12), 0 2px 16px 0 rgba(33,43,54,0.08);"
        tile
      >
        <!-- <v-col
          cols="12"
          md="12"
          class="pa-0"
        >
          <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2689.289173278231!2d-122.3514660841788!3d47.620509895009945!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x5490151f4ed5b7f9%3A0xdb2ba8689ed0920d!2z0KHQv9C10LnRgS3QndC40LTQuw!5e0!3m2!1sru!2s!4v1653565652330!5m2!1sen!2s" width="400" height="300" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
        </v-col> -->

        <v-col
          cols="12"
          md="12"
          class="pa-4 pb-0 d-flex align-center"
        >
          <div class="text-uppercase fz-12 font-weight-semi-bold dark-text">Contacts</div>
          <v-btn
            icon
            class="ml-auto d-md-none"
            @click="close"
          >
            <v-icon size="24px">close</v-icon>
          </v-btn>
        </v-col>

        <v-col
          cols="12"
          md="12"
          class="px-4 pt-2 pb-0 d-flex justify-space-between align-center"
        >
          <span class="fz-14 mr-2" style="color:#1A1F24;letter-spacing: -0.15px">{{ phone }}</span>
          <v-tooltip top content-class="v-tooltip--top">
            <template v-slot:activator="{ on }">
              <v-btn
                v-if="callEnabled"
                v-on="on"
                :style="{ opacity: isFreeAccount ? '0.5' : 1 }"
                class="grey-button px-3"
                min-width="44px"
                @click="checkNumber('call')"
              >
                <v-icon size="20px" color="#919EAB">phone</v-icon>
                <span class="d-md-none d-lg-block ml-2">Call</span>
              </v-btn>
            </template>
            <span>Call</span>
          </v-tooltip>
        </v-col>

        <v-col
          cols="12"
          md="12"
          class="px-4 pt-3 pb-0 d-flex justify-space-between align-center"
        >
          <span class="fz-14 mr-2" style="color:#1A1F24;letter-spacing: -0.15px; word-break: break-word;">{{ email }}</span>
          <v-tooltip top content-class="v-tooltip--top">
            <template v-slot:activator="{ on }">
              <v-btn
                v-if="smsEnabled && currentCompany.sms_enabled"
                v-on="on"
                :style="{ opacity: isFreeAccount ? '0.5' : 1 }"
                class="grey-button px-3"
                min-width="44px"
                @click="checkNumber('message')"
              >
                <v-icon size="20px" color="#919EAB">message</v-icon>
                <span class="d-md-none d-lg-block ml-2">Message</span>
              </v-btn>
            </template>
            <span>Message</span>
          </v-tooltip>
        </v-col>

        <v-col
          cols="12"
          md="12"
          class="px-4 pt-3 pb-4"
        >
          <span class="fz-14" style="color:#1A1F24;letter-spacing: -0.15px">{{ address }}</span>
        </v-col>

        <v-col
          cols="12"
          md="12"
          class="pa-0"
        >
          <v-divider></v-divider>
        </v-col>

        <v-row class="my-0 mr-0">
          <v-card
            elevation="0"
            class="transparent overflow-y-auto w-100"
          >
            <v-col
              cols="12"
              md="12"
              class="pl-2 pr-5 py-0"
            >
              <v-timeline
                dense
                clipped
                align-top
                class="grey-timeline pa-0"
              >
                <template v-for="(event, eventIndex) in log">
                  <div
                    :key="eventIndex"
                    class="fz-12 font-weight-bold text-uppercase ml-10 mt-7 mb-2"
                    style="color: #637381;"
                  >
                    {{ convertDate(eventIndex) }}
                  </div>

                  <v-timeline-item
                    v-for="(item, itemIndex) in event"
                    :key="eventIndex + itemIndex"
                    color="#C4CDD5"
                    small
                  >
                    <v-row class="justify-space-between my-0">
                      <v-col
                        cols="6"
                        md="9"
                        sm="8"
                        class="fz-14 pl-1"
                      >
                        <span class="blue-elemental">{{ getFullName(item.caller_first_name, item.caller_last_name) }}</span>
                        <span>{{ item.type === 'voicemail' ? ' send voicemail to ' : ' called to ' }}</span>
                        <span class="blue-elemental">{{ getFullName(item.callee_first_name, item.callee_last_name) }}</span>
                      </v-col>
                      <v-col
                        class="text-right fz-14"
                        style="color: #637381;"
                        cols="6"
                        md="3"
                        sm="4"
                      >{{convertDate(eventIndex)}}</v-col>
                    </v-row>
                  </v-timeline-item>
                </template>
              </v-timeline>
            </v-col>
          </v-card>
          <!-- <v-btn v-if="log.length > logsShowing" text link class="text-none fill-width " color="link" height="50px" @click="logExpand = !logExpand">
            Show all
            <v-icon color="regular-text" :class="{ 'button__icon--active': logExpand }">expand_more</v-icon>
          </v-btn> -->
        </v-row>
      </v-card>

    </div>
  </transition>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex'
export default {
  name: 'PopUpContactsDetails',
  data: () => ({
    phone: '',
    email: '',
    address: '',
    logExpand: false,
    logsShowing: 4,
    log: [
      /* {
        note: 'Call to',
        name: 'Kevin Owens1',
        time: '10:41pm',
        date: '2021-06-06T11:23:50.245Z'
      },
      {
        note: 'Call to',
        name: 'Kevin Owens2',
        time: '10:41pm',
        date: '2021-06-06T11:23:50.245Z'
      },
      {
        note: 'Call to',
        name: 'Kevin Owens3',
        time: '10:41pm',
        date: '2021-06-06T11:23:50.245Z'
      },
      {
        note: 'Call to',
        name: 'Kevin Owens4',
        time: '10:41pm',
        date: '2021-06-06T11:23:50.245Z'
      },
      {
        note: 'Call to',
        name: 'Kevin Owens5',
        time: '10:41pm',
        date: '2021-06-06T11:23:50.245Z'
      } */
    ],
    id: null,
    contactUuid: ''
  }),
  created () {
    this.init()
  },
  watch: {
    '$store.state.user.selectedContactId' () {
      this.init()
    }
  },
  computed: {
    ...mapGetters(['currentCompany', 'phoneNumbers']),
    isFreeAccount () { return this.currentCompany?.is_free },
    groupByDate () {
      const logList = {}
      this.log.forEach((item) => {
        const date = item.date.split('T')[0]
        if (logList[date]) {
          logList[date].push(item)
        } else {
          logList[date] = [item]
        }
      })
      return logList
    },
    callEnabled () {
      return this.phoneNumbers.some(e => e.features.includes('voice'))
    },
    smsEnabled () {
      return this.phoneNumbers.some(e => e.features.includes('sms'))
    }
  },
  methods: {
    ...mapMutations('components', ['setSubscribeDialog']),
    ...mapActions(['getContact', 'getCallLogs']),
    init () {
      if (!this.$store.state.user.selectedContactId) return
      this.contactUuid = this.$store.state.user.selectedContactId
      this.loadContact()
      this.loadCallLogs()
    },
    async loadContact () {
      try {
        const { data } = await this.getContact(this.contactUuid)
        this.phone = data.contact.phoneNumber
        this.email = data.contact.email
      } catch (err) {
        throw new Error(err)
      }
    },
    async loadCallLogs () {
      try {
        const params = {
          contact_uuid: this.contactUuid,
          orderType: 'desc',
          orderBy: 'created_on'
        }
        const { data } = await this.getCallLogs(params)
        if (!data) return
        this.log = data.payload
      } catch (err) {
        throw new Error(err)
      }
    },
    convertDate (date) {
      const today = new Date().toISOString().split('T')[0]
      let formatDate
      if (today === date) {
        formatDate = 'Today'
      } else {
        formatDate = new Date(date).toLocaleDateString('en-US', {
          month: 'long',
          day: 'numeric'
        })
      }
      return formatDate
    },
    checkNumber (type) {
      if (this.isFreeAccount) {
        if (this.phoneNumbers.length) {
          this.setSubscribeDialog(true)
        } else {
          this.$snotify.error(`Please add number to ${type === 'call' ? 'make a call' : 'send a message'}.`)
        }
        return
      }
      this[type]()
    },
    call () {
      const callOption = {
        phone: parseInt(this.phone),
        status: true
      }
      this.$store.dispatch('components/callModalStatus', callOption)
    },
    getFullName (first, last) {
      if (first && last) {
        return first + ' ' + last
      } else if (first && !last) {
        return first
      } else if (!first && last) {
        return last
      }

      return 'Unknown'
    },
    message () {
      this.$router.push({ name: 'Chat', query: { n: +this.phone } })
    },
    close () {
      this.$store.dispatch('components/ContactDetails', false)
    }
  }
}
</script>

<style scoped lang="scss">
@media (max-width: 960px) {
  .popup-wrapper {
    position: fixed;
    inset: 0;
    z-index: 100;
    display: grid;
    place-items: center;
  }
}
</style>
