<template>
  <v-dialog
    v-model="dialog"
    content-class="drag-dialog mt-15"
    persistent
    max-width="724px"

  >
<!--    <v-card max-width="724px" color="transparent">-->
    <v-card
      class="pa-0 relative relative"
    >
      <div
        class="d-flex justify-center absolute drag-sheets ml-auto mr-auto fill-width"
        v-if="dragMode"
      >
        <v-sheet
          @dragover.prevent
          @drop="sendToQueue(dragging, $event)"
          class="mr-5 d-flex flex-column align-center fz-12 white--text text-center"
          color="transparent"
          max-width="90px"
        >
          <v-avatar
            color="#FFF"
            width="60px"
            height="60px"
            class="mb-1"
          >
            <v-icon size="40px" color="#919EAB">people</v-icon>
          </v-avatar>

          Send to queue
        </v-sheet>

        <v-sheet
          @dragover.prevent
          @drop="sendToPerson(dragging, $event)"
          class="mr-5 d-flex flex-column align-center fz-12 white--text text-center"
          color="transparent"
          max-width="90px"
        >
          <v-avatar
            color="#FFF"
            width="60px"
            height="60px"
            class="mb-1"
          >
            <v-icon size="40px" color="#919EAB">person</v-icon>
          </v-avatar>

          Send to person
        </v-sheet>

        <v-sheet
          @dragover.prevent
          @drop="sendToVoicemail(dragging, $event)"
          class="mr-5 d-flex flex-column align-center fz-12 white--text text-center"
          color="transparent"
          max-width="90px"
        >
          <v-avatar
            color="#FFF"
            width="60px"
            height="60px"
            class="mb-1"
          >
            <v-icon size="40px" color="#919EAB">voicemail</v-icon>
          </v-avatar>

          Send to voicemail
        </v-sheet>

        <v-sheet
          @dragover.prevent
          @drop="playMessage(dragging, $event)"
          class="d-flex flex-column align-center fz-12 white--text text-center"
          color="transparent"
          max-width="90px"
        >
          <v-avatar
            color="#FFF"
            width="60px"
            height="60px"
            class="mb-1"
          >
            <v-icon size="40px" color="#919EAB">play_arrow</v-icon>
          </v-avatar>

          Play message
        </v-sheet>
      </div>
      <v-card-title class="py-6 pr-2 d-flex justify-space-between align-center">
        <h2 class="font-weight-bold">{{ title }}</h2>

        <v-btn
          icon
          @click="close"
        >
          <v-icon size="24px" color="">close</v-icon>
        </v-btn>
      </v-card-title>

      <v-divider/>

      <v-list class="pa-0">
        <template v-for="(mailbox, mailboxIndex) in mailboxesArray">
          <v-divider
            v-if="mailboxIndex"
            :key="mailboxIndex + 'divider'"
          ></v-divider>

          <v-list-item
            class="pl-1 v-list-item--draggable"
            :key="mailboxIndex + 'list-item'"
            draggable="true"
            @dragstart="dragStart(mailboxIndex, $event)"
            @dragover.prevent
            @dragenter="dragEnter"
            @dragleave="dragLeave"
            @dragend="dragEnd(mailboxIndex)"
            @drag="drag(mailboxIndex)"
          >
            <v-icon size="20px" class="mr-1 pointer">drag_indicator</v-icon>

            <v-list-item-avatar
              width="40px"
              height="40px"
              class="mr-4 my-3"
            >
              <v-img
                :alt="`${mailbox.firstname} avatar`"
                :src="mailbox.avatar + '?random=' + mailboxIndex"
              >
                <v-icon color="#fff">phone</v-icon>
              </v-img>
            </v-list-item-avatar>

            <v-list-item-content class="align-center">
              <v-list-item-title class="fz-14 font-weight-medium dark-text">
                {{
                  !mailbox.lastname && !mailbox.lastname ? mailbox.phone : fullName(mailbox.firstname, mailbox.lastname)
                }}
              </v-list-item-title>
              <v-list-item-subtitle
                v-if="mailbox.lastname && mailbox.lastname"
                class="fz-14 grey--text text--darken-1"
              >
                {{ mailbox.phone }}
              </v-list-item-subtitle>
            </v-list-item-content>

            <v-list-item-action-text
              class="flex-row align-center v-list-item__action--normal fz-14 justify-end grey--text text--darken-1"
            >
              {{ mailbox.startQueueTime }} started queue · {{ mailbox.waitingTime }} waiting
            </v-list-item-action-text>

            <v-list-item-action class="flex-row align-center v-list-item__action--hover">
              <v-tooltip top content-class="v-tooltip--top">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    icon
                    class="mr-2"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon color="#919EAB">phone</v-icon>
                  </v-btn>

                </template>
                <span>Answer</span>
              </v-tooltip>

              <v-btn icon class="mr-2">
                <v-icon color="#919EAB">person</v-icon>
              </v-btn>
              <v-btn icon class="mr-2">
                <v-icon color="#919EAB">send</v-icon>
              </v-btn>
              <v-btn icon class="mr-2">
                <v-icon color="#919EAB">play_arrow</v-icon>
              </v-btn>
              <v-btn icon>
                <v-icon color="#ED6347">call_end</v-icon>
              </v-btn>
            </v-list-item-action>
          </v-list-item>
        </template>
      </v-list>

      <v-divider/>

      <v-btn text link class="text-none fill-width " color="link" height="50px" @click="mailboxExpand = !mailboxExpand">
        Show all
        <v-icon color="regular-text" :class="{ 'button__icon--active': mailboxExpand }">expand_more</v-icon>
      </v-btn>
    </v-card>
<!--    </v-card>-->
  </v-dialog>
</template>

<script>
export default {
  name: 'MailboxDialog',
  data: () => ({
    dragMode: false,
    title: 'CEO mailbox',
    mailboxExpand: false,
    mailboxes: [
      {
        avatar: 'https://picsum.photos/200',
        firstname: 'Craig',
        lastname: 'Flores',
        phone: '255-295-7777',
        startQueueTime: '11:31pm',
        waitingTime: '05:34'
      },
      {
        avatar: 'https://picsum.photos/200',
        firstname: '',
        lastname: '',
        phone: '255-295-6559',
        startQueueTime: '11:01pm',
        waitingTime: '05:34'
      },
      {
        avatar: 'https://picsum.photos/200',
        firstname: 'Craig',
        lastname: 'Flores',
        phone: '255-295-6559',
        startQueueTime: '11:01pm',
        waitingTime: '05:34'
      },
      {
        avatar: 'https://picsum.photos/200',
        firstname: '',
        lastname: '',
        phone: '255-295-6559',
        startQueueTime: '11:01pm',
        waitingTime: '05:34'
      },
      {
        avatar: 'https://picsum.photos/200',
        firstname: 'Craig',
        lastname: 'Flores',
        phone: '255-295-6559',
        startQueueTime: '11:01pm',
        waitingTime: '05:34'
      },
      {
        avatar: 'https://picsum.photos/200',
        firstname: '',
        lastname: '',
        phone: '255-295-6559',
        startQueueTime: '11:01pm',
        waitingTime: '05:34'
      },
      {
        avatar: 'https://picsum.photos/200',
        firstname: 'Craig',
        lastname: 'Flores',
        phone: '255-295-8888',
        startQueueTime: '11:11pm',
        waitingTime: '05:34'
      },
      {
        avatar: 'https://picsum.photos/200',
        firstname: '',
        lastname: '',
        phone: '255-295-6559',
        startQueueTime: '11:01pm',
        waitingTime: '05:34'
      },
      {
        avatar: 'https://picsum.photos/200',
        firstname: 'Craig',
        lastname: 'Flores',
        phone: '255-295-6559',
        startQueueTime: '11:01pm',
        waitingTime: '05:34'
      },
      {
        avatar: 'https://picsum.photos/200',
        firstname: '',
        lastname: '',
        phone: '255-295-6559',
        startQueueTime: '11:01pm',
        waitingTime: '05:34'
      },
      {
        avatar: 'https://picsum.photos/200',
        firstname: 'Craig',
        lastname: 'Flores',
        phone: '255-295-6559',
        startQueueTime: '11:01pm',
        waitingTime: '05:34'
      }
    ],
    dragging: -1
  }),
  props: {
    dialog: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    mailboxesArray () {
      return this.mailboxExpand ? this.mailboxes : this.mailboxes.slice(0, 5)
    }
  },
  methods: {
    close () {
      this.$emit('update:mailboxDialog', false)
    },
    fullName (firstname, lastname) {
      return firstname + ' ' + lastname
    },
    dragStart (index, event) {
      this.dragMode = true
      this.dragging = index
      document.querySelectorAll('.v-list-item--draggable')[index].classList.add('v-list-item--dragging')
    },
    drag (index) {
      document.querySelectorAll('.v-list-item--draggable')[index].classList.remove('v-list-item--dragging')
    },
    dragEnter (event) {
      // event.style.width = '200px'
    },
    dragLeave (event) {
    },
    dragEnd (index) {
      this.dragMode = false
      this.dragging = -1
    },
    sendToQueue (index, event) {
      console.log('Send to Queue')
      console.log(this.mailboxes[index])
    },
    sendToPerson (index, event) {
      console.log('Send to Person')
      console.log(this.mailboxes[index])
    },
    sendToVoicemail (index, event) {
      console.log('Send to Voicemail')
      console.log(this.mailboxes[index])
    },
    playMessage (index, event) {
      console.log('Play message')
      console.log(this.mailboxes[index])
    }
  }
}
</script>
