<template>
  <v-autocomplete
    v-model="searchUsers"
    :items="computedDropdown"
    item-disabled="disabled"
    :filter="customFilter"
    :label="isMobile ? 'Type here...' : 'Search members'"
    append-icon=""
    class="pa-0 icon-rotate-none px-2"
    :class="{
      'v-autocomplete--search-header': isHeader && !isMobile,
      'my-3 px-4 pb-3 v-autocomplete-mobile': isMobile,
      'mobile-autocomplete': !isDesktop,
      'is-navigating v-select--is-menu-active': isNavigating >= 0
      }"
    ref="vAutocomplete"
    :style="`max-height: ${isMobile ? '55px' : '36px'}`"
    solo
    clear-icon="add_circle"
    clearable
    outlined
    :height="isMobile ? '48px' : ''"
    hide-details
    color="#212B36"
    :menu-props="{
      maxHeight: isHeader ? 'calc(100vh - 50px)' : isMobile ? 'calc(100vh - 150px)' : 257,
      minWidth: isMobile ? '100vw' : '',
      positionY: isMobile ? 150 : 0,
      contentClass: `overscrollBehaviorContain${isMobile ? ' v-autocomplete--search-mobile' : isNavigating ? ' v-autocomplete-navigating' : ''}`
    }"
    @blur="blur"
    @keydown.enter="enterPressed"
    @keyup.down="navigateDown"
    @keyup.up="navigateUp"
    @keydown.tab="navigateNext"
    @keydown.esc="dismiss"
  >
    <template v-slot:prepend-inner>
      <v-btn
        class="pa-0"
        min-width="36px"
        height="36px"
        solo
        text
      >
        <v-icon size="20px" color="#C7C7C7">search</v-icon>
      </v-btn>
    </template>
    <template v-slot:append v-if="!isHeader && !isMobile && isDesktop">
      <v-btn
        depressed
        class="pa-0 mr-n3"
        min-width="36px"
        height="36px"
        style="border-left: 1px solid #c4cdd5"
        @click="createNewUser"
      >
        <v-icon size="20px" color="#919EAB">person_add</v-icon>
      </v-btn>
    </template>
    <template v-slot:append v-else-if="isMobile || !isDesktop">
      <v-btn
        v-if="!searchText"
        class="pa-0 mr-n3"
        min-width="36px"
        height="36px"
        solo
        text
      >
        <v-icon size="20px" color="#C7C7C7">search</v-icon>
      </v-btn>
    </template>

    <template v-slot:item="data">
      <template v-if="data.item.type === 'info-alert'">
        <v-list-item v-show="!isMobile" class="pa-0 mt-n3">
          <v-alert
            width="100%"
            height="38px"
            color="#EBF4FA"
            class="v-alert--dropdown-info d-flex align-center ma-0"
            tile
            elevation="0"
          >
            <div class="fz-14 dark-text">
              Press <strong>enter</strong> to select, <strong>↑ ↓</strong> or <strong>tab</strong> to
              navigate and
              <strong>esc</strong> to dismiss
            </div>
          </v-alert>
        </v-list-item>
      </template>

      <template v-else-if="data.item.type === 'title'">
        <v-list-item
          style="min-height: unset; box-shadow: inset 0 -1px 0 0 #DFE3E8;"
          class="py-2 px-0 mb-1 mx-4 d-flex align-center"
        >
          <div
            v-if="data.item.title === 'members'"
            :class="{ sm: $vuetify.breakpoint.sm }"
            class="d-flex align-center flex-wrap people-wrapper"
          >
            <span
              :class="isMobile ? 'text-capitalize fz-15' : 'text-uppercase fz-12'"
              class="font-weight-bold grey--text text--darken-1 d-flex align-center"
            >{{ data.item.title }}</span>
            <v-chip
              v-for="(user, index) in selectedUsers"
              :key="`${user.id}_${index}`"
              close
              @click:close="remove(user.uuid)"
              class="fz-10"
            >
              {{fullName(user.firstName, user.lastName)}}
            </v-chip>
          </div>
          <span v-else :class="isMobile ? 'text-capitalize' : 'text-uppercase'" class="fz-12 font-weight-bold grey--text text--darken-1">
            {{ data.item.title }}
          </span>
          <v-btn
            v-if="isHeader || isMobile"
            text
            class="text-none font-weight-semi-bold text-decoration-none fz-14 ml-auto"
            style="color: #007ACE;"
            @click.stop.prevent="search"
          >
            All {{ data.item.title }}
          </v-btn>
        </v-list-item>
      </template>

      <template v-else-if="data.item.type !== 'voicemail'">
        <v-list-item
          v-show="!data.item.disabled"
          :class="{ navigating: data.item.uuid === isNavigating }"
          class="v-list-item--violet-hover"
        >
          <v-list-item-avatar
            min-width="36px"
            width="36px"
            height="36px"
            class="my-2"
          >
            <v-img
              :alt="data.item.firstName + ' avatar'"
              :src="data.item.avatar"
            ></v-img>
          </v-list-item-avatar>
          <v-list-item-content
            class="py-2"
            style="cursor:pointer"
            @click.stop.prevent="isSelector ?
              add(data.item) :
              redirect(data.item.uuid)"
          >
            <v-list-item-title>
              {{ fullName(data.item.firstName, data.item.lastName) }}
            </v-list-item-title>

            <v-list-item-subtitle>
              {{ data.item.type === 'members' ? data.item.userPhoneNumber : data.item.text }}
            </v-list-item-subtitle>
          </v-list-item-content>

          <v-list-item-action
            v-if="data.item.type === 'members' && (isHeader || isMobile)"
            :class="isMobile ? '' : 'v-list-item__action--hover'"
            class="my-2 flex-row align-center"
          >
            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <v-btn
                  v-on="on"
                  icon
                  class="mx-1"
                >
                  <v-icon size="24px">call</v-icon>
                </v-btn>
              </template>
              <span>Call</span>
            </v-tooltip>

            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <v-btn
                  v-on="on"
                  icon
                  class="mx-1"
                >
                  <v-icon size="24px">message</v-icon>
                </v-btn>
              </template>
              <span>Message</span>
            </v-tooltip>
          </v-list-item-action>

          <v-list-item-action
            class="my-2 d-flex flex-row align-center"
            v-else-if="data.item.type === 'members' && !isHeader"
          >
            <v-btn
              v-if="$route.name === 'CreateMailbox' || $route.name === 'EditMailbox'"
              icon
              class="mx-1"
              @click.stop.prevent="add(data.item)"
            >
              <v-icon size="24px">add</v-icon>
            </v-btn>
          </v-list-item-action>
        </v-list-item>
      </template>

      <template v-else>
        <v-list-item class="v-list-item--violet-hover">
          <track-item :value="data.item" :isView="'Header'"></track-item>
        </v-list-item>
      </template>
    </template>
  </v-autocomplete>
</template>

<script>
import TrackItem from '@/components/TrackItem'
import { mapActions } from 'vuex'

/* Props are a temporary solution. SearchResults, a temporary static solution, in the future this data will come from the database. */

export default {
  name: 'SearchDropdown',
  components: { TrackItem },
  props: {
    isHeader: {
      type: Boolean,
      default: false
    },
    mailboxUuid: {
      type: String,
      default: ''
    },
    isMobile: {
      type: Boolean,
      default: false
    },
    isSelector: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    searchResults: {
      members: []
    },
    dropdown: [
      {
        type: 'info-alert'
      }
    ],
    searchUsers: [],
    selectedUsers: [],
    disabledUuid: [],
    searchText: '',
    isNavigating: null
  }),
  created () {
    if (this.$store.state.auth.currentUser.role === 'account_admin') {
      if (this.mailboxUuid) this.getMailbox()
      else this.membersList()
    }
  },
  mounted () {
    this.openDropdown()
  },
  computed: {
    computedDropdown () {
      return this.dropdown.map(item => ({
        ...item,
        disabled: this.disabledUuid.includes(item.uuid)
      }))
    },
    isDesktop () {
      return this.$vuetify.breakpoint.mdAndUp
    },
    shownMembers () {
      return this.searchResults.members.filter(e => !this.disabledUuid.includes(e.uuid))
    },
    navigateIndex () {
      return this.shownMembers.findIndex(e => e.uuid === this.isNavigating)
    }
  },
  watch: {
    '$route.path' () {
      this.dismiss()
    }
  },
  methods: {
    ...mapActions(['getMembers', 'getMember']),
    customFilter (item, queryText, itemText) {
      this.searchText = queryText
      const queris = queryText.split(' ')

      if (!item.type || item.type === 'title' || item.type === 'info-alert') return false
      const value = `${item.firstName || ''} ${item.lastName || ''}`
      let found = false
      for (const query of queris) {
        if (!query) continue
        found = value.toLowerCase().includes(query.toLowerCase())
      }
      return found
    },
    getMailbox () {
      this.$store
        .dispatch('getMailbox', this.mailboxUuid)
        .then(({ data }) => {
          this.disabledUuid = data.mailbox.users ? data.mailbox.users : []
          this.membersList()
        }).catch(err => {
          throw new Error(err)
        })
    },
    async membersList () {
      try {
        const { data } = await this.getMembers({
          orderType: 'desc',
          orderBy: 'created',
          take: 999
        })
        const people = []
        let notFound = 0
        const members = Array.from(data.members)
        for (const uuid of this.disabledUuid) {
          try {
            const member = members.find(e => e.user_uuid === uuid)
            if (member) continue

            const memData = await this.getMember(uuid)
            if (!memData.data || !memData.data.member) continue

            members.push(memData.data.member)
          } catch {
            notFound += 1
          }
        }
        if (notFound) {
          this.$snotify.notifications = []
          this.$snotify.error(`${notFound} assigned user(s) not found`)
        }
        members.forEach((item, index) => {
          const user = {
            uuid: item.user_uuid,
            firstName: item.user_first_name,
            lastName: item.user_last_name,
            userPhoneNumber: item.userPhoneNumber,
            avatar: item.image_link || 'https://picsum.photos/' + item.user_id,
            type: 'member',
            index
          }
          people.push(user)
          if (this.disabledUuid.includes(item.user_uuid)) {
            this.selectedUsers.push({
              ...user,
              disabled: true
            })
          }
        })
        this.searchResults.members = people
        this.$emit('updateUsers', this.selectedUsers)
        this.dropdownArray()
      } catch (err) {
        throw new Error(err)
      }
    },
    dropdownArray () {
      const self = this
      Object.entries(this.searchResults).forEach(function (item) {
        item.forEach(function (element) {
          if (typeof element === 'string') {
            self.dropdown.push({
              type: 'title',
              title: element
            })
          } else if (typeof element === 'object') {
            element.forEach(function (user) {
              if (user.file) {
                self.dropdown.push({ ...user, type: 'voicemail' })
              } else if (user.text) {
                self.dropdown.push({ ...user, type: 'history' })
              } else {
                self.dropdown.push(user)
              }
            })
          }
        })
      })
    },
    fullName (firstName, lastName) {
      return firstName + ' ' + lastName
    },
    createNewUser () {
      this.$emit('createUser')
    },
    add (item) {
      this.selectedUsers.push(item)
      this.disabledUuid.push(item.uuid)
      this.$emit('addNewUser', item)
    },
    remove (uuid) {
      this.selectedUsers = this.selectedUsers.filter(e => e.uuid !== uuid)
      this.disabledUuid = this.disabledUuid.filter(e => e !== uuid)
      this.$emit('removeUser', uuid)
    },
    openDropdown () {
      if (this.isMobile) this.$refs.vAutocomplete.onClick(this.$refs.vAutocomplete)
    },
    blur () {
      if (!this.isMobile) return
      const dropdown = document.querySelector('.v-autocomplete--search-mobile')
      this.$refs.vAutocomplete.focus()
      setTimeout(() => {
        const display = window.getComputedStyle(dropdown, null).display
        if (display === 'none') {
          this.$emit('blur')
        }
      }, 100)
    },
    redirect (id) {
      this.dismiss()
      if (this.$route.params.id === id) return
      this.$router.push({ name: 'UserProfile', params: { type: 'memberDetails', id } })
    },
    search () {
      this.dismiss()
      /* if (!this.searchText) return  */
      this.$router.push({ path: '/members' })
      // this.$router.push({ name: 'SearchResults', params: { search: this.searchText } })
    },
    enterPressed (e) {
      if (!this.isNavigating && this.shownMembers.length) {
        this.isNavigating = this.shownMembers[0].uuid
        return
      }
      const member = this.shownMembers[this.navigateIndex]
      if (!member) return
      if (this.isSelector) {
        this.add(member)
        this.navigateNext(e)
        return
      }
      this.dismiss()
      this.$router.push({ name: 'UserProfile', params: { type: 'memberDetails', id: member.uuid } })
    },
    navigateDown () {
      if (!this.shownMembers.length) return
      if (!this.isNavigating) {
        this.isNavigating = this.shownMembers[0].uuid
        return
      }

      const next = Math.min(this.shownMembers.length - 1, this.navigateIndex + 1)
      this.isNavigating = this.shownMembers[next].uuid
    },
    navigateUp () {
      if (!this.shownMembers.length) return
      if (!this.isNavigating) {
        this.isNavigating = this.shownMembers[this.shownMembers.length - 1].uuid
        return
      }

      const prev = Math.max(0, this.navigateIndex - 1)
      this.isNavigating = this.shownMembers[prev].uuid
    },
    navigateNext (e) {
      if (!this.shownMembers.length) return
      if (this.navigateIndex === this.shownMembers.length - 1) {
        this.isNavigating = this.shownMembers[0].uuid
      } else {
        this.navigateDown()
      }
      setTimeout(() => {
        this.$refs.vAutocomplete.focus()
      }, 100)
      e.preventDefault()
    },
    dismiss () {
      this.isNavigating = null
      this.$refs.vAutocomplete.blur()
    }
  }
}
</script>

<style scoped lang="scss">
.v-input ::v-deep .v-label {
  color: #C7C7C7 !important;
}

.v-input ::v-deep .v-input__slot {
  padding: 0 !important;
}

.v-autocomplete ::v-deep .v-label {
  line-height: 1.2 !important;
}

.v-autocomplete--search-header {
  .theme--dark.v-icon {
    color: rgba(255, 255, 255, 0.7);
  }
}
.v-autocomplete-mobile ::v-deep .v-input__slot {
  background: #fff !important;
}

.v-autocomplete--search-mobile {
  min-width: 100vw !important;
  left: 0 !important;
}
.v-list-item__content {
  width: 40px !important;
}

.people-wrapper {
  gap: 5px;
}

::v-deep .v-input__append-inner {
  width: 40px;
}

.is-navigating ::v-deep .v-input__slot {
  background-color: #fff !important;
}

.navigating {
  background: #ebf4fa;
}

@media (min-width: 600px) {
  .people-wrapper {
    max-width: calc(64vw - 300px);
    &.sm {
      max-width: 89vw;
    }
  }
}
</style>

<style>
  .v-autocomplete--search-mobile {
    top: 180px !important;
  }
  .v-autocomplete-navigating {
    display: unset !important
  }
</style>
