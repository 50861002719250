<template>
  <component :is="isComponent()"/>
</template>

<script>
import Dashboard from '@/views/Dashboard'
import DashboardAdmin from '@/views/DashboardAdmin'
import { Role } from '@/_helpers/role'

export default {
  name: 'Home',
  components: { Dashboard, DashboardAdmin },
  methods: {
    isComponent () {
      const userData = this.$store.state.auth.currentUser
      let component = ''

      if (userData) {
        component = userData.role === Role.User
          ? 'Dashboard'
          : 'DashboardAdmin'
      }

      return component
    }
  }
}
</script>

<style scoped>

</style>
