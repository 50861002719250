<template>
  <v-select
    v-if="label"
    v-model="selected"
    :items="value"
    item-text="label"
    item-value="value"
    class="pt-0 select w-200"
    menu-props="offsetY"
    solo
    outlined
    hide-details
  >
    <template v-slot:append>
      <v-icon size="15px">unfold_more</v-icon>
    </template>
  </v-select>
  <v-menu
    v-else
    bottom
    left
    max-width="203px"
    nudge-left="-8px"
    nudge-bottom="10px"
    :rounded="'1'"
    offset-y
    open-on-click
    content-class="elevation-0 v-menu--triangle"
  >
    <template v-slot:activator="{ on }">
      <v-btn
        v-if="newClass !== 'more-actions' || $vuetify.breakpoint.mdAndUp"
        v-on="on"
        class="all-log-dropdown text-none text-left pl-3 pr-2 py-2 d-flex align-center justify-space-between w-100"
        :class="newClass"
        :width="selectWidth"
        height="36px"
        style="border-radius: 3px;background: linear-gradient(180deg, #FFFFFF 0%, #F9FAFB 100%);box-shadow: 0 1px 0 0 rgba(22,29,37,0.05);"
        :style="newClass === 'more-actions' ? 'color: #637381' : ''"
      >
        {{ contactType }}

        <v-icon size="16px" color="#637381" class="ml-2">{{selectIcon}}</v-icon>
      </v-btn>
      <v-btn
        v-else
        v-on="on"
        class="all-log-dropdown text-none text-left d-flex align-center justify-space-between px-0"
        :class="newClass"
        height="36px"
        style="border-radius: 3px;background: linear-gradient(180deg, #FFFFFF 0%, #F9FAFB 100%);box-shadow: 0 1px 0 0 rgba(22,29,37,0.05); min-width:32px"
        :style="newClass === 'more-actions' ? 'color: #637381' : ''"
      >
        <v-icon size="16px" color="#637381" class="ml-2">more_vert</v-icon>
      </v-btn>
    </template>

    <v-card elevation="1">
      <v-list-item-content class="justify-center py-1">
        <v-btn
          depressed
          text
          height="40px"
          class="text-capitalize fill-width rounded-0 justify-start v-btn--violet-hover mb-0"
          :style="contactType === type ? 'background-color: #5C6AC4; color: #fff;' : ''"
          v-for="(type, typeIndex) in value"
          :key="typeIndex"
          @click="getContactTypes(typeIndex)"
          :class="type === 'More Actions' ? 'd-none' : ''"
        >
          <span class="fz-14">{{ type !== 'More Actions' ? type : '' }}</span>
        </v-btn>
      </v-list-item-content>
    </v-card>
  </v-menu>
</template>

<script>
export default {
  name: 'SelectComponent',
  props: {
    value: {
      type: Array,
      default: null
    },
    selectWidth: {
      type: String,
      default: 'auto'
    },
    selectIcon: {
      type: String,
      default: 'unfold_more'
    },
    newClass: {
      type: String,
      default: ''
    },
    label: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    contactType: '',
    selected: ''
  }),
  watch: {
    selected () {
      this.$emit('selectedValue', this.selected)
    }
  },
  methods: {
    getContactTypes (index) {
      this.contactType = this.value[index]
      this.$emit('selectedValue', this.contactType)
      if (this.newClass !== 'more-actions') return
      this.contactType = this.value[0]
    }
  },
  mounted () {
    this.contactType = this.value[0]
  }
}
</script>
<style scoped lang="scss">
  .all-log-dropdown {
    @include media-breakpoint-up(md){
      border: 1px solid #C4CDD5;
      min-width: 120px !important;
    }
  }
</style>
