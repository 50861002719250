<template>
  <v-dialog
    v-model="dialog"
    persistent
    max-width="724px"
  >
    <v-card class="pa-0">
      <v-card-title class="mb-1 d-flex align-center justify-space-between">
        <h2 class="font-weight-bold">{{ title }}</h2>

        <v-tooltip top content-class="v-tooltip--top">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon
              class="modal-close"
              @click="close"
              v-bind="attrs"
              v-on="on"
            >
              <v-icon size="24px">close</v-icon>
            </v-btn>
          </template>
          <span>Close</span>
        </v-tooltip>
      </v-card-title>

      <v-card-subtitle>
        <span>{{ date }} · {{ time }} · {{ source }} · {{ extension }}</span>
      </v-card-subtitle>

      <v-card-text>
        <p class="mb-0">{{ text }}</p>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'TranscriptionModal',
  props: {
    dialog: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    title: 'Transcription #4872',
    date: '22 Mar 2017',
    time: '02:48PM',
    source: 'Support mailbox',
    extension: '×8721',
    text: 'Last month, my wife, Anne Doe, took me to Las Vegas because she had to go for a business convention. Needless\n' +
      '          to say, she writes for an guide to casinos and I hate gambling. But then, she likes it and this supports us\n' +
      '          too, so I went along without any hassle. At first I was depressed, but then as I asked around and looked\n' +
      '          around, I ended up having more fun in Las Vegas than I would have thought. And no. I did not enter a single\n' +
      '          casino while I was there.'
  }),
  methods: {
    close () {
      this.$emit('update:editDialog', false)
    }
  }
}
</script>
