<template>
  <transition>
    <div class="mt-md-15 pt-4" style="height: 100%">
      <div class="fz-12 font-weight-semi-bold text-uppercase mb-2">Callback Requested</div>

      <v-card
        class="overflow-hidden"
        height="auto"
        max-width="316px"
      >
        <v-card-title
          class="pa-4 pb-0 fz-12 text-uppercase font-weight-bold dark-text d-flex justify-space-between"
          style="line-height: 16px;"
        >
          Send To

          <v-btn
            text
            class="text-none pa-0 text-capitalize font-weight-regular"
            height="20px"
            min-width="46px"
            color="#007ACE"
            @click="close"
          >Cancel
          </v-btn>

        </v-card-title>
        <v-col
          cols="12"
          md="12"
          class="px-4 py-2"
        >
          <v-text-field
            solo
            outlined
            label="Search people, mailboxes"
            type="text"
            v-model="search"
            dense
            hide-details
            style="height: 36px;"
          >
            <template v-slot:prepend-inner>
              <v-icon size="20px" color="#919EAB">search</v-icon>
            </template>
          </v-text-field>
        </v-col>
        <v-list class="pa-0">
          <template v-for="(request, requestIndex) in requested">
            <v-divider
              v-if="requestIndex"
              :key="requestIndex + 'divider'"
            ></v-divider>

            <v-list-item class="px-4 pr-2 py-0" :key="requestIndex + 'list-item'">
              <v-list-item-avatar
                width="40px"
                height="40px"
              >
                <v-img
                  :alt="`${request.firstname} avatar`"
                  :src="request.avatar + '?random=' + requestIndex"
                ></v-img>
              </v-list-item-avatar>

              <v-list-item-content>
                <v-list-item-title class="fz-14 font-weight-medium dark-text">
                  {{ fullName(request.firstname, request.lastname) }}
                </v-list-item-title>
                <v-list-item-subtitle class="fz-14 grey--text text--darken-1">
                  {{ request.phone }}
                </v-list-item-subtitle>
              </v-list-item-content>

              <v-list-item-action class="flex-row align-center">
                <v-tooltip bottom content-class="v-tooltip--bottom">
                  <template v-slot:activator="{ on, attrs}">
                    <v-btn
                      class="mr-1 text-capitalize fz-14"
                      outlined
                      color="#637381"
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon size="16px" color="#637381" class="mr-1">send</v-icon>
                      Send
                    </v-btn>
                  </template>

                  <span>Send</span>
                </v-tooltip>
              </v-list-item-action>
            </v-list-item>
          </template>

          <v-divider></v-divider>

          <v-btn
            text
            @click="logExpand = !logExpand"
            color="#007ACE"
            width="100%"
            height="auto"
            class="px-0 py-4 text-none text-center"
            tile
          >
            Show all
            <v-icon color="regular-text" :class="{ 'button__icon--active': logExpand }">expand_more</v-icon>
          </v-btn>
        </v-list>

        <v-divider/>
        <v-card-title class="text-body-1 font-weight-bold" @click="log">
          Incoming Calls for members
          <v-icon class="ml-auto" size="20" color="#919EAB">chevron_right</v-icon>
        </v-card-title>
        <v-card-text>
          <line-chart :data="analytics.incomingMembers" :options="options" style="height: 90px"/>
        </v-card-text>
      </v-card>
    </div>
  </transition>
</template>

<script>
import LineChart from '@/components/LineChart'
export default {
  name: 'CallbackRequestedMailbox',
  components: { LineChart },
  data: () => ({
    search: '',
    logExpand: false,
    analytics: {
      incomingNumbers: {
        labels: [/* 'Sep 1', 'Sep 2', 'Sep 3', 'Sep 4', 'Sep 5', 'Sep 6', 'Sep 7', 'Sep 8', 'Sep 9', 'Sep 10', 'Sep 11', 'Sep 12' */],
        datasets: [
          {
            backgroundColor: 'transparent',
            borderColor: '#9C6ADE',
            data: [/* 40, 20, 12, 39, 10, 40, 39, 80, 40, 20, 12, 11 */],
            pointHoverRadius: 5,
            pointHoverBackgroundColor: '#9C6ADE'
          }
        ]
      },
      incomingMembers: {
        labels: [/* 'Sep 1', 'Sep 2', 'Sep 3', 'Sep 4', 'Sep 5', 'Sep 6', 'Sep 7', 'Sep 8', 'Sep 9', 'Sep 10', 'Sep 11', 'Sep 12' */],
        datasets: [
          {
            backgroundColor: 'transparent',
            borderColor: '#47C1BF',
            data: [/* 4, 2, 32, 9, 11, 42, 9, 8, 4, 2, 1, 11 */],
            pointHoverRadius: 5,
            pointHoverBackgroundColor: '#47C1BF'
          }
        ]
      }
    },
    options: {
      maintainAspectRatio: false,
      elements: {
        line: {
          tension: 0
        }
      },
      scales: {
        xAxes: [{
          ticks: {
            display: false
          }
        }],
        yAxes: [{
          ticks: {
            display: false
          }
        }]
      },
      legend: {
        display: false
      }
    },
    requested: [
      /* {
        id: 1,
        avatar: 'https://picsum.photos/200',
        firstname: 'Aaron',
        lastname: 'Kennedy',
        phone: '811-281-1051'
      },
      {
        id: 2,
        avatar: 'https://picsum.photos/200',
        firstname: 'Madge',
        lastname: 'Dawson',
        phone: '811-281-1051'
      },
      {
        id: 3,
        avatar: 'https://picsum.photos/200',
        firstname: 'Madge',
        lastname: 'Dawson',
        phone: '811-281-1051'
      },
      {
        id: 3,
        avatar: 'https://picsum.photos/200',
        firstname: 'Madge',
        lastname: 'Dawson',
        phone: '811-281-1051'
      } */
    ]
  }),
  methods: {
    log () {
      this.$router.push({ path: '/analytics' })
    },
    logListExpand () {
      return this.logExpand ? this.log : this.log.slice(0, 4)
    },
    fullName (firstname, lastname) {
      return firstname + ' ' + lastname
    },
    close () {
      this.$store.dispatch('components/CallbackRequestedMailbox', false)
      // this.$emit('CallbackRequestedMailbox', false)
    }
  }
}
</script>
