<template>
  <transition>
    <v-card
      class="fz-14 mb-5 overflow-hidden v-card--user-information position-relative margin-top"
      max-width="316px"
      style="box-shadow: 0 0 0 1px rgba(6,44,82,0.1), 0 1px 3px 0 rgba(64,66,69,0.12), 0 2px 16px 0 rgba(33,43,54,0.08);"
    >
      <div class="fz-12 font-weight-semi-bold dark-text text-uppercase pa-4 d-flex justify-space-between align-center">
        Play message

        <v-btn
          text
          class="text-none pa-0"
          height="20px"
          min-width="46px"
          color="#007ACE"
          @click="close"
        >Cancel
        </v-btn>
      </div>

      <v-list class="pt-0">
        <template v-for="(message, index) in messageList">
          <v-divider
            v-if="index"
            :key="index + 'divider'"
          ></v-divider>

          <v-list-item :key="index">
            <v-list-item-content>
              <v-list-item-title
                class="fz-14 font-weight-medium dark-text"
              >
                {{ message.title }}
              </v-list-item-title>
              <v-list-item-subtitle
                class="fz-14 grey--text text--darken-1"
              >
                {{ message.duration }} sec
              </v-list-item-subtitle>

              <audio
                ref="audio"
                :src="message.file">
              </audio>
            </v-list-item-content>

            <v-list-item-action>
              <v-btn
                class="pa-2"
                :class="message.paused ? 'grey-button' : 'violet-button'"
                height="30px"
                min-width="76px"
                @click="togglePlaying(index, message.title)"
              >
                <v-icon
                  size="24px"
                  :color="message.paused ? '#919EAB' : '#FFF'"
                  class="mr-2"
                >
                  {{ message.paused ? 'play_arrow' : 'pause' }}
                </v-icon>
                {{ message.paused ? 'Play' : 'Pause' }}
              </v-btn>
            </v-list-item-action>
          </v-list-item>
        </template>
      </v-list>
    </v-card>
  </transition>
</template>

<script>
export default {
  name: 'HistoryIncomingCallUserInformation',
  props: {
    dialog: {
      type: Boolean,
      default: true
    }
  },
  data: () => ({
    messageList: [
      {
        title: 'Rosa Ferguson',
        file: '/CafeDelMar.mp3',
        duration: 43,
        paused: true
      },
      {
        title: 'Rosa Ferguson',
        file: '/CafeDelMar.mp3',
        duration: 43,
        paused: true
      },
      {
        title: 'Rosa Ferguson',
        file: '/CafeDelMar.mp3',
        duration: 43,
        paused: true
      }
    ]
  }),
  methods: {
    togglePlaying (index, name) {
      if (this.$refs.audio[index].paused) {
        this.$refs.audio[index].play()
        this.messageList[index].paused = false

        console.log('nameee', name)
        this.$store.dispatch('components/setPlayedMessage', { name: name })
      } else {
        this.$refs.audio[index].pause()
        this.messageList[index].paused = true
      }
    },
    close () {
      // this.$emit('closePlayMessage', false)
      this.$store.dispatch('components/chatMessage', false)
    }
  }
}
</script>
<style lang="scss">
  .margin-top:first-of-type {
    margin-top: 146px !important;
  }
</style>
