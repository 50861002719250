<template>
  <transition>
    <div class="mt-md-15 pt-4" style="height: 100%">
      <div class="fz-12 font-weight-semi-bold text-uppercase mb-2">Callback Requested</div>

      <v-card
        class="overflow-hidden"
        height="auto"
        max-width="316px"
      >
        <v-card-title
          class="pa-4 pb-0 fz-12 text-uppercase font-weight-bold dark-text d-flex justify-space-between"
          style="line-height: 16px;"
        >
          Play Message

          <v-btn
            text
            class="text-none pa-0 text-capitalize font-weight-regular"
            height="20px"
            min-width="46px"
            color="#007ACE"
            @click="close"
          >Cancel
          </v-btn>

        </v-card-title>
        <v-list class="pa-0">
          <template v-for="(request, requestIndex) in requested">
            <v-divider
              v-if="requestIndex"
              :key="requestIndex + 'divider'"
            ></v-divider>

            <v-list-item class="px-4 pr-2 py-0" :key="requestIndex + 'list-item'">

              <v-list-item-content>
                <v-list-item-title class="fz-14 font-weight-medium dark-text">
                  {{ request.title }}
                </v-list-item-title>
                <v-list-item-subtitle class="fz-14 grey--text text--darken-1">
                  {{ request.time }}
                </v-list-item-subtitle>
              </v-list-item-content>

              <v-list-item-action class="flex-row align-center">
                <v-tooltip bottom content-class="v-tooltip--bottom">
                  <template v-slot:activator="{ on, attrs}">
                    <v-btn
                      class="mr-1 text-capitalize fz-14"
                      outlined
                      color="#637381"
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon size="16px" color="#637381" class="mr-1">play_arrow</v-icon>
                      Play
                    </v-btn>
                  </template>

                  <span>Play</span>
                </v-tooltip>
              </v-list-item-action>
            </v-list-item>
          </template>

          <v-divider></v-divider>

          <v-btn
            text
            to="/mailbox/callback"
            color="#007ACE"
            width="100%"
            height="auto"
            class="px-0 py-4 text-none text-center"
            tile
          >
            Go to Callback mailbox of CEO mailbox
          </v-btn>
        </v-list>
      </v-card>
      <analytics class="mt-5"/>
    </div>
  </transition>
</template>

<script>
import Analytics from './Analytics'
export default {
  name: 'PlayMessage',
  components: { Analytics },
  data: () => ({
    search: '',
    logExpand: false,
    requested: [
      {
        title: 'Greeting',
        time: '12 sec'
      },
      {
        title: 'Hold, user is busy',
        time: '7 sec'
      },
      {
        title: 'Meet a new client',
        time: '24 sec'
      }
    ]
  }),
  methods: {
    close () {
      this.$store.dispatch('components/playMessage', false)
      // this.$emit('CallbackRequestedMailbox', false)
    }
  }
}
</script>
