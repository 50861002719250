<template>
  <transition>
    <v-card
      class="fz-14 mb-5 overflow-hidden v-card--user-information position-relative margin-top-md"
      max-height="880px"
      style="box-shadow: 0 0 0 1px rgba(6,44,82,0.1), 0 1px 3px 0 rgba(64,66,69,0.12), 0 2px 16px 0 rgba(33,43,54,0.08); height: auto"
      tile
    >
      <v-row class="ma-0" style="height: calc(100vh - 231px); overflow-y: auto;">
        <v-col v-if="!editMode">
          <v-row>
            <v-col
              cols="12"
              md="12"
              class="pa-4 px-lg-3 px-xl-4 d-flex align-center"
            >
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-btn
                    v-on="on"
                    class="grey-button py-1 px-2 my-1"
                    min-width="44px"
                    :class="user.favourite ? 'indigo--text text--lighten-1' : ''"
                    @click="user.favourite = !user.favourite"
                  >
                    <v-icon
                      size="20px"
                      :color="user.favourite ? '#5C6AC4' : '#919EAB'"
                    >
                      star
                    </v-icon>
                    <span class="d-md-none d-lg-block ml-1">{{ user.favourite ? 'Remove' : 'favourite' }}</span>
                  </v-btn>
                </template>
                <span>{{ user.favourite ? 'Remove' : 'favourite' }}</span>
              </v-tooltip>

              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-btn
                    v-on="on"
                    :disabled="user.isNew"
                    class="grey-button py-1 px-2 mx-xl-3 mx-lg-2 mx-3 my-1"
                    min-width="44px"
                    @click="editMode = true"
                  >
                    <v-icon
                      size="19px"
                      color="#919EAB"
                    >
                      edit
                    </v-icon>
                    <span class="d-md-none d-lg-block ml-1">Edit</span>
                  </v-btn>
                </template>
                <span>Edit</span>
              </v-tooltip>

              <!--              <v-dialog-->
              <!--                v-model="dialog"-->
              <!--                persistent-->
              <!--                max-width="348"-->
              <!--                :attach="'.v-card&#45;&#45;user-information'"-->
              <!--              >-->
              <!--                <template v-slot:activator="{ on, attrs }">-->
              <!--                  <v-btn-->
              <!--                    class="py-1 px-2 ml-auto"-->
              <!--                    :class="user.isFriend ? 'grey-button' : 'violet-button'"-->
              <!--                    v-bind="attrs"-->
              <!--                    v-on="on"-->
              <!--                  >-->
              <!--                    <v-icon-->
              <!--                      size="22px"-->
              <!--                      :color="user.isFriend ? '#919EAB' : '#FFF'"-->
              <!--                      class="mr-2"-->
              <!--                    >-->
              <!--                      {{ user.isFriend ? 'remove_circle' : 'person_add' }}-->
              <!--                    </v-icon>-->
              <!--                    {{ user.isFriend ? 'Remove' : 'Add contact' }}-->
              <!--                  </v-btn>-->
              <!--                </template>-->

              <!--                <v-card>-->
              <!--                  <div class="pa-4 fz-20 dark-text">-->
              <!--                    Remove Aaron Kennedy from contacts-->
              <!--                  </div>-->

              <!--                  <v-card-actions class="px-4 pb-6 pt-0">-->
              <!--                    <v-btn-->
              <!--                      class="grey-button py-3 px-6 mr-2"-->
              <!--                      @click="unfriend"-->
              <!--                      height="44px"-->
              <!--                    >-->
              <!--                      Unfriend-->
              <!--                    </v-btn>-->

              <!--                    <v-btn-->
              <!--                      class="violet-button py-3 px-6"-->
              <!--                      @click="dialog = false"-->
              <!--                      height="44px"-->
              <!--                    >-->
              <!--                      Cancel-->
              <!--                    </v-btn>-->
              <!--                  </v-card-actions>-->
              <!--                </v-card>-->
              <!--              </v-dialog>-->

              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-btn
                    v-on="on"
                    :class="user.isFriend ? 'grey-button' : 'violet-button'"
                    :disabled="!user.isNew"
                    class="py-1 px-2 ml-auto my-1"
                    min-width="44px"
                    @click="editMode = true"
                  >
                    <v-icon
                      size="18px"
                      :color="user.isFriend ? '#919EAB' : '#FFF'"
                    >
                      {{ user.isFriend ? 'remove_circle' : 'person_add' }}
                    </v-icon>
                    <span class="d-md-none d-lg-block ml-1">{{ user.isFriend ? 'Remove' : 'Update contact' }}</span>
                  </v-btn>
                </template>
                <span>{{ user.isFriend ? 'Remove' : 'Add contact' }}</span>
              </v-tooltip>

              <v-overlay
                absolute
                v-if="dialog"
                color="rgba(33,43,54,0.4)"
                top
              >
                <v-card
                  width="100%"
                  color="#fff"
                  transition="scale-transition"
                >
                  <div class="pa-4 fz-20 dark-text">
                    Remove Aaron Kennedy from contacts
                  </div>

                  <v-card-actions class="px-4 pb-6 pt-0">
                    <v-btn
                      class="grey-button py-3 px-6 mr-2"
                      @click="unfriend"
                      height="44px"
                    >
                      Unfriend
                    </v-btn>

                    <v-btn
                      class="violet-button py-3 px-6"
                      @click="dialog = false"
                      height="44px"
                    >
                      Cancel
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-overlay>
            </v-col>

            <v-col
              cols="12"
              md="12"
              class="pa-0"
            >
              <v-divider></v-divider>
            </v-col>

            <v-col
              cols="12"
              md="12"
              class="pa-4"
            >
              <div class="fz-12 font-weight-semi-bold dark-text text-uppercase mb-2">Contacts</div>

              <div class="d-flex justify-space-between align-center mb-3">
                <span>{{ user.phoneNumber }}</span>

                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      v-on="on"
                      class="grey-button py-2 px-3"
                      height="30px"
                      min-width="44px"
                      @click="call"
                    >
                      <v-icon
                        size="20px"
                        color="#919EAB"
                      >
                        phone
                      </v-icon>
                      <span class="d-md-none d-lg-block ml-2">Call</span>
                    </v-btn>
                  </template>
                  <span>Call</span>
                </v-tooltip>
              </div>

              <div class="d-flex justify-space-between align-center">
                <span>{{ user.email }}</span>

                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      v-on="on"
                      class="grey-button py-2 px-3"
                      height="30px"
                      @click="message"
                      min-width="44px"
                    >
                      <v-icon
                        size="20px"
                        color="#919EAB"
                      >
                        message
                      </v-icon>
                      <span class="d-md-none d-lg-block ml-2">Message</span>
                    </v-btn>
                  </template>
                  <span>Message</span>
                </v-tooltip>
              </div>
            </v-col>

            <v-col
              cols="12"
              md="12"
              class="pa-0"
            >
              <v-divider></v-divider>
            </v-col>

            <v-col
              cols="12"
              md="12"
              class="pa-0 pt-4"
            >
              <div class="fz-12 font-weight-semi-bold dark-text text-uppercase mb-2 pl-4">Recordings</div>

              <v-list
                max-height="588px"
                class="overflow-y-auto px-4 pb-3"
              >
                <template v-for="(vm, vmIndex) in voicemailsArray">
                  <track-item
                    :key="vmIndex + 'track'"
                    :value="vm"
                    :isView="'HistoryComponent'"
                  />
                  <v-divider
                    :key="vmIndex + 'divider'"
                  ></v-divider>
                </template>
              </v-list>

              <v-btn
                text
                tile
                link
                class="text-none fill-width"
                color="link"
                height="50px"
                @click="voicemailsExpand = !voicemailsExpand"
              >
                Show all
                <v-icon
                  color="regular-text"
                  :class="{ 'button__icon--active': voicemailsExpand }"
                >
                  expand_more
                </v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-col>

        <v-col v-if="editMode">
          <v-row>
            <v-col
              cols="12"
              md="12"
              class="pa-4 d-flex justify-space-between"
            >
              <v-btn
                class="grey-button py-1 px-2"
                @click="editMode = false"
              >
                Cancel
              </v-btn>

              <v-btn
                class="violet-button py-1 px-2"
                @click="saveInfo"
              >
                <v-icon
                  size="22px"
                  color="#fff"
                  class="mr-2"
                >
                  done
                </v-icon>
                Save info
              </v-btn>
            </v-col>

            <v-col
              cols="12"
              md="12"
              class="pa-0"
            >
              <v-divider></v-divider>
            </v-col>

            <v-col
              cols="12"
              md="12"
              class="pa-4"
            >
              <v-form
                ref="form"
                v-model="valid"
                lazy-validation
              >
                <v-row class="mx-n4">
                  <v-col
                    cols="12"
                    md="12"
                    class="pb-0 px-4"
                  >
                    <div class="fz-12 font-weight-semi-bold dark-text text-uppercase">General information</div>
                  </v-col>

                  <v-col
                    cols="12"
                    md="12"
                    class="d-flex px-4 py-2"
                  >
                    <v-list-item-avatar width="60px" height="60px" class="mr-3">
                      <v-img
                        :alt="`${user.firstName} avatar`"
                        :src="user.avatar"
                      ></v-img>
                    </v-list-item-avatar>

                    <v-list-item-content class="d-block py-0">
                      <v-list-item-title class="fz-14 mb-3">
                        Change picture
                      </v-list-item-title>

                      <FileInput :filesUploaded.sync="user.filesUploaded"/>
                    </v-list-item-content>
                  </v-col>

                  <v-col
                    cols="12"
                    md="12"
                    class="py-0 px-4"
                  >
                    <label class="fz-14 mb-1 d-block">First name</label>
                    <v-text-field
                      v-model="user.firstName"
                      :rules="firstnameRules"
                      solo
                      outlined
                      label="Enter first name"
                      type="tel"
                      dense
                    ></v-text-field>
                  </v-col>

                  <v-col
                    cols="12"
                    md="12"
                    class="py-0 px-4"
                  >
                    <label class="fz-14 mb-1 d-block">Last name</label>
                    <v-text-field
                      v-model="user.lastName"
                      :rules="lastnameRules"
                      solo
                      outlined
                      label="Enter last name"
                      type="tel"
                      dense
                    ></v-text-field>
                  </v-col>

                  <v-col
                    cols="12"
                    md="12"
                    class="pa-0"
                  >
                    <v-divider></v-divider>
                  </v-col>

                  <v-col
                    cols="12"
                    md="12"
                    class="pb-0 px-4"
                  >
                    <div class="fz-12 font-weight-semi-bold dark-text text-uppercase">Contacts</div>
                  </v-col>

                  <v-col
                    cols="12"
                    md="12"
                    class="pa-4 pb-0"
                  >
                    <label class="fz-14 mb-1 d-block">Phone number</label>
                    <v-text-field
                      v-model="user.phoneNumber"
                      :rules="phoneRules"
                      solo
                      outlined
                      label="Phone number"
                      type="tel"
                      dense
                    ></v-text-field>
                  </v-col>

                  <v-col
                    cols="12"
                    md="12"
                    class="py-0 px-4"
                  >
                    <label class="fz-14 mb-1 d-block">Email</label>
                    <v-text-field
                      v-model="user.email"
                      :rules="emailRules"
                      solo
                      outlined
                      label="Enter email"
                      type="tel"
                      dense
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-form>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <vue-snotify />
    </v-card>
  </transition>
</template>

<script>
import TrackItem from '@/components/TrackItem'
import FileInput from '@/components/FileInput'

import { mapState } from 'vuex'

export default {
  name: 'HistoryIncomingCallUserInformation',
  components: {
    FileInput,
    TrackItem
  },
  data: () => ({
    editMode: false,
    dialog: false,
    valid: false,
    user: {},
    firstnameRules: [
      v => !!v || 'Name is required',
      v => v.length <= 10 || 'Name must be less than 10 characters'
    ],
    lastnameRules: [
      v => !!v || 'Last name is required',
      v => v.length <= 15 || 'Last name must be less than 15 characters'
    ],
    emailRules: [
      v => !!v || 'E-mail is required',
      v => /[a-z0-9]+@[a-z]+\.[a-z]{2,3}/.test(v) || 'E-mail must be valid'
    ],
    phoneRules: [
      v => !!v || 'Phone number is required',
      v => /^[0-9]+$/.test(v) || 'Phone number must be valid'
    ],
    voicemailsExpand: false,
    voicemails: [
      {
        name: 'Rosa Ferguson',
        phone: '080-021-3014',
        date: '08 Apr 2017',
        time: '01:52PM',
        source: 'Support mailbox  x8721',
        file: '/CafeDelMar.mp3',
        duration: 43
      },
      {
        name: 'Rosa Ferguson',
        phone: '080-021-3014',
        date: '08 Apr 2017',
        time: '01:52PM',
        source: 'Support mailbox  x8721',
        file: '/CafeDelMar.mp3',
        duration: 43
      },
      {
        name: 'Rosa Ferguson',
        phone: '080-021-3014',
        date: '08 Apr 2017',
        time: '01:52PM',
        source: 'Support mailbox  x8721',
        file: '/CafeDelMar.mp3',
        duration: 43
      },
      {
        name: 'Rosa Ferguson',
        phone: '080-021-3014',
        date: '08 Apr 2017',
        time: '01:52PM',
        source: 'Support mailbox  x8721',
        file: '/CafeDelMar.mp3',
        duration: 43
      },
      {
        name: 'Rosa Ferguson',
        phone: '080-021-3014',
        date: '08 Apr 2017',
        time: '01:52PM',
        source: 'Support mailbox  x8721',
        file: '/CafeDelMar.mp3',
        duration: 43
      },
      {
        name: 'Rosa Ferguson',
        phone: '080-021-3014',
        date: '08 Apr 2017',
        time: '01:52PM',
        source: 'Support mailbox  x8721',
        file: '/CafeDelMar.mp3',
        duration: 43
      },
      {
        name: 'Rosa Ferguson',
        phone: '080-021-3014',
        date: '08 Apr 2017',
        time: '01:52PM',
        source: 'Support mailbox  x8721',
        file: '/CafeDelMar.mp3',
        duration: 43
      }
    ]
  }),
  computed: {
    ...mapState('components', [
      'userInfo'
    ]),
    voicemailsArray () {
      return this.voicemailsExpand ? this.voicemails : this.voicemails.slice(0, 1)
    }
  },
  created () {
    this.user = {
      ...this.userInfo
    }
  },
  watch: {
    userInfo (val) {
      this.user = {
        ...val
      }
    }
  },
  methods: {
    fullName (firstName, lastName) {
      return firstName + ' ' + lastName
    },
    call () {
      console.log('Calling to the phone number: ' + this.user.phoneNumber)
    },
    message () {
      console.log('Sending message to the phone number: ' + this.user.phoneNumber)
    },
    unfriend () {
      this.user.isFriend = false
      this.dialog = false
    },
    saveInfo () {
      this.$refs.form.validate()

      if (this.valid) {
        this.user.isNew
          ? this.addContact()
          : this.editContact()

        this.editMode = false
      } else {
        console.log('Change user information are not validate!')
      }
    },
    addContact () {
      const payload = {
        ...this.user,
        phone: this.user.phoneNumber,
        isActive: true
      }

      this.$store
        .dispatch('createContact', { form: payload })
        .then((res) => {
          this.$store.dispatch('components/userInfo', res.data.contact)
          this.$snotify.notifications = []
          this.$snotify.success('Contact has been added')
        }).catch(err => {
          this.$snotify.error(err)
          console.log('err', err)
        })
    },
    editContact () {
      const payload = {
        ...this.user,
        phone: this.user.phoneNumber,
        isActive: true
      }

      this.$store
        .dispatch('editContact', { form: payload })
        .then((res) => {
          const data = res.data.contact

          this.$store.dispatch('components/userInfo', {
            email: data.cont_email,
            firstName: data.cont_first_name,
            lastName: data.cont_last_name,
            id: data.cont_id,
            active: data.cont_active,
            favourite: data.cont_favourite,
            phoneNumber: data.cont_phone_number
          })

          this.$snotify.notifications = []
          this.$snotify.success('Contact has been edited')
        }).catch(err => {
          this.$snotify.error(err)
          console.log('err', err)
        })
    }
  }
}
</script>
<style scoped lang="scss">
  .v-overlay::v-deep .v-overlay__content {
    position: absolute !important;
    top: 150px !important;
  }
  .margin-top-md:first-of-type {
    @include media-breakpoint-up(md) {
      margin-top: 146px !important;
    }
  }
</style>
