<template>
  <v-dialog
    v-model="subscribeDialog"
    persistent
    max-width="400px"
    >
    <v-card :loading="loading" class="pa-0">
      <v-card-title class="font-weight-bold">
        Subscribe
      </v-card-title>

      <v-card-subtitle>
        You can enable outbound calling and messaging by subscribing to a paid plan.
      </v-card-subtitle>

      <v-card-text>
        <v-radio-group v-model="paymentPeriod">
          <v-radio label="Month" value="month" />
          <v-radio label="Year" value="year" />
        </v-radio-group>
        <p><span class="black--text">Price:</span> <span>${{ subscribePrice }}</span></p>
      </v-card-text>

      <v-card-actions class="pb-5 pt-0 justify-md-end">
        <v-btn
          color="secondary"
          outlined
          class="mx-2 px-4 text-capitalize"
          @click="setSubscribeDialog(false)"
        >
          Cancel
        </v-btn>
        <v-btn
          depressed
          color="primary"
          class="mx-2 px-4 text-capitalize"
          @click="upgradeNumber"
        >
          Subscribe
        </v-btn>
      </v-card-actions>

    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex'
export default {
  name: 'SubscribeDialog',
  data: () => ({
    loading: false,
    subscribePrice: 0,
    paymentPeriod: 'month',
    features: ['sms', 'voice']
  }),
  computed: {
    ...mapGetters('components', ['subscribeDialog']),
    ...mapGetters(['phoneNumbers'])
  },
  watch: {
    paymentPeriod () {
      this.getTotalAmout()
    }
  },
  created () {
    this.getTotalAmout()
  },
  methods: {
    ...mapMutations('components', ['setSubscribeDialog']),
    ...mapActions(['didNumbersList', 'fetchTotalAmount', 'upgradeNumberPaid']),
    async getTotalAmout () {
      try {
        this.loading = true

        if (!this.phoneNumbers.length) {
          this.subscribePrice = 0
          return
        }
        const payload = {
          durationUnit: this.paymentPeriod,
          numbers: this.phoneNumbers.map(item => ({
            didNumber: item.didNumber.toString(),
            features: this.features
          }))
        }

        const data = await this.fetchTotalAmount(payload)
        if (!data) return

        this.subscribePrice = data.totalAmount
      } catch (e) {
        this.$snotify.error(e.message)
        throw new Error(e)
      } finally {
        this.loading = false
      }
    },
    async upgradeNumber () {
      try {
        const { data } = await this.upgradeNumberPaid({
          durationUnit: this.paymentPeriod
        })
        if (!data || !data.url) return
        window.location.replace(data.url)
      } catch (err) {
        this.$snotify.error(err.message)
        throw new Error(err)
      }
    }
  }
}
</script>
