<template>
  <v-card
    class="fz-14 mb-5 overflow-hidden"
    max-width="380px"
    style="box-shadow: 0 0 0 1px rgba(6,44,82,0.1), 0 1px 3px 0 rgba(64,66,69,0.12), 0 2px 16px 0 rgba(33,43,54,0.08);"
    tile
  >
    <v-list class="pb-0" v-if="$store.state.auth.currentUser">
      <v-list-item-title class="pt-2 pb-4 px-4 text-uppercase fz-12 font-weight-semi-bold">
        Assigned mailboxes
      </v-list-item-title>

      <template v-for="(item, index) in assignedMailboxes">
        <v-divider
          v-if="index"
          :key="index + 'v-divider--assigned'"
        ></v-divider>

        <v-list-item :key="index" class="py-0 px-4">
          <v-list-item-content class="py-2">
            <v-list-item-title class="fz-14 font-weight-bold">
              {{ item.title }}
            </v-list-item-title>

            <v-list-item-subtitle
              class="py-1"
              style="color: #637381;"
            >
              {{ item.text }}
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </template>
    </v-list>
  </v-card>
</template>

<script>
export default {
  data () {
    return {
      assignedMailboxes: [
        {
          title: 'Design mailbox',
          text: '1 unread message'
        },
        {
          title: 'Support mailbox',
          text: '2 unread messages'
        }
      ]
    }
  }
}
</script>

<style scoped>

</style>
