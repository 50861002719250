<template>
  <v-btn
    class="grey-button pointer px-2"
    height="30px"
    @click="onClickFile"
  >
    <v-icon
      size="20px"
      color="#637381"
      style="transform: rotate(45deg)"
      class="mr-1"
    >
      attach_file
    </v-icon>
    Select file
    <input
      :multiple="false"
      type="file"
      style="display: none"
      ref="onClickFile"
      accept="image/*"
      @change="onClickFileLoad"
    />
  </v-btn>
</template>

<script>
export default {
  name: 'FileInput',
  data: () => ({
    uploadedFiles: []
  }),
  methods: {
    onClickFile () {
      this.$refs.onClickFile.click()
    },
    onClickFileLoad (event) {
      console.log(event)
      if (this.uploadedFiles[0]) {
        this.uploadedFiles = []
      }
      Array.from(event.target.files).forEach(element => this.uploadedFiles.push(element))
      this.$refs.onClickFile.value = ''
      this.$emit('update:filesUploaded', this.uploadedFiles)
    }
  }
}
</script>
