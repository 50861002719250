<template>
  <v-app>
    <v-container fill-height class="pa-0 justify-center">
      <router-view></router-view>
    </v-container>
  </v-app>
</template>

<script>
export default {
  name: 'EmptyLayout'
}
</script>
