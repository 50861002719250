import 'material-design-icons-iconfont/dist/material-design-icons.css'
import '@mdi/font/css/materialdesignicons.css'
import 'font-awesome/css/font-awesome.min.css'
import Vue from 'vue'
import Vuetify from 'vuetify/lib'

Vue.use(Vuetify)

export default new Vuetify({
  icons: {
    iconfont: 'mdi' || 'mdiSvg' || 'md' || 'fa' || 'fa4' || 'faSvg'
  },
  theme: {
    themes: {
      light: {
        primary: '#5C6AC4',
        secondary: '#b0bec5',
        accent: '#8c9eff',
        error: '#b71c1c',
        link: '#007ACE',
        'regular-text': '#212B36',
        colors: {
          black: '#000000'
        }
      }
    }
  }
})
