import Vue from 'vue'
import moment from 'moment'

export * from './fake-backend'
export * from './role'
export * from './arrayBufferBase64'

Vue.mixin({
  methods: {
    formatDate: function (val, format = 'YYYY-MM-DD HH:mm:ss') {
      return val ? moment(val).format(format) : ''
    },
    formatDateOnly: function (val, format = 'YYYY-MM-DD') {
      return val ? moment(val).format(format) : ''
    },
    formatDateAmPm: function (val, format = 'DD MMM YYYY|HH:mm A') {
      return val ? moment(val).format(format) : ''
    },
    IsNumber (event) {
      if (!/\d/.test(event.key)) return event.preventDefault()
    },
    toHourMinSec (value) {
      const hh = Math.floor(value / 60 / 60)
      const mm = Math.floor(value / 60) - hh * 3600
      const ss = Math.floor(value % 60)
      return `${hh ? hh + ':' : '0'}${mm ? mm + ':' : '0:'}${ss < 10 ? '0' + ss : ss}`
    },
    formatPickerDate (date) {
      return moment(date).format('YYYY-MM-DD')
    },
    onlyAlphanumeric (event) {
      if ((!event.target.selectionStart && event.key === ' ') || !/^[A-Za-z\d '-]+$/.test(event.key)) return event.preventDefault()
    }
  }
})
