<template>
  <transition>
    <v-card
      elevation="0"
      color="#ED6347"
      style="border: 2px solid #FDAC9A; margin-top: 145px"
      class="br-3 pa-5"
      max-width="320px"
      min-height="124px"
    >
      <div class="fz-14 white--text d-flex align-center">
        <v-btn
          icon
          @click="stopRecording"
          class="mr-1"
        >
          <v-icon size="24px" color="#fff">stop</v-icon>
        </v-btn>

        This call is recording. Click to stop.
      </div>

      <v-card
        class="record-chart transparent"
        elevation="0"
        height="44px"
        width="100%"
      >

      </v-card>
    </v-card>
  </transition>
</template>

<script>
export default {
  name: 'HistoryIncomingCallRecord',
  props: {
    recordingStart: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    record: []
  }),
  methods: {
    stopRecording () {
      console.log('stop')
    }
  }
}
</script>

<style scoped>

</style>
