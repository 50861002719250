import axios from 'src/_services/axios-configs'

const state = {
  phoneNumbers: [],
  didCount: 0,
  extensions: []
}

const getters = {
  phoneNumbers: s => s.phoneNumbers,
  didCount: s => s.didCount,
  extensions: s => s.extensions
}

const mutations = {
  setPhoneNumbers (state, payload) {
    state.phoneNumbers = payload.didList
    state.didCount = payload.total
  },
  setExtensions (state, payload) {
    state.extensions = payload
  }
}

const actions = {
  numberStateLIst () {
    const url = '/public/list/state/US'

    return new Promise((resolve, reject) => {
      axios.get(url)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err.response.data)
        })
    })
  },
  didNumbersList ({ commit }, queries) {
    return new Promise((resolve, reject) => {
      const url = '/did/list'

      axios.get(url, { params: queries })
        .then(res => {
          commit('setPhoneNumbers', res.data)
          resolve(res)
        })
        .catch(err => {
          reject(err.response.data)
        })
    })
  },
  getExtensions ({ commit }, params = {}) {
    return new Promise((resolve, reject) => {
      const url = this.state.auth.currentUser.role === 'account_admin' ? '/account/extension/list' : '/user/extension/list'
      axios.get(url, { params: params })
        .then(res => {
          commit('setExtensions', res.data.extensions)
          resolve(res)
        })
        .catch(err => {
          reject(err.response.data)
        })
    })
  },
  releaseNumber (_, payload) {
    return new Promise((resolve, reject) => {
      const url = '/did/release'

      axios.delete(url, { data: payload })
        .then(res => {
          resolve(res.data)
        })
        .catch(err => {
          reject(err.response.data)
        })
    })
  },
  extendNumber (_, payload) {
    return new Promise((resolve, reject) => {
      const url = '/payments/extend-did-number'

      axios.post(url, { ...payload })
        .then(res => {
          resolve(res.data)
        })
        .catch(err => {
          reject(err.response.data)
        })
    })
  },
  fetchNumbersList (_, payload) {
    return new Promise((resolve, reject) => {
      const url = '/did/search'
      const data = {
        type: payload.type,
        country: payload.country,
        state: payload.state,
        npa: payload.npa,
        features: payload.features
      }

      axios.post(url, data)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err.response.data)
        })
    })
  },
  fetchAvailableNumbersList (_, params = {}) {
    return new Promise((resolve, reject) => {
      const url = '/did/get_available_numbers'

      axios.post(url, {}, { params, timeout: 100000 })
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err.response.data)
        })
    })
  },
  fetchTotalAmount (_, payload) {
    return new Promise((resolve, reject) => {
      const url = '/did/price'
      const data = {
        durationUnit: payload.durationUnit,
        didNumbers: payload.numbers
      }

      axios.post(url, data)
        .then(res => {
          resolve(res.data)
        })
        .catch(err => {
          reject(err.response.data)
        })
    })
  },
  orderNewNumber (_, data) {
    return new Promise((resolve, reject) => {
      const url = '/payments/create-payment'

      axios.post(url, data)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err.response.data)
        })
    })
  },
  buyNewNumber (_, payload) {
    return new Promise((resolve, reject) => {
      const url = '/payments/buy-did-number'
      const data = {
        paymentType: payload.paymentType,
        orderUuid: payload.orderUuid,
        durationUnit: payload.durationUnit
      }

      axios.post(url, data)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err.response.data)
        })
    })
  },
  assignFreeNumber (_, data) {
    return new Promise((resolve, reject) => {
      const url = '/did/free_number/assign'

      axios.post(url, data)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err.response.data)
        })
    })
  },
  upgradeNumberPaid (_, data) {
    return new Promise((resolve, reject) => {
      const url = '/payments/upgrade/number/paid'

      axios.post(url, data)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err.response.data)
        })
    })
  },
  blockNumber (_, body) {
    return new Promise((resolve, reject) => {
      const url = '/blacklist'

      axios.post(url, body)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err.response.data)
        })
    })
  },
  unblockNumber (_, id) {
    return new Promise((resolve, reject) => {
      const url = `/blacklist/${id}`

      axios.delete(url)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err.response.data)
        })
    })
  },
  addCallflowToDid (_, { didUuid, callFlowUuid }) {
    return new Promise((resolve, reject) => {
      if (!didUuid || !callFlowUuid) return
      const url = `/did/${didUuid}/callflow/${callFlowUuid}`

      axios.put(url)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err.response.data)
        })
    })
  },
  changeCallflowInDid (_, { didUuid, callFlowUuid }) {
    return new Promise((resolve, reject) => {
      if (!didUuid || !callFlowUuid) return
      const url = `/did/${didUuid}/callflow/${callFlowUuid}/reasign`

      axios.patch(url)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err.response.data)
        })
    })
  },
  removeCallflowFromDid (_, { didUuid }) {
    return new Promise((resolve, reject) => {
      if (!didUuid) return
      const url = `/did/${didUuid}/callflow`

      axios.delete(url)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err.response.data)
        })
    })
  },
  assignNumberToMember (_, { userId, didUuid }) {
    return new Promise((resolve, reject) => {
      const url = `/did/asign/${userId}/${didUuid}`

      axios.patch(url)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err.response.data)
        })
    })
  },
  releaseUserNumber (_, { userId, didUuid }) {
    return new Promise((resolve, reject) => {
      const url = `/did/release/${userId}/${didUuid}`

      axios.delete(url)
        .then(res => {
          resolve(res.data)
        })
        .catch(err => {
          reject(err.response.data)
        })
    })
  }
}

export default { state, getters, mutations, actions }
