<template>
  <v-overlay :value="value" z-index="101" class="fill-width dial-overlay">
    <v-container class="mb-md-0 pb-md-0 pb-16 keypad-container">
      <v-row class="d-flex justify-md-end justify-center mb-md-0" ref="container">
<!--        <v-col lg="7" md="6" class="d-none d-md-block">
          <v-card
            :loading="listLoading"
            :height="isKeypadHeight"
            max-height="700px"
            ref="left"
            class="mr-2 ml-auto overflow-auto"
          >
            <v-list color="white" height="700px">
              <template v-for="(user, userIndex) in userList">
                <v-list-item :key="user.id" class="py-2">
                  <v-list-item-avatar
                    width="50px"
                    height="50px"
                  >
                    <v-img
                      :src="'https://picsum.photos/200' + '?random=' + userIndex"
                      :alt="`${user.firstName} avatar`"
                    />
                  </v-list-item-avatar>

                  <v-list-item-content>
                    <v-list-item-title class="dark-text font-weight-medium fz-14">
                      {{ fullName(user.firstName, user.lastName) }}
                    </v-list-item-title>
                    <v-list-item-subtitle class="grey&#45;&#45;text text&#45;&#45;darken-1 fz-14">
                      {{ user.phoneNumber }}
                    </v-list-item-subtitle>
                  </v-list-item-content>

                  <v-list-item-action class="flex-row align-center">
                    <v-btn
                      :to="{ name: 'Chat', params: { isCalling: true }}"
                      class="grey-button px-3 mr-3"
                      min-width="0"
                      active-class="no-highlighted-link"
                      @click="clickButton(user)"
                    >
                      <v-icon size="20px" color="#919EAB" class="mr-2">phone</v-icon>
                      Call
                    </v-btn>

                    &lt;!&ndash; <v-btn
                      class="grey-button px-3"
                      min-width="0"
                      active-class="no-highlighted-link"
                      to="/chat"
                      @click="clickButton"
                    >

                      <v-icon size="20px" color="#919EAB">message</v-icon>
                    </v-btn> &ndash;&gt;
                  </v-list-item-action>
                </v-list-item>

                <v-divider :key="userIndex" light></v-divider>
              </template>
            </v-list>
          </v-card>

        </v-col>-->
        <v-col lg="auto" md="auto" class="d-flex justify-center d-md-block">
          <div class="text-right text-md-start">
            <v-card
              :height="isKeypadHeight"
              width="100%"
              max-width="400px"
              min-width="400px"
              max-height="700px"
              color="white"
              ref="right"
              class="keypad-block d-flex flex-column justify-space-between"
            >

              <v-text-field
                v-model.lazy="keypad.phoneNumber"
                :rules="phoneNumberRules"
                ref="phoneNumber"
                type="text"
                hide-details
                light
                :maxlength="maxlength"
                :minlength="minlength"
                class="v-text-field--keypad ma-0 pa-0 mx-4"
                color="#212B36"
                @input="phoneFieldFilter($event)"
                @keypress="onlyNumbers($event)"
                @paste.prevent="onPasteNumber"
              ></v-text-field>

              <div class="pa-4 pt-0 d-flex justify-space-between flex-wrap numbers-block"
              >
                <template v-for="(item, itemIndex) in keypad.numbers">
                  <div class="v-btn--wrapper ma-3" :key="itemIndex">
                    <v-btn
                      width="70"
                      height="70"
                      class="v-btn--keypad grey-button rounded-circle"
                      :class="!itemIndex ? 'align-start pt-3' : ''"
                      style="background: #F4F6F8;border: none;"
                      @click="typeKeypad(item.num, item.word)"
                    >
                      <span class="fz-28 font-weight-bold dark-text text-number">{{ item.num }}</span>
                      <span class="fz-18 text-uppercase grey--text text--darken-1 text-letter">
                        <template v-for="(word, wordIndex) in item.word">
                          <span :key="wordIndex">{{ word }}</span>
                        </template>
                      </span>
                    </v-btn>
                  </div>
                </template>
              </div>

              <div
                class="d-flex justify-space-between align-center px-6 pb-8"
              >
                <v-btn
                  class="grey-button rounded-circle mx-2 event-button"
                  width="70px"
                  height="70px"
                  :to="{ name: 'NewContact', params: keypad.phoneNumber ? { number: keypad.phoneNumber } : {} }"
                  :ripple="false"
                  @click="keypadButton()"
                >
                  <v-icon size="36px" color="#919EAB">person_add</v-icon>
                </v-btn>

                <v-btn
                  v-if="showCallButton"
                  class="grey-button rounded-circle mx-2 call-button"
                  width="80px"
                  height="80px"
                  :style="{background: (isValidNumber) ? '#2BC259' : 'grey', border: 'none'}"
                  @click="clickButton()"
                >
                  <v-icon size="54px" color="#FFF" class="call-button_icon">phone</v-icon>
                </v-btn>

                <v-btn
                  :to="{ name: 'Chat', params: { isNew: true, isNewDetails: { phone: keypad.phoneNumber } } }"
                  disabled
                  class="grey-button rounded-circle mx-2 event-button"
                  width="70px"
                  height="70px"
                  active-class="no-highlighted-link"
                  style="opacity:0"
                  @click="clickButton"
                >
                  <v-icon size="40px" color="#819EAB">message</v-icon>
                </v-btn>
              </div>
            </v-card>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </v-overlay>
</template>

<script>
import {
  getSessionById,
  getSessionByPhoneNumber,
  sendDTMF,
  OUTGOING_REQUEST_NAME
} from '../utilities/sessionManager'

export default {
  name: 'Keypad',
  props: {
    value: {
      default: false,
      type: Boolean
    }
  },
  data: () => ({
    userList: [],
    listLoading: false,
    inputNumber: null,
    maxlength: 11,
    minlength: 10,
    numberFilterList: ['2', '3', '4', '5', '6', '7', '8', '9'],
    keypad: {
      phoneNumber: '',
      numbers: [
        {
          num: '1',
          word: []
        },
        {
          num: '2',
          word: ['a', 'b', 'c']
        },
        {
          num: '3',
          word: ['d', 'e', 'f']
        },
        {
          num: '4',
          word: ['g', 'h', 'i']
        },
        {
          num: '5',
          word: ['j', 'k', 'l']
        },
        {
          num: '6',
          word: ['m', 'n', 'o']
        },
        {
          num: '7',
          word: ['p', 'q', 'r', 's']
        },
        {
          num: '8',
          word: ['t', 'u', 'v']
        },
        {
          num: '9',
          word: ['w', 'x', 'y', 'z']
        },
        {
          num: '*',
          word: []
        },
        {
          num: '0',
          word: []
        },
        {
          num: '#',
          word: []
        }
      ]
    },
    phoneNumberRules: [
      // v => (v && v.length >= 10) || 'Phone number must be more than 10 characters',
      // v => (v && v.length <= 12) || 'Phone number must be less than 11 characters'
    ]
  }),
  computed: {
    keypadPhoneNumber () {
      return this.keypad.phoneNumber
    },
    getPhoneNumber () {
      return this.$store.state.components.callStatus.phone
    },
    getCall () {
      return this.$store.state.components.calls.find(call => +call.phoneNumber === +this.getPhoneNumber)
    },
    getCallId () {
      return this.getCall?.id
    },
    showCallButton () {
      if (this.$store.state.components.calls.length === 0) {
        return true
      }
      return this.$store.state.components.calls.every(call => {
        if (call.type === OUTGOING_REQUEST_NAME) {
          return call.hold
        }
        return call.mute
      })
    },
    isValidNumber () {
      return (this.keypad.phoneNumber.length >= 10 && this.keypad.phoneNumber.length < 12)
    },
    isKeypadHeight () {
      const screenHeight = window.screen.height

      return screenHeight < 700
        ? screenHeight - 100
        : screenHeight - 150
    }
  },
  watch: {
    keypadPhoneNumber (value, oldValue) {
      if (value && (value.length > oldValue.length || !oldValue) && this.getCallId) {
        const session = getSessionById(this.getCallId)
        sendDTMF(session, value.slice(-1))
      }
    },
    value (val) {
      if (!val) this.keypad.phoneNumber = ''
    }
  },
  created () {
    // this.getUsersList()
  },
  methods: {
    isSessionExist () {
      return getSessionByPhoneNumber(this.keypad.phoneNumber)
    },
    keypadButton () {
      this.$store.dispatch('components/keypadStatus', false)
    },
    fullName (firstname, lastname) {
      return firstname + ' ' + lastname
    },
    close () {
      this.overlay = false
    },

    async clickButton (user = null) {
      if (this.isSessionExist()) {
        this.keypadButton()
        return 1
      }
      const valid = this.$refs.phoneNumber.validate()

      const callOption = {
        phone: this.keypad.phoneNumber,
        status: true
      }
      if (this.isValidNumber) {
        await this.$store.dispatch('components/callModalStatus', callOption)
      }
    },
    async getUsersList () {
      this.listLoading = true

      await this.$store
        .dispatch('getContacts')
        .then((res) => {
          this.userList = res.data.contacts

          this.listLoading = false
        }).catch(err => {
          console.log('err', err)
          throw new Error(err)
        })
    },
    typeKeypad (number, words) {
      if (this.keypad.phoneNumber === '' && this.numberFilterList.indexOf(number) !== -1) {
        this.maxlength = 10
        this.minlength = 10
      }
      // eslint-disable-next-line no-mixed-operators
      if (number === '0' && this.keypad.phoneNumber === '' || this.keypad.phoneNumber.length >= this.maxlength) {
        return
      }
      const refPhone = this.$refs.phoneNumber.$refs.input
      const caretIndex = refPhone?.selectionStart
      if (this.keypad.phoneNumber.length === caretIndex) {
        this.keypad.phoneNumber += number
      } else {
        this.keypad.phoneNumber = this.keypad.phoneNumber.slice(0, caretIndex) + number + this.keypad.phoneNumber.slice(caretIndex)
      }
      const index = caretIndex + 1
      this.$nextTick(() => {
        refPhone.setSelectionRange(index, index)
        refPhone.focus()
      })
    },
    phoneFieldFilter (value) {
      if (value === '1' && this.numberFilterList.indexOf(value) === -1) {
        this.maxlength = 11
        this.minlength = 11
      } else if (this.numberFilterList.indexOf(value) !== -1) {
        this.maxlength = 10
        this.minlength = 10
      }

      if (value === '0' && this.keypad.phoneNumber === '0') {
        setTimeout(() => { this.keypad.phoneNumber = '' }, 1)
      }

      this.keypad.phoneNumber = value
    },
    onPasteNumber (evt) {
      let filter = evt.clipboardData.getData('text').replace(/[^\d]/g, '')

      if (filter[0] === '1') {
        filter = filter.substring(0, 11)
      } else if (filter[0] === '2') {
        filter = filter.substring(0, 10)
      } else {
        return
      }

      this.keypad.phoneNumber = filter
    },
    onlyNumbers (event) {
      if (event.key === '0' && this.keypad.phoneNumber === '0') this.keypad.phoneNumber = ''

      const keyCode = event.keyCode ? event.keyCode : event.which
      if (keyCode < 48 || keyCode > 57) {
        event.preventDefault()
      }
    }
  },
  updated () {
    // if (this.value) {
    //   if (this.$refs.container.clientHeight / this.$refs.right.$el.clientHeight < 1) {
    //     this.$refs.right.$el.style.zoom = this.$refs.container.clientHeight / this.$refs.right.$el.clientHeight
    //   }
    //   this.$refs.left.$el.style.height = this.$refs.right.$el.clientHeight
    // }
  }
}
</script>

<style lang="scss" scoped>
.keypad-container {
  margin-right: 6%;
}

.v-text-field--keypad {
  max-height: 90px;
}

@media screen and (max-width: 575px) {
  .v-btn--keypad {
    width: 70px !important;
    height: 70px !important;

    .text-number {
      font-size: 38px !important;
    }

    .text-letter {
      font-size: 12px !important;
    }
  }

  .keypad-block {
    min-width: unset !important;
    height: max-content !important;
  }

  .event-button {
    width: 60px !important;
    height: 60px !important;
  }

  .call-button {
    width: 80px !important;
    height: 80px !important;

    &_icon {
      font-size: 54px !important;
    }
  }
}

@media screen and (max-width: 375px) {
  .keypad-block {
    max-width: 100% !important;
  }

  .v-btn--keypad {
    width: 60px !important;
    height: 60px !important;
    min-width: 60px !important;

    .text-number {
      font-size: 28px !important;
    }

    .text-letter {
      font-size: 10px !important;
    }
  }

  .numbers-block {
    div {
      margin: 10px;
    }
  }
}
</style>
