<template>
  <transition>
    <v-card
      class="fz-14 mb-5 overflow-hidden v-card--user-information position-relative margin-top"
      max-width="380px"
      max-height="calc(100vh - 231px)"
      height="100%"
      style="box-shadow: 0 0 0 1px rgba(6,44,82,0.1), 0 1px 3px 0 rgba(64,66,69,0.12), 0 2px 16px 0 rgba(33,43,54,0.08);"
      tile
    >
      <v-row class="my-0">
        <v-col
          cols="12"
          md="12"
          class="pa-0"
        >
          <v-card-title
            class="px-7 pb-2 fz-12 text-uppercase font-weight-bold dark-text"
            style="line-height: 16px;"
          >
            Recent
          </v-card-title>

          <v-list
            max-height="210px"
            class="pb-0 px-3 overflow-y-auto"
          >
            <template v-for="(user, index) in recentUsers">
              <v-list-item :key="index + user.firstname">
                <v-list-item-avatar
                  width="40px"
                  height="40px"
                  class="mr-2"
                >
                  <v-img
                    :alt="`${user.firstname} avatar`"
                    :src="user.avatar + '?random=' + index"
                  ></v-img>
                </v-list-item-avatar>

                <v-list-item-title
                  class="fz-14 font-weight-medium"
                  style="color: #1D2428; letter-spacing: -0.15px"
                >
                  {{ fullName(user.firstname, user.lastname) }}
                </v-list-item-title>

                <v-list-item-action
                  style="min-width: unset;"
                >
                  <v-tooltip top>
                    <template v-slot:activator="{ on }">
                  <v-btn
                    v-on="on"
                    class="grey-button px-3"
                    @click="add(index)"
                    min-width="44px"
                  >
                    <v-icon
                      size="20px"
                      color="#919EAB"
                    >
                      person_add
                    </v-icon>
                    <span class="d-md-none d-lg-block ml-2">Add to call</span>
                  </v-btn>
                    </template>
                    <span>Add to call</span>
                  </v-tooltip>
                </v-list-item-action>
              </v-list-item>

              <v-divider :key="index"></v-divider>
            </template>
          </v-list>
        </v-col>

        <v-card-title
          class="px-7 pb-0 pt-5 fz-12 text-uppercase font-weight-bold dark-text"
          style="line-height: 16px;"
        >
          My contacts
        </v-card-title>

        <v-col
          cols="12"
          md="12"
          class="px-7 py-2"
        >
          <v-text-field
            solo
            outlined
            label="Search people, mailboxes"
            type="text"
            v-model="search"
            dense
            hide-details
            style="height: 36px;"
          >
            <template v-slot:prepend-inner>
              <v-icon size="20px" color="#919EAB">search</v-icon>
            </template>
          </v-text-field>
        </v-col>

        <v-col
          cols="12"
          md="12"
          class="py-0"
        >
          <v-list
            max-height="560px"
            width="100%"
            class="pb-0 overflow-y-auto"
          >
            <template v-for="(user, index) in usersList">
              <v-list-item :key="index + user.firstname">
                <v-list-item-avatar
                  width="40px"
                  height="40px"
                  class="mr-2"
                >
                  <v-img
                    :alt="`${user.firstname} avatar`"
                    :src="user.avatar + '?random=' + index"
                  ></v-img>
                </v-list-item-avatar>

                <v-list-item-title
                  class="fz-14 font-weight-medium"
                  style="color: #1D2428; letter-spacing: -0.15px"
                >
                  {{ fullName(user.firstname, user.lastname) }}
                </v-list-item-title>

                <v-list-item-action
                  style="min-width: unset;"
                >
                  <v-tooltip top>
                    <template v-slot:activator="{ on }">
                  <v-btn
                    v-on="on"
                    class="grey-button px-3"
                    @click="add(index)"
                    min-width="44px"
                  >
                    <v-icon
                      size="20px"
                      color="#919EAB"
                    >
                      person_add
                    </v-icon>

                    <span class="d-md-none d-lg-block ml-2">Add to call</span>
                  </v-btn>
                    </template>
                    <span>Add to call</span>
                  </v-tooltip>
                </v-list-item-action>
              </v-list-item>

              <v-divider :key="index"></v-divider>
            </template>
          </v-list>
        </v-col>
      </v-row>
    </v-card>
  </transition>
</template>

<script>
export default {
  name: 'HistoryIncomingCallInvite',
  data: () => ({
    recentUsers: [
      {
        avatar: 'https://picsum.photos/200',
        firstname: 'Rosa',
        lastname: 'Ferguson'
      },
      {
        avatar: 'https://picsum.photos/200',
        firstname: 'Rosa',
        lastname: 'Ferguson'
      },
      {
        avatar: 'https://picsum.photos/200',
        firstname: 'Rosa',
        lastname: 'Ferguson'
      }
    ],
    search: '',
    usersList: [
      {
        avatar: 'https://picsum.photos/200',
        firstname: 'Rosa',
        lastname: 'Ferguson'
      },
      {
        avatar: 'https://picsum.photos/200',
        firstname: 'Rosa',
        lastname: 'Ferguson'
      },
      {
        avatar: 'https://picsum.photos/200',
        firstname: 'Rosa',
        lastname: 'Ferguson'
      },
      {
        avatar: 'https://picsum.photos/200',
        firstname: 'Rosa',
        lastname: 'Ferguson'
      },
      {
        avatar: 'https://picsum.photos/200',
        firstname: 'Rosa',
        lastname: 'Ferguson'
      },
      {
        avatar: 'https://picsum.photos/200',
        firstname: 'Rosa',
        lastname: 'Ferguson'
      },
      {
        avatar: 'https://picsum.photos/200',
        firstname: 'Rosa',
        lastname: 'Ferguson'
      },
      {
        avatar: 'https://picsum.photos/200',
        firstname: 'Rosa',
        lastname: 'Ferguson'
      },
      {
        avatar: 'https://picsum.photos/200',
        firstname: 'Rosa',
        lastname: 'Ferguson'
      },
      {
        avatar: 'https://picsum.photos/200',
        firstname: 'Rosa',
        lastname: 'Ferguson'
      },
      {
        avatar: 'https://picsum.photos/200',
        firstname: 'Rosa',
        lastname: 'Ferguson'
      },
      {
        avatar: 'https://picsum.photos/200',
        firstname: 'Rosa',
        lastname: 'Ferguson'
      }
    ]
  }),
  methods: {
    fullName (firstname, lastname) {
      return firstname + ' ' + lastname
    },
    add (index) {
      console.log(index)
    }
  }
}
</script>
<style scoped lang="scss">
  .margin-top:first-of-type {
    margin-top: 146px !important;
  }
</style>
