<template>
  <div id="app">
    <component :is="layout">
      <router-view/>
    </component>
  </div>
</template>

<script>
import MainLayout from './layouts/MainLayout'
import EmptyLayout from './layouts/EmptyLayout'
import AdminLayout from './layouts/AdminLayout'

export default {
  name: 'App',
  components: {
    MainLayout,
    EmptyLayout,
    AdminLayout
  },
  computed: {
    layout () {
      return (this.$route.meta.layout || 'empty') + '-layout'
    }
  }
}
</script>

<style lang="scss">
  @import "scss/main.scss";
</style>
