<template>
  <v-card
    :width="fullscreen ? 'calc(100% - 16px)' : '316px'"
    :height="fullscreen ? 'calc(100% - 16px)' : '220px'"
    class="br-3"
    :class="fullscreen ? 'v-card--fullscreen' : ''"
    style="margin-top: 145px"
  >
    <v-sheet
      width="100%"
      height="100%"
      color="rgba(33,43,54,0.4)"
    >
      <video src="/HistoryIncomingCallVideo.mp4" width="100%" height="100%" autoplay muted loop></video>
    </v-sheet>

    <v-tooltip bottom content-class="v-tooltip--bottom">
      <template v-slot:activator="{ on, attrs}">
        <v-btn
          absolute
          left
          :bottom="!fullscreen"
          :top="fullscreen"
          icon
          @click="fullscreen = !fullscreen"
          v-bind="attrs"
          v-on="on"
        >
          <v-icon size="24px" color="#FFF">{{ fullscreen ? 'close_fullscreen' : 'open_in_full' }}</v-icon>
        </v-btn>
      </template>

      <span>{{ fullscreen ? 'Fullscreen OFF' : 'Fullscreen ON' }}</span>
    </v-tooltip>

    <div
      v-if="fullscreen"
      :style="fullscreen ? 'position: absolute;bottom: 32px;' : ''"
      class="d-flex align-center justify-center fill-width"
    >
      <v-btn
        text
        color="#FFF"
        class="mx-1 text-none"
      >
        <v-icon size="24px" color="#FFF" class="mr-1">person</v-icon>
        Information
      </v-btn>

      <v-btn
        text
        color="#FFF"
        class="mx-1 text-none"
      >
        <v-icon size="24px" color="#FFF" class="mr-1">radio_button_checked</v-icon>
        Record call
      </v-btn>

      <v-btn
        text
        color="#FFF"
        class="mx-1 text-none"
      >
        <v-icon size="24px" color="#FFF" class="mr-1">send</v-icon>
        Send to
      </v-btn>

      <v-btn
        text
        color="#FFF"
        class="mx-1 text-none"
      >
        <v-icon size="24px" color="#FFF" class="mr-1">play_arrow</v-icon>
        Play message
      </v-btn>

      <v-btn
        text
        color="#ED6347"
        class="mx-1 text-none"
      >
        <v-icon size="24px" color="#ED6347" class="mr-1">call_end</v-icon>
        Hang up
      </v-btn>
    </div>
  </v-card>
</template>

<script>
export default {
  name: 'HistoryIncomingCallVideo',
  data: () => ({
    fullscreen: false
  })
}
</script>
