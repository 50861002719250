// https://github.com/onsip/SIP.js/issues/961
// https://github.com/onsip/SIP.js/blob/main/docs/session-manager.md
// node_modules/sip.js/lib/api/emitter.d.ts
import { SessionManager } from 'sip.js/lib/platform/web'
import { OutgoingRequestMessage } from 'sip.js/lib/core'
import { SIP_SERVER, SIP_SERVER_PORT } from './configSessionManager'

let sm = null
let refreshIntervalId = null
const OUTGOING_REQUEST_NAME = 'OutgoingRequest'
const INCOMING_REQUEST_NAME = 'IncomingRequest'
const smCollector = []

const reconnect = (server, options) => {
  setTimeout(() => {
    connectSIP(server, options)
  }, 5000)
}

const connectSIP = async (server, options) => {
  if (sm && sm.isConnected) {
    console.log('SM exists')
    // await unregisterSIP()
    await disconnectSIP()
  }
  sm = new SessionManager(server, options)
  sm.connect()
    .then(() => {
      console.log('SIP: connected')
      sm.register()
    })
    .catch((error) => {
      console.error('SIP: Failed to connect')
      console.error(error)
      reconnect(server, options)
      // alert('Failed to connect.\n' + error)
    })
}

const updateExtraHeaders = (sipToken) => {
  if (sm && sipToken) {
    sm.options.registererOptions = { extraHeaders: [`Authorization: JWT ${sipToken}`] }
    sm.registererOptions = { extraHeaders: [`Authorization: JWT ${sipToken}`] }
  }
}

const unregisterSIP = async (sipToken) => {
  try {
    const requestOptions = {
      extraHeaders: [
        `Authorization: JWT ${sipToken}`
      ]
    }
    await sm.unregister({ requestOptions })
    console.log('SIP: unregistered')
  } catch (e) {
    console.error(`[${sm.id}] failed to disconnect`)
    console.error(e)
  }
}

const disconnectSIP = async () => {
  try {
    await sm.disconnect()
    console.log('SIP: disconnected')
  } catch (e) {
    console.error(`[${sm.id}] failed to disconnect`)
    console.error(e)
  }
}

const addSession = (session, type, state) => {
  smCollector.push({
    type,
    session,
    state
  })
  // console.log('addSession: ', smCollector)
}
const getOutboundSession = (phone) => {
  const s = smCollector.filter(session => {
    const phoneNumberOfSession = getNumberOfSession(session)
    if (phoneNumberOfSession) {
      return +phoneNumberOfSession === +phone
    }
    return false
  })
  if (s.length !== 0) {
    return s[s.length - 1].session
  }
  return undefined
}
const isOutboundCall = (session) => {
  if (!session) {
    return undefined
  }
  return getSessionType(session) === OUTGOING_REQUEST_NAME
}

const callAnswer = (session) => {
  sm.answer(session)
    .catch(error => {
      console.error(`[${session.id}] failed to answer`)
      console.error(error)
    })
}

const call = (phone) => {
  const target = `sip:${phone}@${SIP_SERVER}`
  console.log('target: ', target)
  sm.call(target, { inviteWithoutSdp: false })
    .catch(() => {
      // console.log(phone)
      // const c = getOutboundSession(phone)
      // console.log('c: ', c)
      // if (c) {
      //   console.error(`[${c.id}] failed to place call`)
      //   console.error(error)
      //   // alert('Failed to place call.\n' + error)
      // }
    })
}

const callMute = async (mute, session) => {
  if (mute) {
    sm.mute(session)
    if (sm.isMuted(session) === false) {
      console.error(`[${session.id}] failed to mute call`)
      alert('Failed to mute call.')
    }
  } else {
    sm.unmute(session)
    if (sm.isMuted(session) === true) {
      console.error(`[${session.id}] failed to unmute call`)
      alert('Failed to unmute call.')
    }
  }
}
const callHold = async (hold, session) => {
  try {
    if (hold) {
      await sm.hold(session)
    } else {
      await sm.unhold(session)
    }
    return 'success'
  } catch (error) {
    console.error(`[${session.id}] failed to ${hold ? 'hold' : 'unhold'} call`)
    console.error(error)
    return 'fail'
  }
}
const callHangup = async (session) => {
  if (session) {
    try {
      await sm.hangup(session)
      return 'success'
    } catch (error) {
      console.error(`[${session.id}] failed to hangup call`)
      console.error(error)
      return 'fail'
    }
  }
}
const sendDTMF = async (session, tone) => {
  if (session) {
    try {
      console.log(`sendDTMF: ${tone}`)
      await sm.sendDTMF(session, tone.toString())
      return 'success'
    } catch (error) {
      console.error(`[${session.id}] failed to sendDTMF `)
      console.error(error)
      return 'fail'
    }
  }
}
const callDecline = async (session) => {
  if (session) {
    try {
      await sm.decline(session)
      return 'success'
    } catch (error) {
      console.error(`[${session.id}] failed to decline call`)
      console.error(error)
      return 'fail'
    }
  }
}
const removeSessionFromCollector = (session) => {
  const index = smCollector.findIndex(item => item.session.id === session.id)
  if (index !== -1) {
    smCollector.splice(index, 1)
  }
  // console.log(smCollector)
}

const getSessionType = (session) => {
  if (!session || session.request === undefined) {
    throw new Error('SIP: session request not found')
  }
  return session.request instanceof OutgoingRequestMessage ? OUTGOING_REQUEST_NAME : INCOMING_REQUEST_NAME
}

const getNumberOfSession = (session) => {
  let type = 'from'
  if (isOutboundCall(session)) {
    type = 'to'
  }
  return session.request[type].uri.user
}

const getSessionById = (id) => {
  if (id === undefined) {
    throw new Error('id does not exist')
  }
  return smCollector.find(s => s.session.id === id)?.session
}
const getSessionByPhoneNumber = (phoneNumber) => {
  if (phoneNumber === undefined) {
    throw new Error('phoneNumber does not exist')
  }
  const s = smCollector.find(s => {
    const _phoneNumber = getNumberOfSession(s.session)
    if (_phoneNumber) {
      return +_phoneNumber === +phoneNumber
    }
    return false
  })
  return s?.session
}

const beep = (isRun) => {
  if (!isRun) {
    clearInterval(refreshIntervalId)
  } else {
    refreshIntervalId = setInterval(() => {
      var context = new AudioContext()
      var oscillator = context.createOscillator()
      oscillator.type = 'sine'
      oscillator.frequency.value = 700
      oscillator.connect(context.destination)
      oscillator.start()
      setTimeout(function () {
        oscillator.stop()
      }, 1300)
    }, 5000)
  }
}

export {
  sm, smCollector,
  OUTGOING_REQUEST_NAME, INCOMING_REQUEST_NAME,
  connectSIP, updateExtraHeaders, unregisterSIP, disconnectSIP,
  addSession, removeSessionFromCollector,
  getSessionType, getNumberOfSession,
  getOutboundSession, isOutboundCall,
  getSessionById, getSessionByPhoneNumber,
  call, callAnswer, callMute, callHold, callHangup, callDecline, sendDTMF,
  SIP_SERVER, SIP_SERVER_PORT,
  beep
}
