<template>
  <transition>
    <div>
      <div class="fz-12 font-weight-semi-bold text-uppercase mb-2">Analytics</div>

      <v-card
        class="overflow-hidden"
        max-width="316px"
        to="/analytics"
      >
        <v-card-title class="text-body-1 px-3 px-lg-4">
          <span class="fz-14">Incoming Calls for numbers</span>
          <v-icon class="ml-auto" size="18" color="#919EAB">chevron_right</v-icon>
        </v-card-title>
        <v-card-text>
          <line-chart :data="analytics.incomingNumbers" :options="options" style="height: 90px"/>
        </v-card-text>
        <v-divider/>
        <v-card-title class="text-body-1 px-3 px-lg-4">
          <span class="fz-14">Incoming Calls for members</span>
          <v-icon class="ml-auto" size="18" color="#919EAB">chevron_right</v-icon>
        </v-card-title>
        <v-card-text>
          <line-chart :data="analytics.incomingMembers" :options="options" style="height: 90px"/>
        </v-card-text>
      </v-card>
    </div>
  </transition>
</template>

<script>
import LineChart from '@/components/LineChart'

export default {
  name: 'Analytics',
  components: { LineChart },
  data: () => ({
    analytics: {
      incomingNumbers: {
        labels: [/* 'Sep 1', 'Sep 2', 'Sep 3', 'Sep 4', 'Sep 5', 'Sep 6', 'Sep 7', 'Sep 8', 'Sep 9', 'Sep 10', 'Sep 11', 'Sep 12' */],
        datasets: [
          {
            backgroundColor: 'transparent',
            borderColor: '#9C6ADE',
            data: [/* 40, 20, 12, 39, 10, 40, 39, 80, 40, 20, 12, 11 */],
            pointHoverRadius: 5,
            pointHoverBackgroundColor: '#9C6ADE'
          }
        ]
      },
      incomingMembers: {
        labels: [/* 'Sep 1', 'Sep 2', 'Sep 3', 'Sep 4', 'Sep 5', 'Sep 6', 'Sep 7', 'Sep 8', 'Sep 9', 'Sep 10', 'Sep 11', 'Sep 12' */],
        datasets: [
          {
            backgroundColor: 'transparent',
            borderColor: '#47C1BF',
            data: [/* 4, 2, 32, 9, 11, 42, 9, 8, 4, 2, 1, 11 */],
            pointHoverRadius: 5,
            pointHoverBackgroundColor: '#47C1BF'
          }
        ]
      }
    },
    options: {
      maintainAspectRatio: false,
      elements: {
        line: {
          tension: 0
        }
      },
      scales: {
        xAxes: [{
          ticks: {
            display: false
          }
        }],
        yAxes: [{
          ticks: {
            display: false
          }
        }]
      },
      legend: {
        display: false
      }
    }
  })
}
</script>
