<template>
  <v-app class="pr-4">
    <HeaderApp v-if="!$route.meta.altHeader"/>
    <audio style="display: none" id="remoteAudio" ref="remoteAudio" controls>
      <p>Your browser doesn't support HTML5 audio.</p>
    </audio>

    <audio style="display: none" id="localAudio" ref="localAudio" controls>
      <p>Your browser doesn't support HTML5 audio.</p>
    </audio>
    <div class="d-flex px-5 py-3 white align-center header-part" :class="{ 'z-10': isMobile }" style="box-shadow:0px 1px 0 0 #b0bec569"> <!-- border-bottom -->
      <v-img
        position="left center"
        :src="require('@/assets/logo-new.svg')"
        height="22"
        width="84px"
        contain
      ></v-img>
      <v-app-bar-nav-icon class="d-md-none hamburger secondary--text"
                          @click.stop="sidebarMenu = !sidebarMenu"></v-app-bar-nav-icon>
    </div>

    <v-navigation-drawer
      v-model="sidebarMenu"
      app
      floating
      :permanent="$vuetify.breakpoint.mdAndUp"
      color="#F4F6F8"
      class="sidebar"
      width="300px"
    >
      <v-list dense :color="$route.meta.altHeader ? '#F9FAFB' : '#00084B'" dark
              :class="{'nav-list-alt': $route.meta.altHeader}">
        <v-list-item>
          <v-row class="ma-0 pa-0">
            <v-col cols="3" class="ma-0 pa-0 d-flex align-center">
              <v-img
                :src="require($route.meta.altHeader ? '@/assets/logo-new.svg' : '@/assets/logo.svg')"
                height="30"
                width="30"
                contain
                class="navbar-logo"
              ></v-img>
            </v-col>
            <v-col cols="9" class="ma-0 pa-0 d-flex align-center">
              <span>Admin portal</span>
            </v-col>
          </v-row>
        </v-list-item>
      </v-list>
      <v-list :class="{ white: isMobile }" width="calc(100% - 1px)">
        <v-list-item v-if="isMobile">
          <v-list-item-content>
            <v-list-item-title class="font-weight-bold fz-22">Menu</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item
          v-for="item in items"
          :key="item.title"
          link
          :to="item.href"
          dense
          active-class="nav-menu-item--active"
          :class="{ 'mt-2': item.gap }"
          class="nav-menu-item"
        >
          <v-list-item-icon class="mr-4">
            <v-icon size="20px">{{ item.icon }}</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title
              :class="isMobile ? 'fz-16 font-weight-regular' : 'fz-16 font-weight-medium'"
              :style="{ color: isMobile ? '#191E24' : '#637381' }"
            >{{ item.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <v-list class="d-md-none">
        <user-menu/>
      </v-list>
    </v-navigation-drawer>
    <v-main class="py-0 my-0 mt-lg-2">
      <v-container class="px-lg-8 px-md-2 px-0 py-0 fill-height d-block" fluid>
        <v-row class="fill-height no-gutters h-full">
          <v-col
            cols="12"
            md="12"
            class="py-0 pr-md-2 px-0"
          >
            <transition name="fade">
              <router-view :key="$route.path"></router-view>
            </transition>
          </v-col>
            <!-- v-if="mainPagesUrls.includes($route.path)" es componentenr@ edit profileum el karan bacven. kod@ nenc gri vor normal ashxati -->
          <!-- <v-col
            cols="12"
            md="4"
            :class="['pt-md-0', {'dashboard-page-class': $route.path === '/'}]"
          >
           </v-col> -->
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import HeaderApp from '@/components/HeaderApp'
import UserMenu from '../components/UserMenu'
import { Role } from '@/_helpers/role'

export default {
  name: 'AdminLayout',
  components: {
    UserMenu,
    HeaderApp
  },
  data: () => ({
    sidebarMenu: true,
    items: [
      {
        title: 'Company',
        href: '/admin',
        icon: 'person',
        roles: [Role.SuperAdmin]
      },
      {
        title: 'Invoice',
        href: '/admin-invoice',
        icon: 'payment',
        roles: [Role.SuperAdmin]
      },
      {
        title: 'Payment',
        href: '/admin-payment',
        icon: 'payments',
        roles: [Role.SuperAdmin]
      },
      {
        title: 'Number',
        href: '/admin-number',
        icon: 'sim_card',
        roles: [Role.SuperAdmin]
      }
    ]
  }),
  computed: {
    ...mapGetters(['currentUser']),
    isMobile () {
      return this.$vuetify.breakpoint.smAndDown
    }
  },
  created () {
    if (this.currentUser.role !== Role.SuperAdmin) this.$router.push({ name: 'Home' })
  }
}
</script>

<style scoped lang="scss">
  .hamburger {
    position: sticky;
    top: 10px;
    left: 9px;
    z-index: 101;
  }

  .border-bottom {
    border-bottom: 1px solid rgba(176, 190, 197, 0.41) !important;
  }

  .mt-230 {
    @include media-breakpoint-up(md) {
      margin-top: 230px !important;
    }
  }

  .header-part {
    border-bottom: 1px solid  #EDEDED;
  }

  .z-10 {
    z-index: 10;
  }

  .nav-menu-subitem {
    height: unset !important;
    min-height: unset !important;
    justify-content: flex-start;
  }

  /* .navbar-logo ::v-deep * {
    width: 25px !important;
  } */

  @media screen and (max-width: 575px){
    .dashboard-page-class {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding-top: 0px !important;
    }
  }
</style>
