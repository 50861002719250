import axios from 'src/_services/axios-configs'

const state = {
  accounts: []
}

const getters = {
}

const mutations = {
  setAccounts (state, accounts) {
    state.accounts = accounts
  }
}

const actions = {
  setAccounts ({ commit }, accounts) {
    commit('setAccounts', accounts)
  },
  getAccounts ({ dispatch }) {
    return new Promise((resolve, reject) => {
      axios.get('/account/users_accounts/list')
        .then(res => {
          dispatch('setAccounts', res.data)
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  switchAccount ({ dispatch }, uuid) {
    return new Promise((resolve, reject) => {
      axios.post(`/auth/switch_account/${uuid}`)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  }
}

export default { state, getters, mutations, actions }
