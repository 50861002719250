import axios from 'src/_services/axios-configs'

const state = {
  smsCampaign: []
}

const getters = {
}

const mutations = {

}

const actions = {
  getSmsCampaign () {
    const url = '/sms/campaign'

    return new Promise((resolve, reject) => {
      axios.get(url)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err.response.data)
        })
    })
  },
  createCampaign (_, payload) {
    const url = '/payments/create-campaign'

    return new Promise((resolve, reject) => {
      axios.post(url, payload)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err.response.data)
        })
    })
  }
}

export default { state, getters, mutations, actions }
