<template>
  <v-overlay z-index="100" class="fill-width ml-auto dial-overlay dial-overlay-incoming">
    <v-container class="mb-16 mb-md-0 pb-md-0 pb-16">
      <v-row class="ml-auto d-flex justify-md-end justify-center mb-16 mb-md-0" ref="container">
        <v-col
            cols="12"
            sm="3"
            class="d-flex justify-center align-center py-10"
            style="position: relative; background: white; max-width: 300px;"
        >
          <div class="d-flex flex-column align-center">
            <div class="mb-16">
              <v-img
                  :src="require('../assets/call-avatar.svg')"
                  contain
                  width="72"
                  height="72"
                  max-width="min-content"
                  class="mx-auto"
              />
              <v-list class="white">
                <v-list-item class="theme--light white">
                  <v-list-item-content class="text-center">
                    <v-list-item-title class="font-weight-bold">
                      {{ phone }}
                    </v-list-item-title>
                    <v-list-item-subtitle
                        v-if="!callIsActive"
                        class="mt-4 caption"
                        style="color: rgba(0, 0, 0, .87)"
                    >
                      Call
                    </v-list-item-subtitle>
                    <v-list-item-subtitle v-else class="mt-4 caption" style="color: rgba(0, 0, 0, .87)">
                      {{ timeDisplay }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </div>

            <v-row style="max-width: 250px">
              <v-col
                  cols="6"
                  sm="6"
                  class="d-flex flex-column align-center pa-0 mb-8"
              >
                <div>
                  <v-img
                      style="cursor: pointer"
                      :src="require('../assets/call-message.svg')"
                      contain
                      width="72"
                      height="72"
                      max-width="min-content"
                      class="mx-auto"
                  />
                </div>
                <v-list-item-subtitle class="text-center caption mt-3 text--black"
                >Message
                </v-list-item-subtitle>
              </v-col>
              <v-col
                  cols="6"
                  sm="6"
                  class="d-flex flex-column align-center pa-0 mb-8"
              >
                <div>
                  <v-img
                      @click="muteCall()"
                      :style="mutedDisabled ? 'pointer-events: none' : 'cursor: pointer' "
                      :src="
                    require(`../assets/${
                      mute ? 'call-mute-active.svg' : 'call-mute.svg'
                    }`)
                  "
                      contain
                      width="72"
                      height="72"
                      max-width="min-content"
                      class="mx-auto"
                  />
                </div>
                <v-list-item-subtitle class="text-center caption mt-3"
                >Mute
                </v-list-item-subtitle>
              </v-col>
              <v-col
                  cols="6"
                  sm="6"
                  class="d-flex flex-column align-center pa-0 mb-8"
              >
                <div>
                  <v-img
                      :style="`${
                    holdDisable
                      ? 'cursor: no-drop; pointer-events: none'
                      : 'cursor: pointer; pointer-events: inherit'
                  }`"
                      :src="
                    require(`../assets/${
                      hold ? 'call-hold.svg' : 'call-hold-active.svg'
                    }`)
                  "
                      @click="holdCall()"
                      contain
                      width="72"
                      height="72"
                      max-width="min-content"
                      class="mx-auto"
                  />
                </div>

                <v-list-item-subtitle class="text-center caption mt-3"
                >Hold
                </v-list-item-subtitle>
              </v-col>
              <v-col
                  cols="6"
                  sm="6"
                  class="d-flex flex-column align-center pa-0 mb-8"
              >
                <div>
                  <v-img
                      style="cursor: pointer"
                      :src="require('../assets/call-keypad.svg')"
                      contain
                      width="72"
                      height="72"
                      max-width="min-content"
                      class="mx-auto"
                  />
                </div>
                <v-list-item-subtitle class="text-center caption mt-3"
                >Keypad
                </v-list-item-subtitle>
              </v-col>
            </v-row>

            <!-- call answer hangup -->
            <div class="mt-16 w-100 d-flex">
              <v-img
                  @click="hangup()"
                  style="cursor: pointer"
                  :src="require('../assets/call-end.svg')"
                  contain
                  width="72"
                  height="72"
                  max-width="min-content"
                  class="mx-auto"
              />
<!--              <v-img
                  v-if="!callPick"
                  @click="callAnswerFunc()"
                  style="cursor: pointer"
                  :src="require('../assets/call-pick.svg')"
                  contain
                  width="72"
                  height="72"
                  max-width="min-content"
                  class="mx-auto"
              />-->
            </div>

          </div>
        </v-col>
      </v-row>
    </v-container>
  </v-overlay>
</template>

<script>
import moment from 'moment'

export default {
  name: 'IncomingCallModal',
  props: {
    value: {
      default: false,
      type: Boolean
    }
  },
  data: () => ({
    phone: '',
    checkDevice: true,
    // callPick: false,
    callIsActive: false,
    mute: false,
    mutedDisabled: true,
    hold: false,
    holdDisable: true,
    startTimer: false,
    time: moment()
      .utcOffset(0, true)
      .hour(0)
      .minute(0)
      .second(0)
      .millisecond(0)
  }),
  computed: {
    isKeypadHeight () {
      const screenHeight = window.screen.height

      return screenHeight < 700
        ? screenHeight - 100
        : screenHeight - 150
    },
    timeDisplay () {
      return this.time.format('mm:ss')
    }
  },
  watch: {
    '$store.state.components.timerStatus': function (callData) {
      this.startTimer = callData
      this.callIsActive = callData
      this.mutedDisabled = false
      this.holdDisable = false
    },
    '$store.state.components.mute': function (siMuted) {
      this.mute = siMuted
      this.mutedDisabled = false
    },
    '$store.state.components.hold': function (isHold) {
      this.hold = isHold
      this.holdDisable = false
      console.log('this.holdDisable', isHold)
    },
    startTimer (val) {
      if (val) {
        this.timer = setInterval(() => {
          this.time = moment(this.time.add(1, 'seconds'))
        }, 1000)
      } else {
        this.time = moment()
          .utcOffset(0, true)
          .hour(0)
          .minute(0)
          .second(0)
          .millisecond(0)
        clearInterval(this.timer)
      }
    }
  },
  created () {},
  methods: {
    holdCall () {
      this.hold = !this.hold
      this.$store.dispatch('components/hold', this.hold)
    },
    muteCall () {
      if (this.mute) {
        this.$store.dispatch('components/mute', false)
        this.mute = false
      } else {
        this.$store.dispatch('components/mute', true)
        this.mute = true
      }
    },
    hangup () {
      this.$store.dispatch('components/callAnswered', false)
      this.$store.dispatch('components/callIncomingModalStatus', { phone: '', status: false })
      // this.$store.dispatch('components/startTimerEvent', false)
      this.$store.dispatch('components/callIncomingBoxStatus', false)
      this.startTimer = false
    },
    // callAnswerFunc () {
    //   this.$store.dispatch('components/callAnswered', true)
    //   // this.callPick = true
    // },
    keypadButton () {
      this.$store.dispatch('components/keypadStatus', false)
    },
    checkIsDeviceEnableOnPC () {
      navigator.mediaDevices.getUserMedia({ video: false, audio: true }).then((stream) => {
        this.checkDevice = true
      }).catch((err) => {
        this.checkDevice = false
        console.error(`you got an error: ${err}`)
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.keypad-container {
  margin-right: 6%;
}

.v-text-field--keypad {
  max-height: 90px;
}

@media screen and (max-width: 575px) {
  .v-btn--keypad {
    width: 70px !important;
    height: 70px !important;

    .text-number {
      font-size: 38px !important;
    }

    .text-letter {
      font-size: 12px !important;
    }
  }

  .keypad-block {
    min-width: unset !important;
    height: max-content !important;
  }

  .event-button {
    width: 60px !important;
    height: 60px !important;
  }

  .call-button {
    width: 80px !important;
    height: 80px !important;

    &_icon {
      font-size: 54px !important;
    }
  }
}

@media screen and (max-width: 375px) {
  .keypad-block {
    max-width: 100% !important;
  }

  .v-btn--keypad {
    width: 60px !important;
    height: 60px !important;
    min-width: 60px !important;

    .text-number {
      font-size: 28px !important;
    }

    .text-letter {
      font-size: 10px !important;
    }
  }

  .numbers-block {
    div {
      margin: 10px;
    }
  }
}
</style>
