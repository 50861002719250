<template>
  <transition>
    <div class="mt-md-15 pt-4">
      <div class="fz-12 font-weight-semi-bold text-uppercase mb-2">Users</div>

      <v-card
        class="overflow-hidden"
        max-width="316px"
      >
        <v-list class="pa-0">
          <template v-for="(user, userIndex) in users">
            <v-divider
              v-if="userIndex"
              :key="userIndex + 'divider'"
            ></v-divider>

            <v-list-item class="px-4 pr-2 py-0" :key="userIndex + 'list-item'">
              <v-list-item-avatar
                width="40px"
                height="40px"
                class="mr-2"
              >
                <v-img
                  :alt="`${user.firstName} avatar`"
                  :src="'https://picsum.photos/200?random=' + userIndex"
                ></v-img>
              </v-list-item-avatar>

              <v-list-item-content>
                <v-list-item-title class="fz-14 font-weight-medium dark-text white-space-wrap">
                  {{ fullName(user.firstName, user.lastName) }}
                </v-list-item-title>
                <v-list-item-subtitle class="fz-14 grey--text text--darken-1 white-space-wrap">
                  {{ user.userPhoneNumber }}
                </v-list-item-subtitle>
              </v-list-item-content>

              <v-list-item-action class="flex-row align-center ml-2">
                <v-tooltip bottom content-class="v-tooltip--bottom">
                  <template v-slot:activator="{ on, attrs}">
                    <v-btn
                      width="30px"
                      height="30px"
                      icon
                      class="mr-1"
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon size="26px" color="#50B83C">phone_callback</v-icon>
                    </v-btn>
                  </template>

                  <span>Answer</span>
                </v-tooltip>

                <v-tooltip bottom content-class="v-tooltip--bottom">
                  <template v-slot:activator="{ on, attrs}">
                    <v-btn
                      width="30px"
                      height="30px"
                      icon
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon size="26px" color="#ED6347">remove_circle</v-icon>
                    </v-btn>
                  </template>

                  <span>Hang up</span>
                </v-tooltip>
              </v-list-item-action>
            </v-list-item>
          </template>

          <v-divider></v-divider>

          <v-btn
            text
            to="/mailbox/callback"
            color="#007ACE"
            width="100%"
            height="auto"
            class="px-2 py-4 text-none text-center wrap-btn-text"
            tile
          >
            Go to Callback mailbox of CEO mailbox
          </v-btn>
        </v-list>
      </v-card>
    </div>
  </transition>
</template>

<script>
import { mapActions } from 'vuex'
export default {
  name: 'CallbackRequested',
  data: () => ({
    users: []
  }),
  created () {
    this.loadUsers()
  },
  methods: {
    ...mapActions(['getMembers', 'getMailbox']),
    async loadUsers () {
      if (this.$store.state.auth.currentUser.role !== 'account_admin' || !this.$route.params.uuid) return
      try {
        const mailbox = await this.getMailbox(this.$route.params.uuid)
        const { data } = await this.getMembers()
        const users = []
        data.members.forEach(user => {
          if (mailbox.data.mailbox.users.includes(user.uuid)) {
            users.push(user)
          }
        })
        this.users = users
      } catch (err) {
        throw new Error(err)
      }
    },
    fullName (firstname, lastname) {
      return firstname + ' ' + lastname
    }
  }
}
</script>
<style scoped lang="scss">
  .wrap-btn-text::v-deep .v-btn__content {
    width: 100% !important;
    white-space: normal !important;
  }
</style>
