import { Role } from '@/_helpers/role'

export default [
  {
    path: '/admin',
    name: 'AdminCompany',
    meta: {
      layout: 'admin',
      authorize: [Role.SuperAdmin]
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/Admin/Company.vue')
  },
  {
    path: '/admin-invoice/:companyUuid?',
    name: 'AdminInvoice',
    meta: {
      layout: 'admin',
      authorize: [Role.SuperAdmin]
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/Admin/Invoice.vue')
  },
  {
    path: '/admin-payment/:companyUuid?',
    name: 'AdminPayment',
    meta: {
      layout: 'admin',
      authorize: [Role.SuperAdmin]
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/Admin/Payment.vue')
  },
  {
    path: '/admin-number/:companyUuid?',
    name: 'AdminNumber',
    meta: {
      layout: 'admin',
      authorize: [Role.SuperAdmin]
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/Admin/Number.vue')
  },
  {
    path: '/admin-users/:companyUuid',
    name: 'AdminUsers',
    meta: {
      layout: 'admin',
      authorize: [Role.SuperAdmin]
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/Admin/Users.vue')
  }
]
